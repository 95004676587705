const mixin = {
  methods: {
    isFilterEmpty(filter) {
      if (!filter) {
        return true;
      }
      if (Object.keys(filter).length === 0 && filter.constructor === Object) {
        return true;
      }

      //eslint-disable-next-line
      for (const [key, value] of Object.entries(filter)) {
        if (!this.isFilterValueEmpty(value)) {
          return false;
        }
      }

      return true;
    },

    isFilterValueEmpty(value) {
      if (!value) {
        return true;
      }
      return value.length === 0 || (value.min === '' && value.max === '');
    }
  }
};

export default mixin;
