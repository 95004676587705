import * as PartnerType from '@/mixins/partnerType';
import { USER } from '../types/constants';

const mixin = {
  methods: {
    isValueNotEmpty(value) {
      return ['', null, undefined].indexOf(value) === -1;
    },
    isValueEmpty(value) {
      return !this.isValueNotEmpty(value);
    },
    isUserMarketnation() {
      try {
        return this.$store.state.user.profile.type === PartnerType.MARKETNATION;
      } catch (e) {
        return false;
      }
    },

    hasUserAdminRole() {
      const userRoles = this.$store.getters[
        'user/' + USER.GETTERS.GET_USER_ROLES
      ];
      return userRoles.some((role) => {
        return role === 'ROLE_ADMIN';
      });
    },

    hasUserUserRole() {
      const userRoles = this.$store.getters[
        'user/' + USER.GETTERS.GET_USER_ROLES
      ];
      return userRoles.some((role) => {
        return role === 'ROLE_USER';
      });
    },

    isUserAllowed(userType) {
      const profile = this.$store.state.user.profile;
      if (!profile) {
        return false;
      }
      return profile.type === userType;
    },
    downloadFile(data, name) {
      const url = window.URL.createObjectURL(
        new Blob([data], { type: 'application/octet-stream' })
      );
      const link = document.createElement('a');

      link.href = url;
      link.download = name;
      link.click();
    }
  }
};

export default mixin;
