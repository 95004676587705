export function useApproveStatuses() {
  const approveStatuses = {
    APPROVED: 'APPROVED',
    PENDING: 'PENDING'
  };

  return {
    approveStatuses
  };
}
