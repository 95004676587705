import { TREATMENT } from '@/types/constants';
import {
  ITreatmentGetters,
  ITreatmentState,
  TreatmentMutationsTypes
} from '@/types/interfaces/store/treatment/treatment';
import { GetterTree, MutationTree } from 'vuex';
import { IState } from '@/types/interfaces/store';

export const state: ITreatmentState = {
  userTreatments: {},
  accountTreatments: {},
  anonymousTreatments: {}
};

export const getters: GetterTree<ITreatmentState, ITreatmentState> &
  ITreatmentGetters = {
  [TREATMENT.GETTERS.GET_USER_TREATMENT]: (state) => (treatmentName) => {
    const treatment = state.userTreatments[treatmentName];
    return treatment === 'on';
  },
  [TREATMENT.GETTERS.GET_ACCOUNT_TREATMENT]: (state) => (treatmentName) => {
    const treatment = state.accountTreatments[treatmentName];
    return treatment === 'on';
  },
  [TREATMENT.GETTERS.GET_ANONYMOUS_TREATMENT]: (state) => (treatmentName) => {
    const treatment = state.anonymousTreatments[treatmentName];
    return treatment === 'on';
  }
};

const mutations: MutationTree<IState> & TreatmentMutationsTypes = {
  [TREATMENT.MUTATIONS.SET_USER_TREATMENTS](state, value) {
    state.userTreatments = value;
  },
  [TREATMENT.MUTATIONS.SET_ACCOUNT_TREATMENTS](state, value) {
    state.accountTreatments = value;
  },
  [TREATMENT.MUTATIONS.SET_ANONYMOUS_TREATMENTS](state, value) {
    state.anonymousTreatments = value;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters
};
