import ApiService from '@/services/api.service';
import { cloneDeep, upperFirst } from 'lodash';
import inventory from './accordions/inventory';
import basicOfferInfo from './accordions/basic-offer-info';
import channels from './accordions/channels';
import dimensions from './accordions/dimensions';
import offerSpecificContent from './accordions/offer-specific-content';
import pricing from './accordions/pricing';
import promotions from './accordions/promotions';
import productOfferCommon from '../helpers/product-offer-common';
import {
  IOfferIndexState,
  IOfferIndexActions,
  OfferIndexMutationsTypes
} from '@/types/interfaces/store/product/offer/offer';
import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { PD_OFFER } from '@/types/constants';
import { IState } from '@/types/interfaces/store';
import { AxiosError, AxiosResponse } from 'axios';
import { IOfferGetters } from '@/types/interfaces/store/product/offer';
import { IOfferState } from '@/types/interfaces/store/product/offer';

export const state: IOfferIndexState = {
  offerId: null,

  isCreating: false,
  isUpdating: false,

  accordionNames: [
    'basicOfferInfo',
    'channels',
    'dimensions',
    'pricing',
    'promotions',
    'inventory',
    'offerSpecificContent'
  ],

  accordionNamesOnBackend: {
    basicOfferInfo: 'basicInfo',
    channels: 'channels',
    dimensions: 'dimension',
    pricing: 'pricing',
    promotions: 'promotions',
    inventory: 'inventory',
    offerSpecificContent: 'specificContent'
  },

  accordionActionsNames: [
    'BASIC_OFFER_INFO',
    'CHANNELS',
    'DIMENSIONS',
    'PRICING',
    'PROMOTIONS',
    'INVENTORY',
    'OFFER_SPECIFIC_CONTENT'
  ],

  accordionNamesAndActions: {
    basicOfferInfo: 'BASIC_OFFER_INFO',
    channels: 'CHANNELS',
    dimensions: 'DIMENSIONS',
    pricing: 'PRICING',
    promotions: 'PROMOTIONS',
    inventory: 'INVENTORY',
    offerSpecificContent: 'OFFER_SPECIFIC_CONTENT'
  },

  ...inventory.state,
  ...basicOfferInfo.state,
  ...channels.state,
  ...dimensions.state,
  ...offerSpecificContent.state,
  ...pricing.state,
  ...promotions.state
};

export const getters: GetterTree<IOfferState, IOfferState> & IOfferGetters = {
  [PD_OFFER.GETTERS.IS_ADDING_NEW_OFFER]: (state) => {
    return state.offerId === 'new';
  },

  [PD_OFFER.GETTERS.GET_SKU_DETAILS_DATA_FOR_REQUEST]: (state) => (
    onlyEdited
  ) => {
    const data = {};

    state.accordionNames.forEach((name) => {
      if (onlyEdited && !state['isEditing' + upperFirst(name)]) {
        return;
      }
      if (name === 'channels') {
        data[
          state.accordionNamesOnBackend[name]
        ] = channels.helpers.prepareChannelsForRequest(state[name]);
        return;
      }
      data[state.accordionNamesOnBackend[name]] = state[name];
    });

    return data;
  },
  [PD_OFFER.GETTERS.GET_ACCORDION_OFFER_DATA]: (state) => (accordionName) => {
    return state[accordionName];
  },

  [PD_OFFER.GETTERS.GET_ACCORDION_OFFER_ORIGINAL_DATA]: (state) => (
    accordionName
  ) => {
    return state['original' + upperFirst(accordionName)];
  },

  [PD_OFFER.GETTERS.IS_EDITING_OFFER]: (state) => {
    return state.accordionNames.some((name) => {
      return state['isEditing' + upperFirst(name)];
    });
  },

  [PD_OFFER.GETTERS.IS_LOADING_OFFER]: (state) => {
    return state.accordionNames.some((name) => {
      return state['isLoading' + upperFirst(name)];
    });
  },

  [PD_OFFER.GETTERS.GET_ACCORDION_OFFER_ERRORS]: (
    state,
    getters,
    rootState
  ) => (accordionName) => {
    const accordionErrors = cloneDeep(state[accordionName + 'Errors']);
    if (!accordionErrors) {
      return null;
    }

    Object.keys(accordionErrors).forEach((fieldName) => {
      const errorCode = accordionErrors[fieldName];
      if (!errorCode) {
        return;
      }

      // @ts-ignore
      const errorMessage: string = rootState.$t(
        'productError.' + accordionName + '.' + fieldName + '.' + errorCode
      );
      accordionErrors[fieldName] = errorMessage || '';
    });

    return accordionErrors;
  },

  ...inventory.getters,
  ...basicOfferInfo.getters,
  ...channels.getters,
  ...dimensions.getters,
  ...offerSpecificContent.getters,
  ...pricing.getters,
  ...promotions.getters
};

export const actions: ActionTree<IOfferState, IState> & IOfferIndexActions = {
  [PD_OFFER.ACTIONS.GET_SKU_DETAILS]({ dispatch, rootState }) {
    if (rootState['product'].isAddingNewProduct) {
      dispatch(PD_OFFER.ACTIONS.SET_EMPTY_OFFER_DATA);
      return;
    }
    const productId = rootState['product'].productId;
    const offerId = state.offerId;

    state.accordionActionsNames.some((name) => {
      const actionName = 'GET_' + name;
      dispatch(actionName, { productId, offerId });
    });
  },

  [PD_OFFER.ACTIONS.CREATE_OFFER]({ dispatch, commit, rootState, getters }) {
    return new Promise((resolve, reject) => {
      commit(PD_OFFER.MUTATIONS.SET_IS_CREATING, true);

      const productId = rootState['product'].productId;
      const url =
        process.env.VUE_APP_CATALOG_SERVICE +
        '/products/' +
        productId +
        '/offers';
      const data = getters.GET_SKU_DETAILS_DATA_FOR_REQUEST();

      ApiService.post(url, data)
        .then((response: AxiosResponse) => {
          commit(PD_OFFER.MUTATIONS.SET_IS_CREATING, false);
          resolve(response);
        })
        .catch((error: AxiosError<any>) => {
          const errors = error?.response?.data?.message;
          commit(PD_OFFER.MUTATIONS.SET_IS_CREATING, false);
          dispatch(PD_OFFER.ACTIONS.SET_OFFER_ERRORS, errors);
          reject(error);
        });
    });
  },

  [PD_OFFER.ACTIONS.UPDATE_OFFER]({
    dispatch,
    commit,
    state,
    rootState,
    getters
  }) {
    return new Promise((resolve, reject) => {
      commit(PD_OFFER.MUTATIONS.SET_IS_UPDATING, true);

      const productId = rootState['product'].productId;
      const offerId = state.offerId;
      const url =
        process.env.VUE_APP_CATALOG_SERVICE +
        '/products/' +
        productId +
        '/offers/' +
        offerId;
      const data = getters.GET_SKU_DETAILS_DATA_FOR_REQUEST(true);

      ApiService.put(url, data)
        .then((response: AxiosResponse) => {
          commit(PD_OFFER.MUTATIONS.SET_IS_UPDATING, false);
          dispatch(PD_OFFER.ACTIONS.UPDATE_ORIGINAL_OFFER_DATA);
          resolve(response);
        })
        .catch((error: AxiosError<any>) => {
          const errors = error?.response?.data?.message;
          commit(PD_OFFER.MUTATIONS.SET_IS_UPDATING, false);
          dispatch(PD_OFFER.ACTIONS.SET_OFFER_ERRORS, errors);
          reject(error);
        });
    });
  },

  [PD_OFFER.ACTIONS.DELETE_OFFER_SKU]({ state, rootState }) {
    return new Promise((resolve, reject) => {
      const productId = rootState['product'].productId;
      const offerId = state.offerId;
      const url =
        process.env.VUE_APP_CATALOG_SERVICE +
        '/products/' +
        productId +
        '/offers/' +
        offerId;

      ApiService.delete(url)
        .then((response: AxiosResponse) => {
          resolve(response);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },

  [PD_OFFER.ACTIONS.DISCARD_CHANGES_OFFER]({ commit, state }) {
    state.accordionNames.forEach((name) => {
      const originalName = 'original' + upperFirst(name);
      commit(PD_OFFER.MUTATIONS.DISCARD_CHANGES_IN_ACCORDION_OFFER, {
        name,
        originalName
      });
    });
  },

  [PD_OFFER.ACTIONS.CLEAR_OFFER_DATA]({ state, commit }) {
    state.accordionNames.forEach((name) => {
      const originalName = 'original' + upperFirst(name);
      commit(PD_OFFER.MUTATIONS.CLEAR_ACCORDION_DATA_OFFER, {
        name,
        originalName
      });
    });
  },

  [PD_OFFER.ACTIONS.SET_EMPTY_OFFER_DATA]({ state, commit }) {
    state.accordionNames.forEach((name) => {
      const originalName = 'original' + upperFirst(name);
      commit(PD_OFFER.MUTATIONS.SET_EMPTY_ACCORDION_DATA_OFFER, {
        name,
        originalName
      });
    });
  },

  [PD_OFFER.ACTIONS.UPDATE_ORIGINAL_OFFER_DATA]({ state, commit }) {
    for (const key in state.accordionNamesAndActions) {
      const data = state[key];
      // @ts-ignore
      commit('SET_ORIGINAL_' + state.accordionNamesAndActions[key], data);
    }
  },

  [PD_OFFER.ACTIONS.SET_OFFER_ERRORS]({ state, commit }, errors) {
    for (const key in state.accordionNamesAndActions) {
      const accordionErrors = errors[state.accordionNamesOnBackend[key]];
      commit(
        // @ts-ignore
        'SET_' + state.accordionNamesAndActions[key] + '_ERRORS',
        accordionErrors
      );
    }
  },

  ...inventory.actions,
  ...basicOfferInfo.actions,
  ...channels.actions,
  ...dimensions.actions,
  ...offerSpecificContent.actions,
  ...pricing.actions,
  ...promotions.actions
};

const mutations: MutationTree<IOfferState> & OfferIndexMutationsTypes = {
  [PD_OFFER.MUTATIONS.SET_OFFER_ID](state, value) {
    state.offerId = value;
  },

  [PD_OFFER.MUTATIONS.SET_IS_CREATING](state, value) {
    state.isCreating = value;
  },

  [PD_OFFER.MUTATIONS.SET_IS_UPDATING](state, value) {
    state.isUpdating = value;
  },

  [PD_OFFER.MUTATIONS.CLEAR_ERRORS_OBJECT](state) {
    state.accordionNames.forEach((name) => {
      state[name + 'Errors'] = null;
    });
  },

  [PD_OFFER.MUTATIONS.DISCARD_CHANGES_IN_ACCORDION_OFFER](
    state,
    { name, originalName }
  ) {
    const data = state[name];
    const originalData = state[originalName];
    Object.keys(data).forEach((key) => {
      data[key] = cloneDeep(originalData[key]);
    });
  },

  [PD_OFFER.MUTATIONS.CLEAR_ACCORDION_DATA_OFFER](
    state,
    { name, originalName }
  ) {
    state[name] = null;
    state[originalName] = null;
  },

  [PD_OFFER.MUTATIONS.CLEAR_SAVED_NOT_PUBLISHED](state) {
    if (state.basicOfferInfo) {
      state.basicOfferInfo.savedDataNotPublished = false;
    }
  },

  [PD_OFFER.MUTATIONS.SET_EMPTY_ACCORDION_DATA_OFFER](
    state,
    { name, originalName }
  ) {
    const emptyDataName = 'empty' + upperFirst(name);
    state[name] = cloneDeep(state[emptyDataName]);
    state[originalName] = cloneDeep(state[emptyDataName]);
  },

  [PD_OFFER.MUTATIONS.CLEAR_FIELD_OFFER_ERROR](
    state,
    { accordionName, fieldName }
  ) {
    const accordionErrors = state[accordionName + 'Errors'];
    if (!(accordionErrors && accordionErrors[fieldName])) {
      return;
    }
    state[accordionName + 'Errors'][fieldName] = null;
  },

  ...inventory.mutations,
  ...basicOfferInfo.mutations,
  ...channels.mutations,
  ...dimensions.mutations,
  ...offerSpecificContent.mutations,
  ...pricing.mutations,
  ...promotions.mutations
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
