import { useStore } from 'vuex';

export function useUtilityFunction() {
  const store = useStore();

  const isValueNotEmpty = (value) => {
    return ['', null, undefined].indexOf(value) === -1;
  };

  const isValueEmpty = (value) => {
    return isValueNotEmpty(value);
  };

  const isUserAllowed = (userType) => {
    const profile = store.state.user.profile;
    if (!profile) {
      return false;
    }
    return profile.type === userType;
  };

  const downloadFile = (data, name) => {
    const url = window.URL.createObjectURL(
      new Blob([data], { type: 'application/octet-stream' })
    );
    const link = document.createElement('a');

    link.href = url;
    link.download = name;
    link.click();
  };

  return {
    isValueNotEmpty,
    isValueEmpty,
    isUserAllowed,
    downloadFile
  };
}
