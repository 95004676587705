import { cloneDeep } from 'lodash';
import ApiService from '@/services/api.service';
import {
  IPromotionsState,
  IPromotionsGetters,
  IPromotionsActions,
  PromotionsMutationsTypes
} from '@/types/interfaces/store/product/offer/accordions/promotions';
import { PD_OFFER } from '@/types/constants';
import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { AxiosError, AxiosResponse } from 'axios';
import {
  IPromotions,
  IPromotionsError
} from '@/types/interfaces/product/offer/accordions/promotions';
import { IState } from '@/types/interfaces/store';
import { IOfferState } from '@/types/interfaces/store/product/offer';

export const state: IPromotionsState = {
  isEditingPromotions: false,
  isLoadingPromotions: false,

  promotions: null,
  originalPromotions: null,
  emptyPromotions: [
    {
      id: null,
      rebate: null,
      rebateType: null,
      owner: null,
      startDate: null,
      endDate: null,
      applicableMarketplaceId: null
    }
  ],
  promotionsErrors: null
};

export const getters: GetterTree<IPromotionsState, IPromotionsState> &
  IPromotionsGetters = {};

export const actions: ActionTree<IOfferState, IState> & IPromotionsActions = {
  [PD_OFFER.ACTIONS.GET_PROMOTIONS](
    { commit },
    { productId, offerId }: { productId: number; offerId: number }
  ) {
    return new Promise((resolve, reject) => {
      commit(PD_OFFER.MUTATIONS.SET_IS_LOADING_PROMOTIONS, true);

      const url =
        process.env.VUE_APP_CATALOG_SERVICE +
        '/products/' +
        productId +
        '/offers/' +
        offerId +
        '/promotions';

      ApiService.get(url)
        .then((response: AxiosResponse<IPromotions[]>) => {
          commit(PD_OFFER.MUTATIONS.SET_IS_LOADING_PROMOTIONS, false);
          commit(PD_OFFER.MUTATIONS.SET_PROMOTIONS, response.data);
          commit(PD_OFFER.MUTATIONS.SET_ORIGINAL_PROMOTIONS, response.data);
          resolve(response.data);
        })
        .catch((error: AxiosError) => {
          commit(PD_OFFER.MUTATIONS.SET_IS_LOADING_PROMOTIONS, false);
          reject(error);
        });
    });
  }
};

const mutations: MutationTree<IOfferState> & PromotionsMutationsTypes = {
  [PD_OFFER.MUTATIONS.SET_IS_EDITING_PROMOTIONS](
    state: IPromotionsState,
    value: boolean
  ) {
    state.isEditingPromotions = value;
  },

  [PD_OFFER.MUTATIONS.SET_IS_LOADING_PROMOTIONS](
    state: IPromotionsState,
    value: boolean
  ) {
    state.isLoadingPromotions = value;
  },

  [PD_OFFER.MUTATIONS.SET_PROMOTIONS](
    state: IPromotionsState,
    value: IPromotions[]
  ) {
    state.promotions = value;
  },

  [PD_OFFER.MUTATIONS.SET_ORIGINAL_PROMOTIONS](
    state: IPromotionsState,
    value: IPromotions[]
  ) {
    state.originalPromotions = cloneDeep(value);
  },

  [PD_OFFER.MUTATIONS.SET_PROMOTIONS_ERRORS](
    state: IPromotionsState,
    value: IPromotionsError
  ) {
    state.promotionsErrors = value;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
