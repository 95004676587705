import { cloneDeep } from 'lodash';
import ApiService from '@/services/api.service';
import {
  IMediaState,
  IMediaGetters,
  IMediaActions,
  MediaMutationsTypes
} from '@/types/interfaces/store/product/accordions/media';
import { PD_CONT } from '@/types/constants';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { AxiosError, AxiosResponse } from 'axios';
import {
  IImage,
  IMedia,
  IMediaError
} from '@/types/interfaces/product/accordions/media';
import { IState } from '@/types/interfaces/store';

export const state: IMediaState = {
  isEditingMedia: false,
  isSavingMedia: false,
  isLoadingMedia: false,

  media: null,
  originalMedia: null,
  emptyMedia: {
    images: []
  },
  mediaErrors: null
};

export const getters: GetterTree<IMediaState, IMediaState> & IMediaGetters = {};

export const actions: ActionTree<IState, IState> & IMediaActions = {
  [PD_CONT.ACTIONS.GET_MEDIA]({ commit }, productId) {
    return new Promise((resolve, reject) => {
      commit(PD_CONT.MUTATIONS.SET_IS_LOADING_MEDIA, true);

      const url =
        process.env.VUE_APP_CATALOG_SERVICE +
        '/products/' +
        productId +
        '/media';

      ApiService.get(url)
        .then((response: AxiosResponse<IImage[]>) => {
          const media = {
            images: response.data
          };
          commit(PD_CONT.MUTATIONS.SET_IS_LOADING_MEDIA, false);
          commit(PD_CONT.MUTATIONS.SET_MEDIA, media);
          commit(PD_CONT.MUTATIONS.SET_ORIGINAL_MEDIA, media);
          resolve(media);
        })
        .catch((error: AxiosError) => {
          commit(PD_CONT.MUTATIONS.SET_IS_LOADING_MEDIA, false);
          reject(error);
        });
    });
  }
};

const mutations: MutationTree<IState> & MediaMutationsTypes = {
  [PD_CONT.MUTATIONS.SET_IS_EDITING_MEDIA](state: IMediaState, value: boolean) {
    state.isEditingMedia = value;
  },

  [PD_CONT.MUTATIONS.SET_IS_LOADING_MEDIA](state: IMediaState, value: boolean) {
    state.isLoadingMedia = value;
  },

  [PD_CONT.MUTATIONS.SET_MEDIA](state: IMediaState, value: IMedia) {
    state.media = value;
  },

  [PD_CONT.MUTATIONS.SET_ORIGINAL_MEDIA](state: IMediaState, value: IMedia) {
    state.originalMedia = cloneDeep(value);
  },

  [PD_CONT.MUTATIONS.SET_MEDIA_ERRORS](state: IMediaState, value: IMediaError) {
    state.mediaErrors = value;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
