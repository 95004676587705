/** VUEX store definition **/
import { createStore } from 'vuex';

import order from '@/store/modules/order/order';
import partner from '@/store/modules/partner/partner';
import product from '@/store/modules/product/index';
import treatment from '@/store/modules/treatment/treatment';
import user from '@/store/modules/user/user';
import category from '@/store/modules/category/category';

const store = createStore({
  modules: {
    order,
    partner,
    product,
    treatment,
    category,
    user
  },
  plugins: [],
  strict: false
});

export default store;
