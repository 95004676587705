<template src="./template.pug" lang="pug"></template>
<script>
export default {
  name: 'Compliance'
};
</script>
<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { TREATMENT } from '@/types/constants';

const store = useStore();

const productComplianceFlag = computed(() => {
  return store.getters['treatment/' + TREATMENT.GETTERS.GET_USER_TREATMENT](
    'product-compliance'
  );
});
</script>
