import { createI18n } from 'vue-i18n/index';
import enMessage from '@/locale/en.json';

const i18n = createI18n({
  locale: 'en', // set locale
  messages: {
    en: enMessage
  }
});

export default i18n;
