<template src="./template.pug" lang="pug"></template>
<script>
import InlineSvg from 'vue-inline-svg';
export default {
  name: 'MarginStackInputTrashButton',
  components: { InlineSvg }
};
</script>

<script setup>
import { defineProps } from 'vue';

const props = defineProps({
  isVisible: {
    type: Boolean,
    default: false
  }
});
</script>
