export function useMarginStackInputTypes() {
  const inputTypes = {
    BUILT_IN_FULFILLMENT_COST: 'BUILT_IN_FULFILLMENT_COST',
    BUILT_IN_CONSUMER_PAID_SHIPPING: 'BUILT_IN_CONSUMER_PAID_SHIPPING',
    BUILT_IN_LISTING_PRICE: 'BUILT_IN_LISTING_PRICE',
    BUILT_IN_COST_OF_RETURNS: 'BUILT_IN_COST_OF_RETURNS',
    BUILT_IN_FULFILLMENT_MARGIN: 'BUILT_IN_FULFILLMENT_MARGIN',
    BUILT_IN_FREIGHT_COST: 'BUILT_IN_FREIGHT_COST',
    BUILT_IN_MARKETPLACE_MARGIN: 'BUILT_IN_MARKETPLACE_MARGIN',
    BUILT_IN_MARKETPLACE_REFERRAL_ON_SHIPPING:
      'BUILT_IN_MARKETPLACE_REFERRAL_ON_SHIPPING',
    BUILT_IN_PAYMENT_PROCESSING_FEE: 'BUILT_IN_PAYMENT_PROCESSING_FEE',
    BUILT_IN_TRANSACTION_FEE: 'BUILT_IN_TRANSACTION_FEE',
    BUILT_IN_MINIMUM_FEE: 'BUILT_IN_MINIMUM_FEE',
    BUILT_IN_PRODUCT_COST_WITH_FULFILLMENT_INCLUDING_FREIGHT:
      'BUILT_IN_PRODUCT_COST_WITH_FULFILLMENT_INCLUDING_FREIGHT',
    BUILT_IN_MARKETPLACE_REFERRAL_ON_TAX:
      'BUILT_IN_MARKETPLACE_REFERRAL_ON_TAX',
    BUILT_IN_FREIGHT_COST_EST: 'BUILT_IN_FREIGHT_COST_EST',
    BUILT_IN_TRANSACTION_SERVICE_FEE: 'BUILT_IN_TRANSACTION_SERVICE_FEE',
    BUILT_IN_PRODUCT_COST_WITH_FULFILLMENT:
      'BUILT_IN_PRODUCT_COST_WITH_FULFILLMENT',
    BUILT_IN_RETURNS_OVERAGE_FEE: 'BUILT_IN_RETURNS_OVERAGE_FEE',
    BRAND_MARGIN: 'BRAND_MARGIN',
    FULFILLMENT_MARGIN: 'FULFILLMENT_MARGIN',
    FULFILLMENT_COST: 'FULFILLMENT_COST',
    FREIGHT_COST: 'FREIGHT_COST',
    SALES_MARGIN: 'SALES_MARGIN',
    MDF: 'MDF',
    MARKETING: 'MARKETING'
  };

  const inputTypesWithParenthesis = {
    BUILT_IN_FULFILLMENT_MARGIN: inputTypes.BUILT_IN_FULFILLMENT_MARGIN,
    BUILT_IN_MARKETPLACE_REFERRAL_ON_SHIPPING:
      inputTypes.BUILT_IN_MARKETPLACE_REFERRAL_ON_SHIPPING,
    BUILT_IN_FREIGHT_COST: inputTypes.BUILT_IN_FREIGHT_COST,
    BUILT_IN_MARKETPLACE_MARGIN: inputTypes.BUILT_IN_MARKETPLACE_MARGIN,
    BUILT_IN_PAYMENT_PROCESSING_FEE: inputTypes.BUILT_IN_PAYMENT_PROCESSING_FEE,
    BUILT_IN_TRANSACTION_FEE: inputTypes.BUILT_IN_TRANSACTION_FEE,
    BUILT_IN_MINIMUM_FEE: inputTypes.BUILT_IN_MINIMUM_FEE,
    BUILT_IN_TRANSACTION_SERVICE_FEE:
      inputTypes.BUILT_IN_TRANSACTION_SERVICE_FEE,
    BUILT_IN_RETURNS_OVERAGE_FEE: inputTypes.BUILT_IN_RETURNS_OVERAGE_FEE,
    BUILT_IN_COST_OF_RETURNS: inputTypes.BUILT_IN_COST_OF_RETURNS,
    BUILT_IN_PRODUCT_COST_WITH_FULFILLMENT:
      inputTypes.BUILT_IN_PRODUCT_COST_WITH_FULFILLMENT,
    BUILT_IN_FREIGHT_COST_EST: inputTypes.BUILT_IN_FREIGHT_COST_EST,
    BUILT_IN_PRODUCT_COST_WITH_FULFILLMENT_INCLUDING_FREIGHT:
      inputTypes.BUILT_IN_PRODUCT_COST_WITH_FULFILLMENT_INCLUDING_FREIGHT,
    BUILT_IN_MARKETPLACE_REFERRAL_ON_TAX:
      inputTypes.BUILT_IN_MARKETPLACE_REFERRAL_ON_TAX
  };

  const inputTypesWithoutParenthesis = {
    BUILT_IN_FULFILLMENT_COST: inputTypes.BUILT_IN_FULFILLMENT_COST,
    BUILT_IN_LISTING_PRICE: inputTypes.BUILT_IN_LISTING_PRICE,
    BRAND_MARGIN: inputTypes.BRAND_MARGIN,
    FULFILLMENT_MARGIN: inputTypes.FULFILLMENT_MARGIN,
    FULFILLMENT_COST: inputTypes.FULFILLMENT_COST,
    FREIGHT_COST: inputTypes.FREIGHT_COST,
    SALES_MARGIN: inputTypes.SALES_MARGIN,
    MDF: inputTypes.MDF,
    MARKETING: inputTypes.MARKETING
  };

  const inputTypesWithCustomCalculations = {
    BUILT_IN_FULFILLMENT_MARGIN: inputTypes.BUILT_IN_FULFILLMENT_MARGIN,
    BUILT_IN_MARKETPLACE_MARGIN: inputTypes.BUILT_IN_MARKETPLACE_MARGIN,
    BUILT_IN_PAYMENT_PROCESSING_FEE: inputTypes.BUILT_IN_PAYMENT_PROCESSING_FEE,
    BUILT_IN_TRANSACTION_SERVICE_FEE:
      inputTypes.BUILT_IN_TRANSACTION_SERVICE_FEE,
    BUILT_IN_MARKETPLACE_REFERRAL_ON_SHIPPING:
      inputTypes.BUILT_IN_MARKETPLACE_REFERRAL_ON_SHIPPING,
    BUILT_IN_MARKETPLACE_REFERRAL_ON_TAX:
      inputTypes.BUILT_IN_MARKETPLACE_REFERRAL_ON_TAX,
    BUILT_IN_COST_OF_RETURNS: inputTypes.BUILT_IN_COST_OF_RETURNS
  };

  const isInputTypeWithParenthesis = (type: string) => {
    return Object.values(inputTypesWithParenthesis).some(
      (typeWithParenthesis) => typeWithParenthesis === type
    );
  };

  const isInputTypeWithCustomCalculations = (type: string) => {
    return Object.values(inputTypesWithCustomCalculations).some(
      (typeWithCustomCalculations) => typeWithCustomCalculations === type
    );
  };

  const isInputTypeBuiltIn = (type: string) => {
    return type.includes('BUILT_IN');
  };

  return {
    inputTypes,
    inputTypesWithParenthesis,
    inputTypesWithoutParenthesis,
    inputTypesWithCustomCalculations,
    isInputTypeWithParenthesis,
    isInputTypeWithCustomCalculations,
    isInputTypeBuiltIn
  };
}
