import { useMarginStackInputTypes } from './types';
import { isNil } from 'lodash';
import { useStore } from 'vuex';
import { IInput } from '@/types/interfaces/product/margin-stack/margin-stack';
import {
  PD_CONT,
  PD_OFFER,
  PRODUCT
} from '../../../../../../../types/constants';

export function useMarginStackInputCalculations(
  getCalculatedInputValue: (inputType: string) => number
) {
  const store = useStore();

  const {
    isInputTypeWithCustomCalculations,
    inputTypes
  } = useMarginStackInputTypes();

  const calculateInputValue = (input: IInput) => {
    if (isNil(input.opportunityValue.value)) {
      return null;
    }

    if (input.percentageOf) {
      return calculatePercentageInputValue(input);
    }

    if (isInputTypeWithCustomCalculations(input.type)) {
      return calculateInputValueWithCustomCalculations(input);
    }
    return input.opportunityValue.value;
  };

  const calculatePercentageInputValue = (input) => {
    return (
      (input.opportunityValue.value / 100) *
      getCalculatedInputValue(input.percentageOf)
    );
  };

  const calculateInputValueWithCustomCalculations = (input: IInput) => {
    switch (input.type) {
      case inputTypes.BUILT_IN_FULFILLMENT_MARGIN:
        return calculateBuiltInFulfillmentMarginValue(
          input.opportunityValue.value
        );
      case inputTypes.BUILT_IN_PAYMENT_PROCESSING_FEE:
        return calculateBuiltInPaymentProcessingFeeValue(
          input.opportunityValue.value
        );
      case inputTypes.BUILT_IN_MARKETPLACE_REFERRAL_ON_SHIPPING:
        return calculateMarketplaceReferralOnShipping(
          input.opportunityValue.value
        );
      case inputTypes.BUILT_IN_MARKETPLACE_REFERRAL_ON_TAX:
        return calculateMarketplaceReferralOnTax(input.opportunityValue.value);
      case inputTypes.BUILT_IN_COST_OF_RETURNS:
        return calculateBuiltInPaymentProcessingFeeValue(
          input.opportunityValue.value
        );
      default:
        return calculateDefaultInputValueWithCustomCalculations(input);
    }
  };

  const calculateDefaultInputValueWithCustomCalculations = (input) => {
    return (
      (input.opportunityValue.value / 100) *
      getCalculatedInputValue(inputTypes.BUILT_IN_LISTING_PRICE)
    );
  };

  const calculateMarketplaceReferralOnTax = (value) => {
    const multiplier = 0.095;
    return (
      (value / 100) *
      getCalculatedInputValue(inputTypes.BUILT_IN_LISTING_PRICE) *
      multiplier
    );
  };

  const calculateMarketplaceReferralOnShipping = (value) => {
    const multiplier = 1;
    return (
      (value / 100) *
      getCalculatedInputValue(inputTypes.BUILT_IN_CONSUMER_PAID_SHIPPING) *
      multiplier
    );
  };

  const calculateBuiltInFulfillmentMarginValue = (value: number) => {
    const fulfillmentMarginType =
      store.getters[
        'product/offer/' + PD_OFFER.GETTERS.GET_FULFILLMENT_MARGIN_TYPE
      ];
    const msrp = store.getters['product/' + PD_CONT.GETTERS.GET_MSRP];
    const listingPrice = getCalculatedInputValue(
      inputTypes.BUILT_IN_LISTING_PRICE
    );
    const fulfillmentCost = getCalculatedInputValue(
      inputTypes.BUILT_IN_FULFILLMENT_COST
    );

    switch (fulfillmentMarginType) {
      case 'listing-price':
        return (value * listingPrice) / 100;
      case 'msrp':
        return (value * msrp) / 100;
      case 'fulfillment-cost':
      default:
        return fulfillmentCost * (value / 100);
    }
  };

  const calculateBuiltInPaymentProcessingFeeValue = (value: number) => {
    const multiplier = 1;
    return (
      (value / 100) *
      getCalculatedInputValue(inputTypes.BUILT_IN_LISTING_PRICE) *
      multiplier
    );
  };

  return {
    calculateInputValue
  };
}
