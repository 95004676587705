import { ref, inject } from 'vue';
import { useStore } from 'vuex';
import { MARGIN_STACK } from '@/types/constants';

export function useSelected() {
  const store = useStore();
  const selected = ref([]);
  const isClearingProposals = ref<boolean>(false);
  const storefrontId = inject<number>('storefrontId');

  const isSelectedEmpty = () => {
    return selected.value?.length === 0;
  };

  const clearSelected = async () => {
    isClearingProposals.value = true;
    await store
      .dispatch('product/marginStack/' + MARGIN_STACK.ACTIONS.CLEAR_PROPOSALS, {
        storefrontId,
        proposalIds: selected.value
      })
      .then(() => {
        selected.value = [];
      });
    isClearingProposals.value = false;
  };

  return {
    selected,
    isClearingProposals,
    isSelectedEmpty,
    clearSelected
  };
}
