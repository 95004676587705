const mixin = {
  methods: {
    getCurrencyFormat(value) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }).format(value);
    },
    getDateFormat(itemDate) {
      const fullDate = new Date(itemDate);
      const month = this.getMonthName(fullDate.getMonth());
      const date = fullDate.getDate();
      const year = fullDate.getFullYear();
      const time = this.getTimeAMPMFormat(fullDate);
      return month + ' ' + date + ', ' + year + ' ' + time;
    },
    getTimeAMPMFormat(date) {
      let hours = date.getHours();
      let minutes = date.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? '0' + minutes : minutes;

      return hours + ':' + minutes + ' ' + ampm;
    },
    getMonthName(monthNumber) {
      const monthNames = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ];
      return monthNames[monthNumber];
    }
  }
};

export default mixin;
