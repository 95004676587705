export enum OfferGetters {
  GET_FULFILLMENT_PARTNER_ID = 'GET_FULFILLMENT_PARTNER_ID',
  GET_FULFILLMENT_MARGIN_TYPE = 'GET_FULFILLMENT_MARGIN_TYPE',
  GET_STOREFRONT_IDS = 'GET_STOREFRONT_IDS',

  IS_ADDING_NEW_OFFER = 'IS_ADDING_NEW_OFFER',
  GET_SKU_DETAILS_DATA_FOR_REQUEST = 'GET_SKU_DETAILS_DATA_FOR_REQUEST',

  GET_ACCORDION_OFFER_DATA = 'GET_ACCORDION_OFFER_DATA',
  GET_ACCORDION_OFFER_ORIGINAL_DATA = 'GET_ACCORDION_OFFER_ORIGINAL_DATA',
  IS_EDITING_OFFER = 'IS_EDITING_OFFER',
  IS_LOADING_OFFER = 'IS_LOADING_OFFER',
  GET_ACCORDION_OFFER_ERRORS = 'GET_ACCORDION_OFFER_ERRORS',
  SAVED_DATA_NOT_PUBLISHED = "SAVED_DATA_NOT_PUBLISHED"
}
export enum OfferMutations {
  SET_IS_EDITING_BASIC_OFFER_INFO = 'SET_IS_EDITING_BASIC_OFFER_INFO',
  SET_IS_LOADING_BASIC_OFFER_INFO = 'SET_IS_LOADING_BASIC_OFFER_INFO',
  SET_BASIC_OFFER_INFO = 'SET_BASIC_OFFER_INFO',
  SET_ORIGINAL_BASIC_OFFER_INFO = 'SET_ORIGINAL_BASIC_OFFER_INFO',
  SET_BASIC_OFFER_INFO_ERRORS = 'SET_BASIC_OFFER_INFO_ERRORS',

  SET_IS_EDITING_CHANNELS = 'SET_IS_EDITING_CHANNELS',
  SET_IS_LOADING_CHANNELS = 'SET_IS_LOADING_CHANNELS',
  SET_CHANNELS = 'SET_CHANNELS',
  SET_ORIGINAL_CHANNELS = 'SET_ORIGINAL_CHANNELS',
  SET_CHANNELS_ERRORS = 'SET_CHANNELS_ERRORS',

  SET_IS_EDITING_PRICING = 'SET_IS_EDITING_PRICING',
  SET_IS_LOADING_PRICING = 'SET_IS_LOADING_PRICING',
  SET_PRICING = 'SET_PRICING',
  SET_ORIGINAL_PRICING = 'SET_ORIGINAL_PRICING',
  SET_PRICING_ERRORS = 'SET_PRICING_ERRORS',

  SET_IS_EDITING_PROMOTIONS = 'SET_IS_EDITING_PROMOTIONS',
  SET_IS_LOADING_PROMOTIONS = 'SET_IS_LOADING_PROMOTIONS',
  SET_PROMOTIONS = 'SET_PROMOTIONS',
  SET_ORIGINAL_PROMOTIONS = 'SET_ORIGINAL_PROMOTIONS',
  SET_PROMOTIONS_ERRORS = 'SET_PROMOTIONS_ERRORS',

  SET_IS_EDITING_INVENTORY = 'SET_IS_EDITING_INVENTORY',
  SET_IS_LOADING_INVENTORY = 'SET_IS_LOADING_INVENTORY',
  SET_INVENTORY = 'SET_INVENTORY',
  SET_ORIGINAL_INVENTORY = 'SET_ORIGINAL_INVENTORY',
  SET_INVENTORY_ERRORS = 'SET_INVENTORY_ERRORS',
  SET_INVENTORY_STOREFRONTS = 'SET_INVENTORY_STOREFRONTS',

  SET_IS_EDITING_OFFER_SPECIFIC_CONTENT = 'SET_IS_EDITING_OFFER_SPECIFIC_CONTENT',
  SET_IS_LOADING_OFFER_SPECIFIC_CONTENT = 'SET_IS_LOADING_OFFER_SPECIFIC_CONTENT',
  SET_OFFER_SPECIFIC_CONTENT = 'SET_OFFER_SPECIFIC_CONTENT',
  SET_ORIGINAL_OFFER_SPECIFIC_CONTENT = 'SET_ORIGINAL_OFFER_SPECIFIC_CONTENT',
  SET_OFFER_SPECIFIC_CONTENT_ERRORS = 'SET_OFFER_SPECIFIC_CONTENT_ERRORS',

  SET_IS_EDITING_DIMENSIONS = 'SET_IS_EDITING_DIMENSIONS',
  SET_IS_LOADING_DIMENSIONS = 'SET_IS_LOADING_DIMENSIONS',
  SET_DIMENSIONS = 'SET_DIMENSIONS',
  SET_ORIGINAL_DIMENSIONS = 'SET_ORIGINAL_DIMENSIONS',
  SET_DIMENSIONS_ERRORS = 'SET_DIMENSIONS_ERRORS',

  SET_OFFER_ID = 'SET_OFFER_ID',
  SET_IS_CREATING = 'SET_IS_CREATING',
  SET_IS_UPDATING = 'SET_IS_UPDATING',
  CLEAR_ERRORS_OBJECT = 'CLEAR_ERRORS_OBJECT',

  DISCARD_CHANGES_IN_ACCORDION_OFFER = 'DISCARD_CHANGES_IN_ACCORDION_OFFER',
  CLEAR_ACCORDION_DATA_OFFER = 'CLEAR_ACCORDION_DATA_OFFER',
  SET_EMPTY_ACCORDION_DATA_OFFER = 'SET_EMPTY_ACCORDION_DATA_OFFER',
  CLEAR_FIELD_OFFER_ERROR = 'CLEAR_FIELD_OFFER_ERROR',
  CLEAR_SAVED_NOT_PUBLISHED = "CLEAR_SAVED_NOT_PUBLISHED"
}
export enum OfferActions {
  GET_BASIC_OFFER_INFO = 'GET_BASIC_OFFER_INFO',
  GET_CHANNELS = 'GET_CHANNELS',
  GET_PRICING = 'GET_PRICING',
  GET_PROMOTIONS = 'GET_PROMOTIONS',
  GET_INVENTORY = 'GET_INVENTORY',
  UPDATE_INVENTORY = 'UPDATE_INVENTORY',
  GET_OFFER_SPECIFIC_CONTENT = 'GET_OFFER_SPECIFIC_CONTENT',
  GET_DIMENSIONS = 'GET_DIMENSIONS',

  GET_SKU_DETAILS = 'GET_SKU_DETAILS',
  CREATE_OFFER = 'CREATE_OFFER',
  UPDATE_OFFER = 'UPDATE_OFFER',
  DELETE_OFFER_SKU = 'DELETE_OFFER_SKU',

  DISCARD_CHANGES_OFFER = 'DISCARD_CHANGES_OFFER',
  CLEAR_OFFER_DATA = 'CLEAR_OFFER_DATA',
  SET_EMPTY_OFFER_DATA = 'SET_EMPTY_OFFER_DATA',
  UPDATE_ORIGINAL_OFFER_DATA = 'UPDATE_ORIGINAL_OFFER_DATA',
  SET_OFFER_ERRORS = 'SET_OFFER_ERRORS'
}
