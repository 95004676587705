import { cloneDeep } from 'lodash';
import ApiService from '@/services/api.service';
import {
  IChannelsActions,
  IChannelsState,
  IChannelsGetters,
  ChannelsMutationsTypes
} from '@/types/interfaces/store/product/offer/accordions/channels';
import { PD_OFFER } from '@/types/constants';
import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { AxiosError, AxiosResponse } from 'axios';
import {
  IChannels,
  IChannelsFromBackend,
  IChannelsError
} from '@/types/interfaces/product/offer/accordions/channels';
import { IState } from '@/types/interfaces/store';
import { IOfferState } from '@/types/interfaces/store/product/offer';

export const state: IChannelsState = {
  isEditingChannels: false,
  isLoadingChannels: false,

  channels: null,
  originalChannels: null,
  emptyChannels: { marketplaceIdStorefrontIdPairs: {} },
  channelsErrors: null
};

export const getters: GetterTree<IChannelsState, IChannelsState> &
  IChannelsGetters = {
  [PD_OFFER.GETTERS.GET_STOREFRONT_IDS]: (
    state: IChannelsState
  ): Array<number | null> => {
    if (!state.channels?.marketplaceIdStorefrontIdPairs) {
      return [];
    }
    return Object.values(state.channels.marketplaceIdStorefrontIdPairs);
  }
};

export const actions: ActionTree<IOfferState, IState> & IChannelsActions = {
  [PD_OFFER.ACTIONS.GET_CHANNELS](
    { commit },
    { productId, offerId }: { productId: number; offerId: number }
  ) {
    return new Promise((resolve, reject) => {
      commit(PD_OFFER.MUTATIONS.SET_IS_LOADING_CHANNELS, true);

      const url =
        process.env.VUE_APP_CATALOG_SERVICE +
        '/products/' +
        productId +
        '/offers/' +
        offerId +
        '/channels';

      ApiService.get(url)
        .then((response: AxiosResponse<IChannelsFromBackend[]>) => {
          const channels = {
            marketplaceIdStorefrontIdPairs: helpers.convertChannelsToIdsPairs(
              response.data
            )
          };
          commit(PD_OFFER.MUTATIONS.SET_IS_LOADING_CHANNELS, false);
          commit(PD_OFFER.MUTATIONS.SET_CHANNELS, channels);
          commit(PD_OFFER.MUTATIONS.SET_ORIGINAL_CHANNELS, channels);
          resolve(channels);
        })
        .catch((error: AxiosError) => {
          commit(PD_OFFER.MUTATIONS.SET_IS_LOADING_CHANNELS, false);
          reject(error);
        });
    });
  }
};

const mutations: MutationTree<IOfferState> & ChannelsMutationsTypes = {
  [PD_OFFER.MUTATIONS.SET_IS_EDITING_CHANNELS](
    state: IChannelsState,
    value: boolean
  ) {
    state.isEditingChannels = value;
  },

  [PD_OFFER.MUTATIONS.SET_IS_LOADING_CHANNELS](
    state: IChannelsState,
    value: boolean
  ) {
    state.isLoadingChannels = value;
  },

  [PD_OFFER.MUTATIONS.SET_CHANNELS](state: IChannelsState, value: IChannels) {
    state.channels = value;
  },

  [PD_OFFER.MUTATIONS.SET_ORIGINAL_CHANNELS](
    state: IChannelsState,
    value: IChannels
  ) {
    state.originalChannels = cloneDeep(value);
  },

  [PD_OFFER.MUTATIONS.SET_CHANNELS_ERRORS](
    state: IChannelsState,
    value: IChannelsError
  ) {
    state.channelsErrors = value;
  }
};

const helpers = {
  convertChannelsToIdsPairs: (channels: IChannelsFromBackend[]) => {
    const idsPairs: Record<number, number | null> = {};

    channels.forEach((item) => {
      if (!item.marketplaceId) {
        return;
      }
      idsPairs[item.marketplaceId] = item.marketplaceStorefrontId;
    });

    return idsPairs;
  },

  prepareChannelsForRequest: (channels: IChannels) => {
    const idsPairs = channels.marketplaceIdStorefrontIdPairs;

    return Object.keys(idsPairs).map((key) => {
      return {
        marketplaceId: key,
        marketplaceStorefrontId: idsPairs[parseInt(key)]
      };
    });
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
  helpers
};
