import ApiService from '@/services/api.service';
import StorageService from '@/services/storage.service';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { AxiosError, AxiosResponse } from 'axios';
import { IState } from '@/types/interfaces/store';
import {
  IUserActions,
  IUserGetters,
  IUserState,
  UserMutationsTypes
} from '@/types/interfaces/store/user/user';
import { USER } from '@/types/constants';

export const state: IUserState = {
  token: StorageService.getItem('token'),
  id: StorageService.getItem('userId'),
  accountId: StorageService.getItem('accountId'),
  roles: StorageService.getArray('userRoles'),

  authenticating: false,
  authenticationError: false,

  profile: StorageService.getObject('profile')
};

export const getters: GetterTree<IUserState, IUserState> & IUserGetters = {
  [USER.GETTERS.IS_LOGGED_IN]: (state) => !!state.token,
  [USER.GETTERS.IS_AUTHENTICATING]: (state) => state.authenticating,
  [USER.GETTERS.IS_AUTHENTICATION_ERROR]: (state) => state.authenticationError,
  [USER.GETTERS.GET_PROFILE]: (state) => state.profile,
  [USER.GETTERS.GET_PROFILE_TYPE]: (state) => {
    if (!state.profile) {
      return '';
    }
    return state.profile.type;
  },
  [USER.GETTERS.GET_USER_ROLES]: (state) => {
    return state.roles;
  }
};

export const actions: ActionTree<IState, IState> & IUserActions = {
  [USER.ACTIONS.LOGIN]({ commit }, { email, password }) {
    return new Promise((resolve, reject) => {
      commit(USER.MUTATIONS.LOGIN_REQUEST);

      const requestData = {
        method: 'post',
        url: process.env.VUE_APP_USER_SERVICE + '/login',
        data: {
          email: email,
          password: password
        }
      };

      authCall(requestData, commit, resolve, reject);
    });
  },

  [USER.ACTIONS.GET_PROFILE]({ commit }) {
    return new Promise((resolve, reject) => {
      StorageService.removeItem('profile');
      commit(USER.MUTATIONS.GET_PROFILE_REQUEST);

      const requestData = {
        method: 'get',
        url: process.env.VUE_APP_USER_SERVICE + '/profile'
      };

      ApiService.customRequest(requestData)
        .then((response) => {
          StorageService.setObject('profile', response.data);

          commit(USER.MUTATIONS.GET_PROFILE_SUCCESS, response.data);

          resolve(response);
        })
        .catch((err) => {
          commit(USER.MUTATIONS.GET_PROFILE_ERROR);

          reject(err);
        });
    });
  },

  [USER.ACTIONS.LOGOUT]({ commit }) {
    return new Promise<void>((resolve) => {
      commit(USER.MUTATIONS.LOGOUT_REQUEST);

      const requestData = {
        method: 'post',
        url: process.env.VUE_APP_USER_SERVICE + '/logoutUser'
      };

      ApiService.customRequest(requestData).finally(() => {
        StorageService.clear();

        commit(USER.MUTATIONS.LOGOUT_SUCCESS);

        resolve();
      });
    });
  },

  [USER.ACTIONS.REFRESH_TOKEN]({ commit }) {
    return new Promise((resolve, reject) => {
      const requestData = {
        method: 'get',
        url: process.env.VUE_APP_USER_SERVICE + '/refreshToken',
        headers: {
          Authorization: `Bearer ${StorageService.getItem('refreshToken')}`
        }
      };

      authCall(requestData, commit, resolve, reject);
    });
  },

  [USER.ACTIONS.VERIFY_SET_PASSWORD]({ commit }, { id, token }) {
    return new Promise((resolve, reject) => {
      commit(USER.MUTATIONS.VERIFY_SET_PASSWORD_REQUEST);

      const requestData = {
        method: 'get',
        url: process.env.VUE_APP_USER_SERVICE + '/verify-set-password',
        params: {
          id,
          token
        }
      };

      ApiService.customRequest(requestData)
        .then((response) => {
          commit(USER.MUTATIONS.VERIFY_SET_PASSWORD_SUCCESS);

          resolve(response.data);
        })
        .catch((err) => {
          commit(USER.MUTATIONS.VERIFY_SET_PASSWORD_ERROR);

          reject(err);
        });
    });
  },

  [USER.ACTIONS.SET_PASSWORD]({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit(USER.MUTATIONS.SET_PASSWORD_REQUEST);

      const requestData = {
        method: 'post',
        url: process.env.VUE_APP_USER_SERVICE + '/set-password',
        data
      };

      ApiService.customRequest(requestData)
        .then((response) => {
          commit(USER.MUTATIONS.SET_PASSWORD_SUCCESS);

          resolve(response.data);
        })
        .catch((err) => {
          commit(USER.MUTATIONS.SET_PASSWORD_ERROR);

          reject(err);
        });
    });
  },

  [USER.ACTIONS.FORGOT_PASSWORD]({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit(USER.MUTATIONS.FORGOT_PASSWORD_REQUEST);

      const requestData = {
        method: 'post',
        url: process.env.VUE_APP_USER_SERVICE + '/forgot-password',
        data
      };

      ApiService.customRequest(requestData)
        .then((response) => {
          commit(USER.MUTATIONS.FORGOT_PASSWORD_SUCCESS);

          resolve(response.data);
        })
        .catch((err) => {
          commit(USER.MUTATIONS.FORGOT_PASSWORD_ERROR);

          reject(err);
        });
    });
  }
};

const authCall = (requestData, commit, resolve, reject) => {
  ApiService.customRequest(requestData)
    .then((response) => {
      StorageService.setItem('token', response.data.accessToken);
      StorageService.setItem('refreshToken', response.data.refreshToken);
      StorageService.setItem('userId', response.data.id);
      StorageService.setArray('userRoles', response.data.roles);
      if (response.data.accountId) {
        StorageService.setItem('accountId', response.data.accountId);
      }

      commit(USER.MUTATIONS.LOGIN_SUCCESS, response.data);

      ApiService.setHeader();

      resolve(response);
    })
    .catch((err) => {
      StorageService.removeItem('token');

      commit(USER.MUTATIONS.LOGIN_ERROR);

      reject(err);
    });
};

const mutations: MutationTree<IState> & UserMutationsTypes = {
  [USER.MUTATIONS.LOGIN_REQUEST](state) {
    state.authenticating = true;
    state.authenticationError = false;
  },
  [USER.MUTATIONS.LOGIN_ERROR](state) {
    state.authenticating = false;
    state.authenticationError = true;
    state.token = null;
  },
  [USER.MUTATIONS.LOGIN_SUCCESS](state, data) {
    state.authenticating = false;
    state.authenticationError = false;
    state.token = data.accessToken;
    state.id = data.id;
    state.accountId = data.accountId;
  },

  [USER.MUTATIONS.LOGOUT_REQUEST]() {},
  [USER.MUTATIONS.LOGOUT_SUCCESS](state) {
    state.token = null;
    state.profile = null;
    state.id = null;
    state.accountId = null;
  },

  [USER.MUTATIONS.GET_PROFILE_REQUEST](state) {
    state.authenticating = true;
    state.authenticationError = false;
    state.profile = null;
  },
  [USER.MUTATIONS.GET_PROFILE_ERROR](state) {
    state.authenticating = false;
    state.authenticationError = true;
  },
  [USER.MUTATIONS.GET_PROFILE_SUCCESS](state, profile) {
    state.authenticating = false;
    state.authenticationError = false;
    state.profile = profile;
  },

  [USER.MUTATIONS.VERIFY_SET_PASSWORD_REQUEST]() {},
  [USER.MUTATIONS.VERIFY_SET_PASSWORD_SUCCESS]() {},
  [USER.MUTATIONS.VERIFY_SET_PASSWORD_ERROR]() {},

  [USER.MUTATIONS.SET_PASSWORD_REQUEST]() {},
  [USER.MUTATIONS.SET_PASSWORD_SUCCESS]() {},
  [USER.MUTATIONS.SET_PASSWORD_ERROR]() {},

  [USER.MUTATIONS.FORGOT_PASSWORD_REQUEST]() {},
  [USER.MUTATIONS.FORGOT_PASSWORD_SUCCESS]() {},
  [USER.MUTATIONS.FORGOT_PASSWORD_ERROR]() {},
  [USER.MUTATIONS.SET_PROFILE_TYPE](state, type) {
    state.profile!.type = type;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
