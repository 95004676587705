import { createApp } from 'vue';
import '@/assets/css/tailwind.css';
import 'lib-vue-components/src/vee-validate';

import App from '@/App.vue';
const app = createApp(App);

import router from '@/router';
import store from '@/store';
app.use(router);
app.use(store);

import Components from './lib-vue-components';
Object.keys(Components).forEach((name) => {
  app.component(name, Components[name]);
});

import i18n from './i18n-config';
app.use(i18n);

import VueHotjar from 'vue-hotjar-next';
if (process.env.VUE_APP_HOTJAR_SITE_ID) {
  app.use(VueHotjar, {
    id: parseInt(process.env.VUE_APP_HOTJAR_SITE_ID)
  });
}

import FontAwesomeIcon from './font-awesome';
app.component('font-awesome-icon', FontAwesomeIcon);

// layouts
import DefaultLayout from '@/layouts/default';
import PlainLayout from '@/layouts/plain';
app.component('default-layout', DefaultLayout);
app.component('plain-layout', PlainLayout);

// global plugins
import ToastNotification from '@/plugins/toast-notification/index';
import VueClickAway from 'vue3-click-away';
app.use(ToastNotification);
app.use(VueClickAway);

app.mount('#app');
