import { onBeforeMount } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { PD_CONT, PRODUCT } from '@/types/constants';

export function useOnPageLoad() {
  const route = useRoute();
  const store = useStore();

  onBeforeMount(() => {
    onProductPageLoad();
  });

  const onProductPageLoad = async () => {
    setIsAddingNewProduct();
    setProductId();
    await store.dispatch('product/' + PRODUCT.ACTIONS.GET_OFFERS_LIST);
    await store.dispatch('product/' + PRODUCT.ACTIONS.GET_PRODUCT_CONTENT);

    if (store.state.product.isAddingNewProduct) {
      return;
    }

    getBasicProductInfo();
    getCategoryAttributes();
    getDescription();
    getDimensions();
    getMedia();
  };

  const getBasicProductInfo = () => {
    const action = 'product/' + PD_CONT.ACTIONS.GET_BASIC_PRODUCT_INFO;
    store.dispatch(action, store.state.product.productId);
  };

  const getCategoryAttributes = () => {
    const action = 'product/' + PD_CONT.ACTIONS.GET_CATEGORY_ATTRIBUTES;
    store.dispatch(action, store.state.product.productId);
  };

  const getDescription = () => {
    const action = 'product/' + PD_CONT.ACTIONS.GET_DESCRIPTION;
    store.dispatch(action, store.state.product.productId);
  };

  const getDimensions = () => {
    const action = 'product/' + PD_CONT.ACTIONS.GET_DIMENSIONS;
    store.dispatch(action, store.state.product.productId);
  };

  const getMedia = () => {
    const action = 'product/' + PD_CONT.ACTIONS.GET_MEDIA;
    store.dispatch(action, store.state.product.productId);
  };

  const setIsAddingNewProduct = () => {
    const isAddingNewProduct = !route.meta.editing;
    store.commit(
      'product/' + PRODUCT.MUTATIONS.SET_IS_ADDING_NEW_PRODUCT,
      isAddingNewProduct
    );
  };

  const setProductId = () => {
    const productId = route.params.id;
    store.commit('product/' + PRODUCT.MUTATIONS.SET_PRODUCT_ID, productId);
  };

  return {
    onProductPageLoad
  };
}
