import { cloneDeep } from 'lodash';
import ApiService from '@/services/api.service';
import {
  IPricingState,
  IPricingGetters,
  IPricingActions,
  PricingMutationsTypes
} from '@/types/interfaces/store/product/offer/accordions/pricing';
import { PD_OFFER } from '@/types/constants';
import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { AxiosError, AxiosResponse } from 'axios';
import {
  IPricing,
  IPricingError
} from '@/types/interfaces/product/offer/accordions/pricing';
import { IState } from '@/types/interfaces/store';
import { IOfferState } from '@/types/interfaces/store/product/offer';

export const state: IPricingState = {
  isEditingPricing: false,
  isLoadingPricing: false,

  pricing: null,
  originalPricing: null,
  emptyPricing: {
    listingPrice: null,
    pricingRestrictions: null,
    pricingRestrictionType: null,
    restrictedPrice: null
  },
  pricingErrors: null
};

export const getters: GetterTree<IPricingState, IPricingState> &
  IPricingGetters = {};

export const actions: ActionTree<IOfferState, IState> & IPricingActions = {
  [PD_OFFER.ACTIONS.GET_PRICING](
    { commit },
    { productId, offerId }: { productId: number; offerId: number }
  ) {
    return new Promise((resolve, reject) => {
      commit(PD_OFFER.MUTATIONS.SET_IS_LOADING_PRICING, true);

      const url =
        process.env.VUE_APP_CATALOG_SERVICE +
        '/products/' +
        productId +
        '/offers/' +
        offerId +
        '/pricing';

      ApiService.get(url)
        .then((response: AxiosResponse<IPricing>) => {
          commit(PD_OFFER.MUTATIONS.SET_IS_LOADING_PRICING, false);
          commit(PD_OFFER.MUTATIONS.SET_PRICING, response.data);
          commit(PD_OFFER.MUTATIONS.SET_ORIGINAL_PRICING, response.data);
          resolve(response.data);
        })
        .catch((error: AxiosError) => {
          commit(PD_OFFER.MUTATIONS.SET_IS_LOADING_PRICING, false);
          reject(error);
        });
    });
  }
};

const mutations: MutationTree<IOfferState> & PricingMutationsTypes = {
  [PD_OFFER.MUTATIONS.SET_IS_EDITING_PRICING](
    state: IPricingState,
    value: boolean
  ) {
    state.isEditingPricing = value;
  },

  [PD_OFFER.MUTATIONS.SET_IS_LOADING_PRICING](
    state: IPricingState,
    value: boolean
  ) {
    state.isLoadingPricing = value;
  },

  [PD_OFFER.MUTATIONS.SET_PRICING](state: IPricingState, value: IPricing) {
    state.pricing = value;
  },

  [PD_OFFER.MUTATIONS.SET_ORIGINAL_PRICING](
    state: IPricingState,
    value: IPricing
  ) {
    state.originalPricing = cloneDeep(value);
  },

  [PD_OFFER.MUTATIONS.SET_PRICING_ERRORS](
    state: IPricingState,
    value: IPricingError
  ) {
    state.pricingErrors = value;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
