export enum DimensionsUnitValueType {
  '' = '',
  MM = 'mm',
  CM = 'cm',
  M = 'm',
  IN = 'in',
  FT = 'ft'
}

export enum WeightUnitValueType {
  '' = '',
  G = 'g',
  KG = 'kg',
  OZ = 'oz',
  LB = 'lb'
}

export enum IdentifiersValueType {
  '' = '',
  GTIN = 'gtin',
  UPC = 'upc',
  EAN = 'ean',
  IAN = 'ian'
}

export enum EavAttributeBooleanValueType {
  '' = '',
  TRUE = 'true',
  FALSE = 'false'
}

export enum ConditionValueType {
  NEW = 'new',
  OPEN = 'open',
  RECERTIFIED = 'recertified',
  OTHER = 'other'
}

export enum MarginTypeValueType {
  '' = '',
  FULFILLMENT_COST = 'fulfillment-cost',
  LISTING_PRICE = 'listing-price',
  MSRP = 'msrp'
}

export enum WarrantyDateValueType {
  '' = '',
  DAYS = 'days',
  MONTHS = 'months',
  YEARS = 'years'
}

export enum PricingRestrictionTypesValueType {
  '' = '',
  UPP = 'upp',
  MAP = 'map',
  FLOOR = 'floor'
}

export enum HoldbackAmountValueType {
  PERCENT = 'PERCENT',
  UNITS = 'UNITS'
}

export interface DropdownOption<T> {
  value: T;
  label: string;
}
