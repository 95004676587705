import { ref } from 'vue';

export function useStateValues(emits) {
  const isEditing = ref({});
  const setIsEditing = (value: boolean, tabName: string) => {
    isEditing.value[tabName] = value;
    emits('isEditing', Object.values(isEditing.value).includes(true));
  };

  const isProposing = ref({});
  const setIsProposing = (value: boolean, tabName: string) => {
    isProposing.value[tabName] = value;
    emits('isProposing', Object.values(isProposing.value).includes(true));
  };

  const isUpdating = ref({});
  const setIsUpdating = (value: boolean, tabName: string) => {
    isUpdating.value[tabName] = value;
    emits('isUpdating', Object.values(isUpdating.value).includes(true));
  };

  return {
    isEditing,
    setIsEditing,
    isProposing,
    setIsProposing,
    isUpdating,
    setIsUpdating
  };
}
