<template src="./template.pug" lang="pug"></template>
<script>
export default {
  name: 'ProductType'
};
</script>
<script setup>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import StorageService from '@/services/storage.service';

const store = useStore();
const router = useRouter();

const goToNewProductPage = () => {
  router.push('/products/new');
};

const goToProductsPageWithOpenUploadModal = () => {
  StorageService.setItem('showBulkUploadModal', true);
  router.push('/products');
};
</script>
