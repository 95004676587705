export function useFormatters() {
  const getCurrencyFormat = (value: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(value);
  };

  const getDateFormat = (itemDate: string) => {
    const fullDate: Date = new Date(itemDate);
    const month = getMonthName(fullDate.getMonth());
    const date = fullDate.getDate();
    const year = fullDate.getFullYear();
    const time = getTimeAMPMFormat(fullDate);
    return month + ' ' + date + ', ' + year + ' ' + time;
  };

  const getTimeAMPMFormat = (date: Date) => {
    let hours = date.getHours();
    let minutes: string | number = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;

    return hours + ':' + minutes + ' ' + ampm;
  };

  const getMonthName = (monthNumber: number) => {
    const monthNames: string[] = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];
    return monthNames[monthNumber];
  };

  return {
    getCurrencyFormat,
    getDateFormat
  };
}
