import { ref, nextTick, Ref } from 'vue';
import { useStore } from 'vuex';
import { MARGIN_STACK, PD_OFFER, PRODUCT } from '@/types/constants';

export function useDiscardChanges(
  activeHorizontalTab: Ref<string | undefined>,
  horizontalTabCodes: {
    PRODUCT_CONTENT: 'product-content';
    SKU_DETAILS: 'sku-details';
    MARGIN_STACK: 'margin-stack';
  },
  setActiveHorizontalTab: { (tabCode: string): any; (arg: string | null): any }
) {
  const store = useStore();
  let tabToNavigateAfterDiscard: string | null = null;
  const isDiscardModalOpen = ref<boolean>(false);

  const showDiscardModal = (tabCode: string) => {
    isDiscardModalOpen.value = true;
    tabToNavigateAfterDiscard = tabCode;
  };

  const closeDiscardModal = () => {
    isDiscardModalOpen.value = false;
  };

  const discardChangesInProductContent = () => {
    store.dispatch('product/' + PRODUCT.ACTIONS.DISCARD_CHANGES_PRODUCT);
    store.commit('product/' + PRODUCT.MUTATIONS.CLEAR_ERRORS_OBJECT);
  };

  const discardChangesInSkuDetails = () => {
    store.dispatch('product/offer/' + PD_OFFER.ACTIONS.DISCARD_CHANGES_OFFER);
    store.commit('product/offer/' + PD_OFFER.MUTATIONS.CLEAR_ERRORS_OBJECT);
  };

  const discardChangesInMarginStack = async () => {
    store.commit(
      'product/marginStack/' +
        MARGIN_STACK.MUTATIONS.SET_DISCARD_CHANGES_TRIGGER,
      true
    );
    await nextTick();
    store.commit(
      'product/marginStack/' +
        MARGIN_STACK.MUTATIONS.SET_DISCARD_CHANGES_TRIGGER,
      false
    );
  };

  const discardChanges = () => {
    if (tabToNavigateAfterDiscard) {
      setTimeout(() => setActiveHorizontalTab(tabToNavigateAfterDiscard), 100);
    }

    switch (activeHorizontalTab.value) {
      case horizontalTabCodes.PRODUCT_CONTENT:
        return discardChangesInProductContent();
      case horizontalTabCodes.SKU_DETAILS:
        return discardChangesInSkuDetails();
      case horizontalTabCodes.MARGIN_STACK:
        return discardChangesInMarginStack();
    }
  };

  return {
    isDiscardModalOpen,
    showDiscardModal,
    closeDiscardModal,
    discardChanges
  };
}
