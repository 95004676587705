import { PartnerType } from '@/types/enums/store/partner/partner';
import { useStore } from 'vuex';
import { computed } from 'vue';
import { PARTNER } from '@/types/constants';
import { IMarketplacesWithStatus } from '@/types/interfaces/product/grids/grids';
import { useOptions } from '@/composables/views/products/product-details/options';
import gridGetters from '../../../views/products/mixins/gridGetters';

export function useGridGetters() {
  const store = useStore();
  const { marketplaceOptionsWithNumberValue } = useOptions();
  const fulfillmentAndBrandPartners = computed(() => {
    return store.getters[
      'partner/' + PARTNER.GETTERS.GET_FULFILLMENT_AND_BRAND_PARTNERS
    ];
  });
  const marketplaces = computed(() => {
    return store.getters['partner/' + PARTNER.GETTERS.GET_MARKETPLACES];
  });

  const getFulfillmentWithSelfFulfilledOptions = () => {
    let fulfillments = [...fulfillmentAndBrandPartners.value];
    fulfillments = getFulfillmentsWithSelfFulfilled(fulfillments);
    return fulfillments.map((item) => {
      return {
        value: item.id,
        label: item.name,
        type: item.type
      };
    });
  };

  const getFulfillmentsWithSelfFulfilled = (fulfillments) => {
    if (!fulfillments) {
      return [];
    }

    const brandsIds: number[] = [];
    fulfillments.forEach((item) => {
      if (item.type === PartnerType.BRAND) {
        brandsIds.push(item.id);
      }
    });

    fulfillments.push({
      id: brandsIds,
      name: 'Self-Fulfilled',
      type: 'SELF_FULFILLED'
    });

    return fulfillments;
  };

  const getMarketplacesWithStatusOptions = () => {
    let marketplacesWithStatus: IMarketplacesWithStatus[] = [];
    const statusOptions = gridGetters.getStatusOptions();
    const marketplaceOptions = getMarketplaceOptions();

    statusOptions.forEach((status) => {
      marketplacesWithStatus.push({
        group: 'status',
        label: status.label,
        value: status.value
      });
    });

    marketplaceOptions.forEach((marketplace) => {
      marketplacesWithStatus.push({
        group: 'marketplace',
        label: marketplace.label,
        value: marketplace.value
      });
    });

    return marketplacesWithStatus;
  };

  const getMarketplaceOptions = () => {
    let marketplaceOptions: typeof marketplaceOptionsWithNumberValue = [];
    marketplaces.value.forEach((item) => {
      marketplaceOptions.push({ value: item.id, label: item.name });
    });

    return marketplaceOptions;
  };

  return {
    getFulfillmentWithSelfFulfilledOptions,
    getFulfillmentsWithSelfFulfilled,
    getMarketplacesWithStatusOptions,
    getMarketplaceOptions
  };
}
