import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col flex-1 relative px-10 overflow-hidden bg-surface" }
const _hoisted_2 = { class: "flex flex-1 overflow-hidden" }
const _hoisted_3 = { class: "flex flex-col flex-1 overflow-hidden" }
const _hoisted_4 = {
  class: "flex flex-col overflow-y-scroll h-full w-full px-1",
  id: "scroll_container"
}
const _hoisted_5 = {
  class: "ml-8 flex-shrink-0",
  style: {"min-width":"200px"}
}
const _hoisted_6 = { class: "pr-10 h-20 flex items-center justify-end" }

export function render(_ctx, _cache) {
  const _component_ProductPageHeader = _resolveComponent("ProductPageHeader")
  const _component_THorizontalTabs = _resolveComponent("THorizontalTabs")
  const _component_ProductContent = _resolveComponent("ProductContent")
  const _component_SkuDetails = _resolveComponent("SkuDetails")
  const _component_MarginStack = _resolveComponent("MarginStack")
  const _component_TSidenavV2 = _resolveComponent("TSidenavV2")
  const _component_TButton = _resolveComponent("TButton")
  const _component_DiscardChangesModal = _resolveComponent("DiscardChangesModal")
  const _component_SavedDataNotPublishedModal = _resolveComponent("SavedDataNotPublishedModal")
  const _component_TLoader = _resolveComponent("TLoader")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.isLoadingProductContent)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_ProductPageHeader, { onOpenSavedNotPublishedModal: _ctx.showSavedNotPublishedModal }, null, 8, ["onOpenSavedNotPublishedModal"]),
            _createVNode(_component_THorizontalTabs, {
              activeTab: _ctx.activeHorizontalTab,
              "onUpdate:activeTab": _ctx.setActiveHorizontalTab,
              tabs: _ctx.horizontalTabsItems,
              fullWidth: ""
            }, null, 8, ["activeTab", "onUpdate:activeTab", "tabs"]),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  (_ctx.isHorizontalTabActive(_ctx.horizontalTabCodes.PRODUCT_CONTENT))
                    ? (_openBlock(), _createBlock(_component_ProductContent, { key: 0 }))
                    : _createCommentVNode("", true),
                  (_ctx.isHorizontalTabActive(_ctx.horizontalTabCodes.SKU_DETAILS))
                    ? (_openBlock(), _createBlock(_component_SkuDetails, { key: 1 }))
                    : _createCommentVNode("", true),
                  (_ctx.isHorizontalTabActive(_ctx.horizontalTabCodes.MARGIN_STACK))
                    ? (_openBlock(), _createBlock(_component_MarginStack, {
                        key: 2,
                        ref: "marginStack"
                      }, null, 512))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_TSidenavV2, {
                  heading: _ctx.getActiveHorizontalTabName(),
                  scrollContainerId: "scroll_container",
                  items: _ctx.getSideNavItems(),
                  activeItemCode: _ctx.activeSideNavItem,
                  "onUpdate:activeItemCode": _cache[0] || (_cache[0] = $event => ((_ctx.activeSideNavItem) = $event)),
                  ref: "sideNav"
                }, null, 8, ["heading", "items", "activeItemCode"])
              ])
            ])
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["save-bar overflow-hidden text-right border-t border-light-gray shadow-xl transition-all duration-500 ease-in-out bg-surface transform flex-shrink-0", _ctx.isSaveBarVisible ? 'h-20' : 'h-0'])
          }, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_TButton, {
                id: "discard-btn",
                color: "primary",
                variant: "text",
                type: "button",
                label: "Discard",
                containerClass: "mr-8",
                onClick: _cache[1] || (_cache[1] = $event => (_ctx.showDiscardModal()))
              }),
              _createVNode(_component_TButton, {
                id: "save-product-btn",
                color: "primary",
                variant: "contained",
                type: "button",
                label: "Save",
                containerClass: "my-1.5",
                disabled: !_ctx.saveButtonActive,
                loading: _ctx.isSaving,
                onClick: _ctx.onSaveButtonClick
              }, null, 8, ["disabled", "loading", "onClick"]),
              (_ctx.savedDataNotPublished)
                ? (_openBlock(), _createBlock(_component_TButton, {
                    key: 0,
                    id: "save-product-btn",
                    color: "primary",
                    variant: "contained",
                    type: "button",
                    label: "Update Published Channels",
                    containerClass: "my-1.5 ml-2",
                    disabled: !_ctx.savedDataNotPublished,
                    loading: _ctx.isSaving,
                    onClick: _ctx.publishMarketplaceAll
                  }, null, 8, ["disabled", "loading", "onClick"]))
                : _createCommentVNode("", true)
            ])
          ], 2),
          _createVNode(_component_DiscardChangesModal, {
            isOpen: _ctx.isDiscardModalOpen,
            "onUpdate:isOpen": _cache[2] || (_cache[2] = $event => ((_ctx.isDiscardModalOpen) = $event)),
            onDiscardChanges: _ctx.discardChanges
          }, null, 8, ["isOpen", "onDiscardChanges"]),
          _createVNode(_component_SavedDataNotPublishedModal, {
            isOpen: _ctx.isSavedNotPublishedModalOpen,
            "onUpdate:isOpen": _cache[3] || (_cache[3] = $event => ((_ctx.isSavedNotPublishedModalOpen) = $event)),
            onUpdatePublishedChanges: _ctx.publishMarketplaceAll,
            onContinueToOtherTab: _ctx.continueToOtherTab
          }, null, 8, ["isOpen", "onUpdatePublishedChanges", "onContinueToOtherTab"])
        ], 64))
      : _createCommentVNode("", true),
    _createVNode(_component_TLoader, {
      loading: _ctx.isLoadingProductContent || _ctx.isSaving || _ctx.isLoadingMarginStack || _ctx.isSavingMarginStack,
      opacity: _ctx.isSaving ? '80' : '100',
      transitionDuration: "300"
    }, null, 8, ["loading", "opacity"])
  ], 64))
}