import { useMarginStackInputs } from './margin-stack-inputs';
import { usePartnerTotals } from './partnerTotals';
import { computed } from 'vue';
import { IMarketplace } from '@/types/interfaces/product/margin-stack/margin-stack';

export function useMarginStack(
  marginStackMarketplace: { value: IMarketplace },
  marketplaceId: number
) {
  const allInputs = computed(() => {
    if (!marginStackMarketplace.value) {
      return [];
    }
    return marginStackMarketplace.value?.general.inputs.concat(
      marginStackMarketplace.value?.brand.inputs,
      marginStackMarketplace.value?.fulfillment.inputs,
      marginStackMarketplace.value?.marketplace.inputs,
      marginStackMarketplace.value?.marketnation.inputs
    );
  });

  const {
    getCalculatedInputValue,
    getInput,
    getInputDollarValueByFieldName,
    getInputValueWithDecorators
  } = useMarginStackInputs(allInputs);

  const { getPartnerTotals, getTotalOfAllInputs } = usePartnerTotals(
    marginStackMarketplace,
    getCalculatedInputValue,
    marketplaceId
  );

  return {
    getPartnerTotals,
    getTotalOfAllInputs,
    getCalculatedInputValue,
    getInput,
    getInputDollarValueByFieldName,
    getInputValueWithDecorators
  };
}
