import { cloneDeep } from 'lodash';
import ApiService from '@/services/api.service';
import {
  IDimensionsActions,
  IDimensionsGetters,
  IDimensionsState,
  DimensionsMutationsTypes
} from '@/types/interfaces/store/product/offer/accordions/dimensions';
import { PD_OFFER } from '@/types/constants';
import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { AxiosError, AxiosResponse } from 'axios';
import {
  IDimensions,
  IDimensionsError
} from '@/types/interfaces/product/offer/accordions/dimensions';
import { IState } from '@/types/interfaces/store';
import { IOfferState } from '@/types/interfaces/store/product/offer';

export const state: IDimensionsState = {
  isEditingDimensions: false,
  isLoadingDimensions: false,

  dimensions: null,
  originalDimensions: null,
  emptyDimensions: {
    shippingWeight: null,
    shippingWeightUnit: null,
    shippingHeight: null,
    shippingLength: null,
    shippingWidth: null,
    shippingDimensionsUnit: null
  },
  dimensionsErrors: null
};

export const getters: GetterTree<IDimensionsState, IDimensionsState> &
  IDimensionsGetters = {};

export const actions: ActionTree<IOfferState, IState> & IDimensionsActions = {
  [PD_OFFER.ACTIONS.GET_DIMENSIONS](
    { commit },
    { productId, offerId }: { productId: number; offerId: number }
  ) {
    return new Promise((resolve, reject) => {
      commit(PD_OFFER.MUTATIONS.SET_IS_LOADING_DIMENSIONS, true);

      const url =
        process.env.VUE_APP_CATALOG_SERVICE +
        '/products/' +
        productId +
        '/offers/' +
        offerId +
        '/dimension';

      ApiService.get(url)
        .then((response: AxiosResponse<IDimensions>) => {
          commit(PD_OFFER.MUTATIONS.SET_IS_LOADING_DIMENSIONS, false);
          commit(PD_OFFER.MUTATIONS.SET_DIMENSIONS, response.data);
          commit(PD_OFFER.MUTATIONS.SET_ORIGINAL_DIMENSIONS, response.data);
          resolve(response.data);
        })
        .catch((error: AxiosError) => {
          commit(PD_OFFER.MUTATIONS.SET_IS_LOADING_DIMENSIONS, false);
          reject(error);
        });
    });
  }
};

const mutations: MutationTree<IOfferState> & DimensionsMutationsTypes = {
  [PD_OFFER.MUTATIONS.SET_IS_EDITING_DIMENSIONS](
    state: IDimensionsState,
    value: boolean
  ) {
    state.isEditingDimensions = value;
  },

  [PD_OFFER.MUTATIONS.SET_IS_LOADING_DIMENSIONS](
    state: IDimensionsState,
    value: boolean
  ) {
    state.isLoadingDimensions = value;
  },

  [PD_OFFER.MUTATIONS.SET_DIMENSIONS](
    state: IDimensionsState,
    value: IDimensions
  ) {
    state.dimensions = value;
  },

  [PD_OFFER.MUTATIONS.SET_ORIGINAL_DIMENSIONS](
    state: IDimensionsState,
    value: IDimensions
  ) {
    state.originalDimensions = cloneDeep(value);
  },

  [PD_OFFER.MUTATIONS.SET_DIMENSIONS_ERRORS](
    state: IDimensionsState,
    value: IDimensionsError
  ) {
    state.dimensionsErrors = value;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
