import ForgotPassword from '@/views/forgot-password';
import Login from '@/views/login';
import SetPassword from '@/views/set-password';

let loginRoutes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      layout: 'plain',
      public: true,
      onlyWhenLoggedOut: true // Allow access to guest user
    }
  },
  {
    path: '/set-password',
    name: 'set-password',
    component: SetPassword,
    meta: {
      layout: 'plain',
      public: true,
      onlyWhenLoggedOut: true // Allow access to guest user
    }
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword,
    meta: {
      layout: 'plain',
      public: true,
      onlyWhenLoggedOut: true // Allow access to guest user
    }
  }
];

export default loginRoutes;
