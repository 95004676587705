<template src="./template.pug" lang="pug"></template>

<script>
import sidebarTopItems from '../sidebarTopItems';

export default {
  name: 'DefaultLayout',
  mixins: [sidebarTopItems],
  props: {
    isLoadingPartners: {
      type: Boolean,
      default: true
    }
  }
};
</script>
