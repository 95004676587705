<template src="./template.pug" lang="pug"></template>
<script>
import MarginStackInput from './input';
import MarginStackInputTrashButton from './trash-button';
import MarginStackApprovals from './approvals';

export default {
  name: 'MarginStackInputsTab',
  components: {
    MarginStackInput,
    MarginStackInputTrashButton,
    MarginStackApprovals
  }
};
</script>

<script setup>
import { ref, defineProps, defineEmits, computed, inject } from 'vue';
import { useFormatters } from '@/composables/formatters';
import { useIsEditing } from '@/composables/views/products/product-details/margin-stack/marketplace/margin-stack-inputs/inputs-tab/isEditing';
import { useDiscardChanges } from '@/composables/views/products/product-details/margin-stack/marketplace/margin-stack-inputs/inputs-tab/discardChanges';

const { getCurrencyFormat } = useFormatters();

const props = defineProps({
  inputs: {
    type: Array,
    required: true
  },
  tabName: {
    type: String,
    default: ''
  },
  title: {
    type: String,
    default: ''
  },
  approvalStatus: {
    type: String,
    default: ''
  },
  total: {
    type: Number,
    default: 0
  },
  rebate: {
    type: Object,
    default: null
  },
  areAllSectionsApproved: {
    type: Boolean,
    default: false
  }
});

const emits = defineEmits([
  'isEditing',
  'isProposing',
  'isUpdating',
  'editedInputs',
  'update:rebate',
  'update:approvalStatus'
]);

const { isEditingInputsTab, originalInputs, originalRebate } = useIsEditing(
  props,
  emits
);

useDiscardChanges(props, emits, originalInputs, originalRebate);

const getInputDollarValueByFieldName = inject('getInputDollarValueByFieldName');
const getInputValueWithDecorators = inject('getInputValueWithDecorators');

const _approvalStatus = computed({
  get() {
    return props.approvalStatus;
  },
  set(value) {
    emits('update:approvalStatus', value);
  }
});

const isTabGeneral = () => {
  return props.tabName === 'general';
};

const getTabTotalValue = () => {
  const absTotal = Math.abs(props.total);
  if (props.tabName === 'brand') {
    return getCurrencyFormat(absTotal);
  }
  if (props.total > 0) {
    return `(${getCurrencyFormat(absTotal)})`;
  }
  return getCurrencyFormat(absTotal);
};

const deleteInput = (index) => {
  props.inputs.splice(index, 1);
};

const isRebateHovered = ref(false);

const deleteRebate = () => {
  emits('update:rebate', null);
};

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});

const getMnMinimumFee = () => {
  const minFee = getInputDollarValueByFieldName(
    'MarketNation Minimum Transaction Service Fee'
  );
  return currencyFormatter.format(minFee);
};

const shouldDisplayMnMinimumInUseWarning = (input) => {
  if (input.title.toLowerCase() === 'transaction service fee') {
    const mnMinimumFee = getInputDollarValueByFieldName(
      'MarketNation Minimum Transaction Service Fee'
    );
    const mnTransFee = getInputDollarValueByFieldName(
      'Transaction service fee'
    );

    if (mnMinimumFee > mnTransFee) {
      return true;
    }
  }
  return false;
};
</script>
