import { cloneDeep } from 'lodash';
import ApiService from '@/services/api.service';
import {
  IProductBasicInfoState,
  IProductBasicInfoGetters,
  IProductBasicInfoActions,
  ProductBasicInfoMutationsTypes
} from '@/types/interfaces/store/product/accordions/basic-product-info';
import { PD_CONT } from '@/types/constants';
import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { AxiosError, AxiosResponse } from 'axios';
import {
  IBasicProductInfo,
  IBasicProductInfoError
} from '@/types/interfaces/product/accordions/basic-product-info';

import { IState } from '@/types/interfaces/store';

export const state: IProductBasicInfoState = {
  isEditingBasicProductInfo: false,
  isLoadingBasicProductInfo: false,

  basicProductInfo: null,
  originalBasicProductInfo: null,
  emptyBasicProductInfo: {
    title: '',
    mpn: '',
    categoryId: null,
    brandId: null,
    manufacturer: null,
    msrp: null,
    identifiers: [{ identifierType: '', identifier: '' }],
    savedDataNotPublished: false
  },
  basicProductInfoErrors: null
};

export const getters: GetterTree<
  IProductBasicInfoState,
  IProductBasicInfoState
> &
  IProductBasicInfoGetters = {
  [PD_CONT.GETTERS.GET_BRAND_ID]: (
    state: IProductBasicInfoState
  ): number | null | undefined => {
    return state.basicProductInfo?.brandId;
  },
  [PD_CONT.GETTERS.GET_CATEGORY_ID]: (
    state: IProductBasicInfoState
  ): number | null | undefined => {
    return state.basicProductInfo?.categoryId;
  },
  [PD_CONT.GETTERS.GET_MSRP]: (
    state: IProductBasicInfoState
  ): number | undefined | null => {
    return state.basicProductInfo?.msrp;
  }
};

export const actions: ActionTree<IState, IState> & IProductBasicInfoActions = {
  [PD_CONT.ACTIONS.GET_BASIC_PRODUCT_INFO]({ commit }, productId: number) {
    return new Promise((resolve, reject) => {
      commit(PD_CONT.MUTATIONS.SET_IS_LOADING_BASIC_PRODUCT_INFO, true);

      const url =
        process.env.VUE_APP_CATALOG_SERVICE +
        '/products/' +
        productId +
        '/basic-info';

      ApiService.get(url)
        .then((response: AxiosResponse<IBasicProductInfo>) => {
          commit(PD_CONT.MUTATIONS.SET_IS_LOADING_BASIC_PRODUCT_INFO, false);
          commit(PD_CONT.MUTATIONS.SET_BASIC_PRODUCT_INFO, response.data);
          commit(
            PD_CONT.MUTATIONS.SET_ORIGINAL_BASIC_PRODUCT_INFO,
            response.data
          );
          resolve(response.data);
        })
        .catch((error: AxiosError<IBasicProductInfoError>) => {
          commit(PD_CONT.MUTATIONS.SET_IS_LOADING_BASIC_PRODUCT_INFO, false);
          reject(error);
        });
    });
  }
};

const mutations: MutationTree<IState> & ProductBasicInfoMutationsTypes = {
  [PD_CONT.MUTATIONS.SET_IS_EDITING_BASIC_PRODUCT_INFO](
    state: IProductBasicInfoState,
    value: boolean
  ) {
    state.isEditingBasicProductInfo = value;
  },

  [PD_CONT.MUTATIONS.SET_IS_LOADING_BASIC_PRODUCT_INFO](
    state: IProductBasicInfoState,
    value: boolean
  ) {
    state.isLoadingBasicProductInfo = value;
  },

  [PD_CONT.MUTATIONS.SET_BASIC_PRODUCT_INFO](
    state: IProductBasicInfoState,
    value: IBasicProductInfo
  ) {
    state.basicProductInfo = value;
  },

  [PD_CONT.MUTATIONS.SET_ORIGINAL_BASIC_PRODUCT_INFO](
    state: IProductBasicInfoState,
    value: IBasicProductInfo
  ) {
    state.originalBasicProductInfo = cloneDeep(value);
  },

  [PD_CONT.MUTATIONS.SET_BASIC_PRODUCT_INFO_ERRORS](
    state: IProductBasicInfoState,
    value: IBasicProductInfoError
  ) {
    state.basicProductInfoErrors = value;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
