import { IFilters } from '@/types/interfaces/utils';
import {
  IOrderFilter,
  IProductFilter,
  IUploadFilter
} from '@/types/interfaces/product/grids/grids';

const gridQuery = {
  getGridQuery(filters: IFilters) {
    let filtersQuery = this.getGridFiltersQuery(filters.filter);
    return (
      '?page=' +
      (filters.currentPage ? filters.currentPage : 1) +
      (filters.sortByAttribute ? '&attribute=' + filters.sortByAttribute : '') +
      (filters.orderBy ? '&order=' + filters.orderBy : '') +
      filtersQuery
    );
  },

  getGridFiltersQuery(filters: IOrderFilter | IProductFilter | IUploadFilter) {
    if (!filters) {
      return '';
    }

    let filtersQuery = '';

    for (const [filter, value] of Object.entries(filters)) {
      if (!value) {
        continue;
      }

      if (Array.isArray(value) && value.length > 0) {
        filtersQuery += this.getGridFiltersQueryForArray(filter, value);
        continue;
      }
      if (typeof value === 'object') {
        filtersQuery += this.getGridFiltersQueryForObject(filter, value);
        continue;
      }

      filtersQuery += '&' + filter + '=' + value;
    }

    return filtersQuery;
  },

  getGridFiltersQueryForObject(filter: string, value) {
    if (!value.max && !value.min) {
      return '';
    }

    return (
      '&' +
      filter +
      '=' +
      (!value.max ? '' : value.max) +
      ',' +
      (!value.min ? '' : value.min)
    );
  },

  getGridFiltersQueryForArray(filter: string, value: string[]) {
    return '&' + filter + '=' + value.join(',');
  },

  getGridFiltersForPostRequest(filters) {
    for (const [key, value] of Object.entries(filters)) {
      if (!value) {
        delete filters[key];
      }
    }
    return filters;
  }
};

export default gridQuery;
