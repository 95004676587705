export function useGridsTheads() {
  const orders = [
    {
      name: 'id',
      label: 'Mn Order #',
      search: 'single',
      type: 'text',
      default: '-'
    },
    {
      name: 'marketplaces',
      label: 'Marketplace',
      search: 'multi',
      type: 'text',
      default: '-'
    },
    {
      name: 'marketplaceOrderId',
      label: 'Mp Order Id',
      search: 'single',
      type: 'text',
      default: '-'
    },
    {
      name: 'customer',
      label: 'Customer',
      search: 'single',
      type: 'text'
    },
    {
      name: 'date',
      label: 'Date',
      search: 'range',
      type: 'text'
    },
    {
      name: 'orderStatuses',
      label: 'Order Status',
      search: 'multi',
      type: 'text'
    }
  ];

  const uploadStatus = [
    {
      name: 'id',
      label: 'UPLOAD ID',
      colWidth: null,
      search: 'single',
      type: 'text',
      default: '-'
    },
    {
      name: 'date',
      label: 'DATE & TIME',
      colWidth: null,
      search: 'single',
      type: 'text',
      default: '-'
    },
    {
      name: 'username',
      label: 'USER NAME',
      colWidth: null,
      search: 'single',
      type: 'text',
      default: '-'
    },
    {
      name: 'status',
      label: 'UPLOAD STATUS',
      colWidth: null,
      search: 'multi',
      type: 'text'
    },
    {
      name: 'successCount',
      label: 'SUCCESSFUL',
      colWidth: null,
      search: 'single',
      type: 'text',
      default: '-'
    },
    {
      name: 'errorCount',
      label: 'ERRORS',
      colWidth: null,
      search: 'single',
      type: 'text',
      default: '-'
    }
  ];

  const updateQueue = [
    {
      name: 'identifier',
      label: 'IDENTIFIER',
      colWidth: null,
      search: 'single',
      type: 'text',
      default: '-'
    },
    {
      name: 'sku',
      label: 'SKU',
      colWidth: null,
      search: 'single',
      type: 'text',
      default: '-'
    },
    {
      name: 'title',
      label: 'Product title',
      colWidth: null,
      search: 'single',
      type: 'text',
      default: '-'
    },
    {
      name: 'fulfillments',
      label: 'Fulfillment',
      colWidth: null,
      search: 'multi',
      type: 'text'
    },
    {
      name: 'status',
      label: 'MP Status',
      colWidth: null,
      search: 'multi',
      type: 'text'
    },
    {
      name: 'productStatus',
      label: 'Opp Status',
      colWidth: null,
      search: 'multi',
      type: 'text'
    }
  ];

  return {
    orders,
    uploadStatus,
    updateQueue
  };
}
