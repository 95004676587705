export enum CategoryGetters {
  GET_CATEGORY_DETAILS = 'GET_CATEGORY_DETAILS',
  LOADING_CATEGORY_DETAILS = 'LOADING_CATEGORY_DETAILS',
  GET_CATEGORIES = 'GET_CATEGORIES',
  GET_CATEGORY_OPTIONS = 'GET_CATEGORY_OPTIONS'
}
export enum CategoryMutations {
  GET_CATEGORIES_REQUEST = 'GET_CATEGORIES_REQUEST',
  GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS',
  GET_CATEGORY_DETAILS_REQUEST = 'GET_CATEGORY_DETAILS_REQUEST',
  GET_CATEGORY_DETAILS_SUCCESS = 'GET_CATEGORY_DETAILS_SUCCESS',
  CLEAR_CATEGORY_DETAILS = 'CLEAR_CATEGORY_DETAILS'
}
export enum CategoryActions {
  GET_CATEGORIES_FROM_BACKEND = 'GET_CATEGORIES_FROM_BACKEND',
  GET_CATEGORY_DETAILS_FROM_BACKEND = 'GET_CATEGORY_DETAILS_FROM_BACKEND'
}
