import {
  ProductContentGetters,
  ProductContentActions,
  ProductContentMutations
} from './enums/store/product/product-content';
import {
  OfferGetters,
  OfferActions,
  OfferMutations
} from '@/types/enums/store/product/offer';
import {
  MarginStackGetters,
  MarginStackActions,
  MarginStackMutations
} from '@/types/enums/store/product/margin-stack';
import {
  PartnerGetters,
  PartnerMutations,
  PartnerActions
} from '@/types/enums/store/partner/partner';
import {
  CategoryGetters,
  CategoryMutations,
  CategoryActions
} from '@/types/enums/store/category/category';
import {
  ProductGetters,
  ProductMutations,
  ProductActions
} from '@/types/enums/store/product';

import {
  OrderGetters,
  OrderActions,
  OrderMutations
} from '@/types/enums/store/order/order';

import {
  UserGetters,
  UserMutations,
  UserActions
} from '@/types/enums/store/user/user';

import {
  TreatmentGetters,
  TreatmentMutations
} from '@/types/enums/store/treatment/treatment';

import {
  GridProductGetters,
  GridProductActions,
  GridProductMutations
} from '@/types/enums/store/product/grids/product';

import {
  GridUpdateQueueGetters,
  GridUpdateQueueActions,
  GridUpdateQueueMutations
} from '@/types/enums/store/product/grids/update-queue';

import {
  GridUploadStatusGetters,
  GridUploadStatusActions,
  GridUploadStatusMutations
} from '@/types/enums/store/product/grids/upload-status';

export const PD_CONT = {
  GETTERS: ProductContentGetters,
  MUTATIONS: ProductContentMutations,
  ACTIONS: ProductContentActions
};

export const PD_OFFER = {
  GETTERS: OfferGetters,
  MUTATIONS: OfferMutations,
  ACTIONS: OfferActions
};

export const MARGIN_STACK = {
  GETTERS: MarginStackGetters,
  MUTATIONS: MarginStackMutations,
  ACTIONS: MarginStackActions
};

export const PARTNER = {
  GETTERS: PartnerGetters,
  MUTATIONS: PartnerMutations,
  ACTIONS: PartnerActions
};

export const CATEGORY = {
  GETTERS: CategoryGetters,
  MUTATIONS: CategoryMutations,
  ACTIONS: CategoryActions
};

export const PRODUCT = {
  GETTERS: ProductGetters,
  MUTATIONS: ProductMutations,
  ACTIONS: ProductActions
};

export const ORDER = {
  GETTERS: OrderGetters,
  MUTATIONS: OrderMutations,
  ACTIONS: OrderActions
};

export const USER = {
  GETTERS: UserGetters,
  MUTATIONS: UserMutations,
  ACTIONS: UserActions
};

export const TREATMENT = {
  GETTERS: TreatmentGetters,
  MUTATIONS: TreatmentMutations
};

export const GRID_PRODUCTS = {
  GETTERS: GridProductGetters,
  MUTATIONS: GridProductMutations,
  ACTIONS: GridProductActions
};

export const GRID_UPDATE_QUEUE = {
  GETTERS: GridUpdateQueueGetters,
  MUTATIONS: GridUpdateQueueMutations,
  ACTIONS: GridUpdateQueueActions
};

export const GRID_UPLOAD_STATUS = {
  GETTERS: GridUploadStatusGetters,
  MUTATIONS: GridUploadStatusMutations,
  ACTIONS: GridUploadStatusActions
};
