import { useMarginStackInputCalculations } from './calculations';
import { isNil, isString } from 'lodash';
import { useFormatters } from '@/composables/formatters';
import { useMarginStackInputTypes } from './types';
import { useValueTypes } from './valueTypes';
import { InputType, } from '@/types/enums/store/product/margin-stack';
import { list } from "postcss";

export function useMarginStackInputs(inputsList) {
  const { getCurrencyFormat } = useFormatters();
  const { isInputTypeWithParenthesis } = useMarginStackInputTypes();
  const { isInputDate, isInputDecimal } = useValueTypes();

  const getInput = (type) => {
    return inputsList.value.find((input) => input.type === type);
  };
  

  /**
   * Helper method added to display if the minimum transaction fee or the mn transaction fee would be used.
   * @param fieldName - Name/title of the field - I.E. Listing Price
   */
  const getInputDollarValueByFieldName = (fieldName: String) => {
    const input = inputsList.value.find(
      (input) => input.title.toLowerCase() === fieldName.toLowerCase()
    );

    if (!input) {
      return 0.0;
    }

    // If its a percentage field then we have to get the listing price and
    // calcaluate the value that way.
    if (input.priceType.toLowerCase() === 'percent') {
      const listingPrice = inputsList.value.find(
        (input) => input.title.toLowerCase() === 'listing price'
      );
      if (
        !listingPrice ||
        !listingPrice.opportunityValue.value ||
        !input ||
        !input.opportunityValue.value
      ) {
        return 0.0;
      } else {
        return (
          Math.round(
            input.opportunityValue.value * listingPrice.opportunityValue.value
          ) / 100.0
        );
      }
    }

    // Else we can just assume that it is a dollar field until we clean up this
    // mess of a code base.
    return input.opportunityValue.value ? input.opportunityValue.value : 0.0;
  };

  //parameter - input type || input object
  const getCalculatedInputValue = (parameter) => {
    const { input } = getInputAndInputTypeFromParameter(parameter);

    if (isInputDecimal(input)) {
      return calculateInputValue(input);
    }

    return input?.opportunityValue.value;
  };

  //parameter - input type || input object
  const getInputValueWithDecorators = (parameter, tabName, mnMinFee) => {
    const { input, inputType } = getInputAndInputTypeFromParameter(parameter);
    let value = getCalculatedInputValue(input);

    checkInputForError(parameter);

    if (parameter === 'BUILT_IN_TRANSACTION_SERVICE_FEE' && value < mnMinFee) {
      value = mnMinFee;
    }

    if (isInputDate(input)) {
      return value;
    }

    if (isNil(value)) {
      return '-';
    }

    value = getCurrencyFormat(value);

    if (isInputTypeWithParenthesis(inputType)) {
      value = '(' + value + ')';
    }

    if (tabName === 'marketplace' && isInputTypeWithParenthesis(inputType)) {
      value = 'est.' + value;
    }

    return value;
  };

  const getInputAndInputTypeFromParameter = (parameter) => {
    let input = parameter;
    let inputType = parameter;

    if (!isString(parameter)) {
      inputType = parameter.type;
    }

    if (isString(parameter)) {
      input = getInput(inputType);
    }

    return {
      input,
      inputType
    };
  };

  const twoInputsMustBeFilledOutIfOneIsCheck = (changedInput, dependantInput) => {
    const dependantIsMissing = (!dependantInput.opportunityValue.value || dependantInput.opportunityValue.value === 0)
    const inputIsMissing = (!changedInput.opportunityValue.value || changedInput.opportunityValue.value === 0)
    // If they are both missing then there is no error
    if (inputIsMissing && dependantIsMissing) {
      return
    }

    dependantInput.hasError = dependantIsMissing;
    changedInput.hasError = inputIsMissing;
  }

  // This method was added recently to conform with all the backend changes that require checks on some fields.
  // It will allow the front end to control what pops up/borders happens as 1 field changes relative to the other fields.
  const checkInputForError = (parameter) => {
    const { input, inputType } = getInputAndInputTypeFromParameter(parameter);
  
    try {
      // Freight costs are only required if Product cost with fulfillment is entered
      if (inputType == 'BUILT_IN_FREIGHT_COST_EST' || inputType === 'BUILT_IN_PRODUCT_COST_WITH_FULFILLMENT') {
        const dependantInput = inputType == 'BUILT_IN_FREIGHT_COST_EST' ? getInput('BUILT_IN_PRODUCT_COST_WITH_FULFILLMENT') : getInput('BUILT_IN_FREIGHT_COST_EST')
        twoInputsMustBeFilledOutIfOneIsCheck(input, dependantInput)
      }

      //If optional Consumer paid shipping is entered, then Marketplace referral fee on shipping field should be required
      if (inputType == 'BUILT_IN_CONSUMER_PAID_SHIPPING' || inputType === 'BUILT_IN_MARKETPLACE_REFERRAL_ON_SHIPPING') {
        const dependantInput = inputType == 'BUILT_IN_CONSUMER_PAID_SHIPPING' ? getInput('BUILT_IN_MARKETPLACE_REFERRAL_ON_SHIPPING') : getInput('BUILT_IN_CONSUMER_PAID_SHIPPING')
        twoInputsMustBeFilledOutIfOneIsCheck(input, dependantInput)
      }
    } catch(e){ 
      input.hasError = false
    } 
  }

  const { calculateInputValue } = useMarginStackInputCalculations(
    getCalculatedInputValue
  );

  return {
    getCalculatedInputValue,
    getInput,
    getInputDollarValueByFieldName,
    getInputValueWithDecorators
  };
}
