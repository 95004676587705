import { usePartnerTypes } from '@/composables/partnerTypes';
import { useStore } from 'vuex';
import { PARTNER, PD_CONT, PD_OFFER } from '@/types/constants';

export function useEditPermissions() {
  const store = useStore();
  const {
    isUserMarketnation,
    isUserBrand,
    isUserFulfillment,
    isUserMarketplace
  } = usePartnerTypes();

  const canEditBrand = () => {
    if (isUserFulfillment()) {
      const fulfillmentPartnerId =
        store.getters['offer/' + PD_OFFER.GETTERS.GET_FULFILLMENT_PARTNER_ID];
      const brandId = store.getters['product/' + PD_CONT.GETTERS.GET_BRAND_ID];
      const fulfillmentPartner = store.getters[
        'partner/' + PARTNER.GETTERS.GET_FULFILLMENT_PARTNER_BY_ID
      ](fulfillmentPartnerId);

      return fulfillmentPartner?.brandEdit.includes(brandId);
    }

    return isUserMarketnation() || isUserBrand();
  };

  const canEditFulfillment = () => {
    return isUserFulfillment() || isUserMarketnation();
  };

  const canEditMarketplace = () => {
    return isUserMarketplace() || isUserMarketnation();
  };

  const canEditMarketnation = () => {
    return isUserMarketnation();
  };

  const canEditTab = (tabName: string) => {
    switch (tabName) {
      case 'general':
      case 'brand':
        return canEditBrand();
      case 'fulfillment':
        return canEditFulfillment();
      case 'marketplace':
        return canEditMarketplace();
      default:
        return canEditMarketnation();
    }
  };
  return {
    canEditBrand,
    canEditFulfillment,
    canEditMarketplace,
    canEditMarketnation,
    canEditTab
  };
}
