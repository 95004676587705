import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "pt-7 pb-7 inline-block" }
const _hoisted_2 = { class: "flex flex-col relative" }

export function render(_ctx, _cache) {
  const _component_SkuSelect = _resolveComponent("SkuSelect")
  const _component_BasicOfferInfo = _resolveComponent("BasicOfferInfo")
  const _component_Channels = _resolveComponent("Channels")
  const _component_Inventory = _resolveComponent("Inventory")
  const _component_OfferSpecificContent = _resolveComponent("OfferSpecificContent")
  const _component_Dimensions = _resolveComponent("Dimensions")
  const _component_TLoader = _resolveComponent("TLoader")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.desc), 1),
    _createVNode(_component_SkuSelect),
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.isLoadingOffer)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (_openBlock(), _createBlock(_component_BasicOfferInfo, {
              id: "basic-offer-info",
              key: _ctx.isAddingNewOffer
            })),
            (_openBlock(), _createBlock(_component_Channels, {
              id: "channels",
              key: _ctx.isAddingNewOffer
            })),
            (_openBlock(), _createBlock(_component_Inventory, {
              id: "inventory",
              key: _ctx.isAddingNewOffer
            })),
            (_openBlock(), _createBlock(_component_OfferSpecificContent, {
              id: "offer-specific-content",
              key: _ctx.isAddingNewOffer
            })),
            (_openBlock(), _createBlock(_component_Dimensions, {
              id: "dimensions-and-weight",
              key: _ctx.isAddingNewOffer
            }))
          ], 64))
        : _createCommentVNode("", true),
      _createVNode(_component_TLoader, {
        loading: _ctx.isLoadingOffer || _ctx.isLoadingOfferList,
        opacity: "100",
        transitionDuration: "300",
        paddingTop: "3",
        centerVertically: false,
        circleWidth: "45",
        circleHeight: "45"
      }, null, 8, ["loading"])
    ])
  ], 64))
}