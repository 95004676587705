const StorageService = {
  getItem(key, parseJson = false) {
    if (parseJson) {
      return JSON.parse(localStorage.getItem(key)) || {};
    }

    return localStorage.getItem(key);
  },

  setItem(key, value, stringifyJson = false) {
    if (stringifyJson) {
      value = JSON.stringify(value);
    }
    localStorage.setItem(key, value);
  },

  getObject(key) {
    return JSON.parse(localStorage.getItem(key)) || {};
  },

  setObject(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  },

  setArray(key, array) {
    localStorage.setItem(key, JSON.stringify(array));
  },

  getArray(key) {
    return JSON.parse(localStorage.getItem(key)) || [];
  },

  removeItem(key) {
    localStorage.removeItem(key);
  },

  clear() {
    localStorage.clear();
  }
};

export default StorageService;
