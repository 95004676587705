<template>
  <div>
    <t-modal
      :isOpen="isOpen"
      @update:isOpen="closeExportProductsModal"
      title="Download products"
      outlinedButtonLabel="Cancel"
      :outlinedButtonClick="closeExportProductsModal"
      containedButtonLabel="Download products"
      :containedButtonClick="submit"
      :isContainedButtonDisabled="isLoading"
    >
      <p class="inline">
        Choose the CSV file you would like to download and send through email
      </p>
      <div class="selectors-container">
        <div>
          <p class="mt-6 mb-3 font-bold text-sm">Download Product Content</p>
          <t-radio-set
            v-model="selectedProductOption"
            :options="productContentOptions"
            :disabledOptions="!isFiltering ? disabledOption : []"
            id="export_products"
            name="export_products"
            space-between-options="3"
            containerClass="mb-3"
          ></t-radio-set>
        </div>
        <div>
          <p class="mt-6 mb-3 font-bold text-sm">
            Download Margin Stack Records
          </p>
          <t-radio-set
            v-model="selectedMarginStackOption"
            :options="marginStackRecordOptions"
            :disabledOptions="!isFiltering ? disabledOption : []"
            id="export_records"
            name="export_records"
            space-between-options="3"
            containerClass="mb-3"
          ></t-radio-set>
        </div>
      </div>
    </t-modal>
  </div>
</template>

<script>
import StorageService from '@/services/storage.service';

export default {
  props: ['isOpen', 'gridProductIds', 'isCurrentTabAN'],
  inject: ['toastNotification'],
  name: 'exportProductsModal',
  data() {
    return {
      selectedProductOption: false,
      selectedMarginStackOption: false,
      isFiltering: null,
      activeFilters: null,
      isLoading: false,
      disabledOption: ['SELECTED'],
      guideLink:
        'https://marketnation.notion.site/PUG-Downloading-and-uploading-products-09bc57fe585540edba065c2243f67908'
    };
  },
  updated() {
    this.isFiltering = this.checkForFilters();
  },
  mounted() {
    this.isFiltering = this.checkForFilters();
    this.activeFilters = this.isCurrentTabAN
      ? StorageService.getObject('anProductGridFilters')
      : StorageService.getObject('productGridFilters');
  },
  watch: {
    isOpen: {
      handler(value) {
        this.selectedMarginStackOption = false;
        this.selectedProductOption = false;
        if (value) {
          this.activeFilters = this.isCurrentTabAN
            ? StorageService.getObject('anProductGridFilters')
            : StorageService.getObject('productGridFilters');
        }
      }
    }
  },
  methods: {
    checkForFilters() {
      let filters = this.activeFilters && this.activeFilters.filter;
      if (filters) {
        for (const [key, value] of Object.entries(filters)) {
          if (value) {
            return true;
          }
        }
        return false;
      }
    },
    closeExportProductsModal() {
      this.$emit('closeModal');
    },
    getExportNotificationErrorMessage() {
      return 'Product download could not start, please try again later';
    },
    getExportNotificationSuccessMessage() {
      const userEmail = this.$store.state.user.profile.email;
      return `Your download will be emailed to <i><b>${userEmail}</b></i>`;
    },
    async exportProductOffers() {
      this.isLoading = true;
      const payload = {
        type: this.selectedProductOption,
        productOfferIds: this.gridProductIds.product_offer_ids
      };
      await this.$store
        .dispatch('product/EXPORT_PRODUCTS', payload)
        .then(() => {
          this.toastNotification.success(
            this.getExportNotificationSuccessMessage()
          );
        })
        .catch(() => {
          this.toastNotification.error(
            this.getExportNotificationErrorMessage()
          );
        });

      this.isLoading = false;
    },
    async exportMarginStackRecords() {
      const payload = {
        type: this.selectedMarginStackOption,
        productOfferStorefrontIds: this.gridProductIds
          .product_offer_storefront_ids
      };
      await this.$store
        .dispatch('product/EXPORT_RECORDS', payload)
        .then(() => {
          this.toastNotification.success(
            this.getExportNotificationSuccessMessage()
          );
        })
        .catch(() => {
          this.toastNotification.error(
            this.getExportNotificationErrorMessage()
          );
        });
    },
    async submit() {
      this.selectedProductOption && (await this.exportProductOffers());
      this.selectedMarginStackOption && (await this.exportMarginStackRecords());
      this.closeExportProductsModal();
    }
  },
  computed: {
    marginStackRecordOptions() {
      return [
        { label: 'All records', value: 'ALL' },
        {
          label: `${
            this.gridProductIds.product_offer_storefront_ids &&
            this.gridProductIds.product_offer_storefront_ids.length
          } records matching your search`,
          value: 'SELECTED'
        },
        { label: 'Do not download', value: null }
      ];
    },
    productContentOptions() {
      return [
        {
          label: 'All products',
          value: 'ALL'
        },
        {
          label: `${
            this.gridProductIds.product_offer_ids &&
            this.gridProductIds.product_offer_ids.length
          } records matching your search`,
          value: 'SELECTED'
        },
        { label: 'Do not download', value: null }
      ];
    }
  }
};
</script>
<style lang="scss" scoped>
.selectors-container {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  align-items: flex-start;
  margin-top: 30px;

  & > div {
    padding-right: 20px;
  }

  &::v-deep p {
    margin-top: 0;
  }
}
</style>
