import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { MARGIN_STACK, PD_OFFER, PRODUCT } from '@/types/constants';

export function useHorizontalTabs(
  setFirstSideNavItemAsActiveElement: { (): any; (): void },
  showDiscardModal: (tab: string) => void,
  showSavedNotPublishedModal: (tab: string) => void
) {
  const route = useRoute();
  const store = useStore();

  const horizontalTabCodes = {
    PRODUCT_CONTENT: 'product-content',
    SKU_DETAILS: 'sku-details',
    MARGIN_STACK: 'margin-stack'
  };

  const horizontalTabsItems = [
    {
      code: horizontalTabCodes.PRODUCT_CONTENT,
      name: 'Product content'
    },
    {
      code: horizontalTabCodes.SKU_DETAILS,
      name: 'SKU details'
    },
    {
      code: horizontalTabCodes.MARGIN_STACK,
      name: 'Margin stack'
    }
  ];

  const activeHorizontalTab = ref<string>();

  const setActiveHorizontalTab = (tabCode: string) => {
    const isEditing =
      (store.getters['product/' + PRODUCT.GETTERS.IS_EDITING_PRODUCT] &&
        activeHorizontalTab.value === 'product-content') ||
      (store.getters['product/offer/' + PD_OFFER.GETTERS.IS_EDITING_OFFER] &&
        activeHorizontalTab.value === 'sku-details') ||
      (store.getters[
        'product/marginStack/' + MARGIN_STACK.GETTERS.IS_EDITING_MARGIN_STACK
      ] &&
        activeHorizontalTab.value === 'margin-stack');

    if (isEditing) {
      return showDiscardModal(tabCode);
    }
    if (
      store.getters[
        'product/offer/' + PD_OFFER.GETTERS.SAVED_DATA_NOT_PUBLISHED
      ] &&
      activeHorizontalTab.value === 'product-content'
    ) {
      return showSavedNotPublishedModal(tabCode);
    }

    activeHorizontalTab.value = tabCode;
    setFirstSideNavItemAsActiveElement();
  };

  const isHorizontalTabActive = (horizontalTabCode: string) => {
    return activeHorizontalTab.value === horizontalTabCode;
  };

  const getActiveHorizontalTabName = () => {
    return horizontalTabsItems.find(
      (item) => item.code === activeHorizontalTab.value
    )?.name;
  };

  const setActiveHorizontalTabBasedOnRoute = () => {
    const horizontalTabItem = route.hash?.replace('#', '')?.split('/')[0];
    const marketplaceId = route.query?.marketplace;

    if (horizontalTabItem && !horizontalTabItem.includes('offer')) {
      activeHorizontalTab.value = horizontalTabItem;
      return;
    }

    if (marketplaceId) {
      activeHorizontalTab.value = horizontalTabCodes.MARGIN_STACK;
      return;
    }

    activeHorizontalTab.value = horizontalTabCodes.PRODUCT_CONTENT;
  };

  onMounted(async () => {
    setTimeout(setActiveHorizontalTabBasedOnRoute, 500);
  });

  return {
    horizontalTabsItems,
    activeHorizontalTab,
    horizontalTabCodes,
    isHorizontalTabActive,
    getActiveHorizontalTabName,
    setActiveHorizontalTab
  };
}
