import { useMarginStackInputTypes } from './margin-stack-inputs/types';
import { useTabOwners } from './tabOwners';

export function usePartnerTotals(
  marginStackMarketplace: {
    value: {
      brand: any;
      fulfillment: any;
      marketplace: any;
      marketnation: any;
    };
  },
  getCalculatedInputValue: (arg0: string) => number,
  marketplaceId: number
) {
  const {
    inputTypes,
    isInputTypeWithParenthesis,
    isInputTypeBuiltIn
  } = useMarginStackInputTypes();

  const { isMarketplaceEbay } = useTabOwners(marketplaceId);

  const getBrandTotal = () => {
    const fulfillmentCost =
      getCalculatedInputValue(inputTypes.BUILT_IN_CONSUMER_PAID_SHIPPING) || 0;
    const listingPrice =
      getCalculatedInputValue(inputTypes.BUILT_IN_LISTING_PRICE) || 0;

    return fulfillmentCost + listingPrice;
  };

  const getFulfillmentTotal = () => {
    const fulfillment =
      getCalculatedInputValue(
        inputTypes.BUILT_IN_PRODUCT_COST_WITH_FULFILLMENT_INCLUDING_FREIGHT
      ) || 0;
    const freightCost =
      getCalculatedInputValue(inputTypes.BUILT_IN_FREIGHT_COST_EST) || 0;
    const productCostWithFulfillment =
      getCalculatedInputValue(
        inputTypes.BUILT_IN_PRODUCT_COST_WITH_FULFILLMENT
      ) || 0;
    return fulfillment + freightCost + productCostWithFulfillment;
  };

  const getMarketplaceTotal = () => {
    let marketplaceMargin =
      getCalculatedInputValue(inputTypes.BUILT_IN_MARKETPLACE_MARGIN) || 0;
    const paymentProcessingFee =
      getCalculatedInputValue(inputTypes.BUILT_IN_PAYMENT_PROCESSING_FEE) || 0;
    const transactionFee =
      getCalculatedInputValue(inputTypes.BUILT_IN_TRANSACTION_FEE) || 0;
    const marketplaceReferralOnShipping = getCalculatedInputValue(
      inputTypes.BUILT_IN_MARKETPLACE_REFERRAL_ON_SHIPPING
    );

    if (isMarketplaceEbay()) {
      marketplaceMargin *= 1;
    }

    return (
      marketplaceMargin +
      paymentProcessingFee +
      transactionFee +
      marketplaceReferralOnShipping
    );
  };

  const getMarketnationTotal = () => {
    const transactionServiceFee =
      getCalculatedInputValue(inputTypes.BUILT_IN_TRANSACTION_SERVICE_FEE) || 0;
    const minimumFee =
      getCalculatedInputValue(inputTypes.BUILT_IN_MINIMUM_FEE) || 0;
    const costOfReturns =
      getCalculatedInputValue(inputTypes.BUILT_IN_COST_OF_RETURNS) || 0;
    return (
      Math.max(transactionServiceFee, minimumFee) +
      costOfReturns +
      getPartnersSharedCalculations(marginStackMarketplace.value?.marketnation)
    );
  };

  const getPartnersSharedCalculations = (partner) => {
    return (
      getCustomInputsSumValue(partner?.inputs) - (partner?.rebate?.value || 0)
    );
  };

  const getCustomInputsSumValue = (inputsList) => {
    return inputsList?.reduce((Sum, input) => {
      if (isInputTypeBuiltIn(input.type)) {
        return Sum;
      }
      if (isInputTypeWithParenthesis(input.type)) {
        return Sum - (getCalculatedInputValue(input.type) || 0);
      }
      return Sum + (getCalculatedInputValue(input.type) || 0);
    }, 0);
  };

  const getTotalOfAllInputs = () => {
    return (
      getBrandTotal() -
      getFulfillmentTotal() -
      getMarketplaceTotal() -
      getMarketnationTotal()
    );
  };

  const getPartnerTotals = () => {
    return {
      brand: getBrandTotal(),
      fulfillment: getFulfillmentTotal(),
      marketplace: getMarketplaceTotal(),
      marketnation: getMarketnationTotal()
    };
  };

  return {
    getPartnerTotals,
    getTotalOfAllInputs
  };
}
