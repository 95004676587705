const helper = {
  extractMarketplacesFromStatusFilter(filters: {
    status: string[];
    productStatus: string;
    marketplaces: number[];
    title: null | string;
  }) {
    let statusType;

    if (!filters) {
      return {};
    }

    if (!filters.status && !filters.productStatus) {
      return filters;
    }

    if (!!filters.status && filters.status.length > 0) {
      statusType = 'status';
    }
    if (!!filters.productStatus && filters.productStatus.length > 0) {
      statusType = 'productStatus';
    }

    if (!statusType) {
      return filters;
    }

    let status: string[] = [];
    let marketplaces: number[] = [];

    if (statusType === 'productStatus') {
      filters.marketplaces = filters[statusType];
      filters[statusType] = null;
      return filters;
    }

    filters[statusType].forEach((item) => {
      if (item.group === statusType) {
        status.push(item.value);
        return;
      }
      marketplaces.push(item.value);
    });

    filters[statusType] = status;
    filters.marketplaces = marketplaces;

    return filters;
  }
};

export default helper;
