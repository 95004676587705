import { cloneDeep } from 'lodash';
import ApiService from '@/services/api.service';
import {
  ICategoryAttributesState,
  ICategoryAttributesGetters,
  ICategoryAttributesActions,
  CategoryAttributesMutationsTypes
} from '@/types/interfaces/store/product/accordions/category-attributes';

import { PD_CONT } from '@/types/constants';
import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { AxiosError, AxiosResponse } from 'axios';
import { IState } from '@/types/interfaces/store';
import {
  CategoryAttributes,
  ICategoryAttributesError
} from '@/types/interfaces/product/accordions/category-attributes';

export const state: ICategoryAttributesState = {
  isEditingCategoryAttributes: false,
  isLoadingCategoryAttributes: false,

  categoryAttributes: null,
  originalCategoryAttributes: null,
  emptyCategoryAttributes: {},
  categoryAttributesErrors: null,

  isCategoryAttributesAccordionVisible: false
};

export const getters: GetterTree<
  ICategoryAttributesState,
  ICategoryAttributesState
> &
  ICategoryAttributesGetters = {
  [PD_CONT.GETTERS.IS_CATEGORY_ATTRIBUTES_ACCORDION_VISIBLE]: (
    state: ICategoryAttributesState
  ): boolean => {
    return state.isCategoryAttributesAccordionVisible;
  }
};

export const actions: ActionTree<IState, IState> &
  ICategoryAttributesActions = {
  [PD_CONT.ACTIONS.GET_CATEGORY_ATTRIBUTES]({ commit }, productId: number) {
    return new Promise((resolve, reject) => {
      commit(PD_CONT.MUTATIONS.SET_IS_LOADING_CATEGORY_ATTRIBUTES, true);

      const url =
        process.env.VUE_APP_CATALOG_SERVICE +
        '/products/' +
        productId +
        '/product-category-attributes';

      ApiService.get(url)
        .then((response: AxiosResponse<CategoryAttributes>) => {
          commit(PD_CONT.MUTATIONS.SET_IS_LOADING_CATEGORY_ATTRIBUTES, false);
          commit(PD_CONT.MUTATIONS.SET_CATEGORY_ATTRIBUTES, response.data);
          commit(
            PD_CONT.MUTATIONS.SET_ORIGINAL_CATEGORY_ATTRIBUTES,
            response.data
          );
          resolve(response.data);
        })
        .catch((error: AxiosError) => {
          commit(PD_CONT.MUTATIONS.SET_IS_LOADING_CATEGORY_ATTRIBUTES, false);
          reject(error);
        });
    });
  }
};

const mutations: MutationTree<IState> & CategoryAttributesMutationsTypes = {
  [PD_CONT.MUTATIONS.SET_IS_EDITING_CATEGORY_ATTRIBUTES](
    state: ICategoryAttributesState,
    value: boolean
  ) {
    state.isEditingCategoryAttributes = value;
  },

  [PD_CONT.MUTATIONS.SET_IS_LOADING_CATEGORY_ATTRIBUTES](
    state: ICategoryAttributesState,
    value: boolean
  ) {
    state.isLoadingCategoryAttributes = value;
  },

  [PD_CONT.MUTATIONS.SET_CATEGORY_ATTRIBUTES](
    state: ICategoryAttributesState,
    value: CategoryAttributes
  ) {
    state.categoryAttributes = value;
  },

  [PD_CONT.MUTATIONS.SET_ORIGINAL_CATEGORY_ATTRIBUTES](
    state: ICategoryAttributesState,
    value: CategoryAttributes
  ) {
    state.originalCategoryAttributes = cloneDeep(value);
  },

  [PD_CONT.MUTATIONS.SET_CATEGORY_ATTRIBUTES_ERRORS](
    state: ICategoryAttributesState,
    value: ICategoryAttributesError
  ) {
    state.categoryAttributesErrors = value;
  },

  [PD_CONT.MUTATIONS.SET_IS_CATEGORY_ATTRIBUTES_ACCORDION_VISIBLE](
    state: ICategoryAttributesState,
    value: boolean
  ) {
    state.isCategoryAttributesAccordionVisible = value;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
