import { computed } from 'vue';
import { useStore } from 'vuex';
import { PD_CONT, PD_OFFER, PARTNER } from '@/types/constants';

export function useTabOwners(marketplaceId: number) {
  const store = useStore();

  const brandName = computed(() => {
    const brandId = store.getters['product/' + PD_CONT.GETTERS.GET_BRAND_ID];
    return store.getters['partner/' + PARTNER.GETTERS.GET_BRAND_NAME_BY_ID](
      brandId
    );
  });

  const marketplaceName = computed(() => {
    return store.getters[
      'partner/' + PARTNER.GETTERS.GET_MARKETPLACE_NAME_BY_ID
    ](marketplaceId);
  });

  const fulfillmentPartnerName = computed(() => {
    const fulfillmentPartnerId =
      store.getters[
        'product/offer/' + PD_OFFER.GETTERS.GET_FULFILLMENT_PARTNER_ID
      ];
    return store.getters[
      'partner/' + PARTNER.GETTERS.GET_FULFILLMENT_PARTNER_BY_ID
    ](fulfillmentPartnerId)?.name;
  });

  const isMarketplaceEbay = () => {
    return marketplaceId === 2;
  };

  const tabOwnerOptions = computed(() => [
    {
      value: 'brand',
      label: brandName.value
    },
    {
      value: 'fulfillment',
      label: fulfillmentPartnerName.value
    },
    {
      value: 'marketplace',
      label: marketplaceName.value
    },
    {
      value: 'marketnation',
      label: 'MarketNation'
    }
  ]);

  const tabOwners = computed(() => {
    return {
      general: 'General',
      brand: brandName.value,
      fulfillment: fulfillmentPartnerName.value,
      marketplace: marketplaceName.value,
      marketnation: 'MarketNation'
    };
  });

  return {
    tabOwners,
    tabOwnerOptions,
    isMarketplaceEbay
  };
}
