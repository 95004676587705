import { ref, nextTick, Ref } from 'vue';
import { useStore } from 'vuex';
import { MARGIN_STACK, PD_OFFER, PRODUCT } from '@/types/constants';
import { useRouter } from 'vue-router';


export function useSavedNotPublishedModal(
  activeHorizontalTab?: Ref<string | undefined>,
  horizontalTabCodes?: {
    PRODUCT_CONTENT: 'product-content';
    SKU_DETAILS: 'sku-details';
    MARGIN_STACK: 'margin-stack';
  },
  setActiveHorizontalTab?: { (tabCode: string): any; (arg: string | null): any }
) {
  const store = useStore();
  const router = useRouter();

  let tabToNavigateAfterSavedNotPublished: string | null = null;
  const isSavedNotPublishedModalOpen = ref<boolean>(false);

  const showSavedNotPublishedModal = (tabCode?: string) => {
    if (store.getters['product/offer/' + PD_OFFER.GETTERS.SAVED_DATA_NOT_PUBLISHED]) {
      isSavedNotPublishedModalOpen.value = true;
    }
    else {
      isSavedNotPublishedModalOpen.value = false;

    }
    if (tabCode) {
      tabToNavigateAfterSavedNotPublished = tabCode;
    }

  };

  const continueToOtherTab = () => {
    if (setActiveHorizontalTab && tabToNavigateAfterSavedNotPublished) {
      store.commit('product/offer/' + PD_OFFER.MUTATIONS.CLEAR_SAVED_NOT_PUBLISHED);
      setTimeout(() => setActiveHorizontalTab(tabToNavigateAfterSavedNotPublished), 100);
    } else {
      isSavedNotPublishedModalOpen.value = false;
      router.push({ name: 'products' })
    }


  };

  return {
    isSavedNotPublishedModalOpen,
    showSavedNotPublishedModal,
    continueToOtherTab
  };
}
