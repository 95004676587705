<template src="./template.pug" lang="pug"></template>
<script>
import InlineSvg from 'vue-inline-svg';

export default {
  name: 'MarginStackApprovals',
  components: { InlineSvg }
};
</script>

<script setup>
import { ref, defineProps, defineEmits, inject } from 'vue';
import { useEditPermissions } from '@/composables/views/products/product-details/margin-stack/marketplace/editPermissions';
import { useStore } from 'vuex';
import { useApproveStatuses } from '@/composables/views/products/product-details/margin-stack/marketplace/margin-stack-inputs/inputs-tab/approvals/approveStatuses';
import { MARGIN_STACK } from '../../../../../../../../types/constants';

const props = defineProps({
  approvalStatus: {
    type: String,
    default: ''
  },
  areAllSectionsApproved: {
    type: Boolean,
    default: false
  },
  tabName: {
    type: String,
    default: ''
  }
});
const emits = defineEmits(['update:approvalStatus']);

const { canEditTab } = useEditPermissions();
const { approveStatuses } = useApproveStatuses();
const store = useStore();
const isApprovingSection = ref(false);
const storefrontId = inject('storefrontId');

const isPendingApproval = () => {
  return props.approvalStatus === approveStatuses.PENDING;
};

const isApproved = () => {
  return props.approvalStatus === approveStatuses.APPROVED;
};

const isUserAllowedToApprove = () => {
  return canEditTab(props.tabName);
};

const approveSection = async () => {
  isApprovingSection.value = true;
  await store
    .dispatch('product/marginStack/' + MARGIN_STACK.ACTIONS.APPROVE_SECTION, {
      storefrontId,
      sectionType: props.tabName.toUpperCase()
    })
    .then(() => {
      emits('update:approvalStatus', approveStatuses.APPROVED);
    })
    .finally(() => {
      isApprovingSection.value = false;
    });
};
</script>
