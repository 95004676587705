import ApiService from '@/services/api.service';
import helper from '../helper';
import gridQuery from '../../../utility/gridQuery';
import {
  GridUploadStatusMutationsTypes,
  IGridUploadStatusActions,
  IGridUploadStatusGetters,
  IGridUploadStatusState
} from '@/types/interfaces/store/product/grids/upload-status';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { GRID_UPLOAD_STATUS } from '@/types/constants';
import { IState } from '@/types/interfaces/store';

export const state: IGridUploadStatusState = {
  uploadStatusGridItems: {},
  loadingUploadStatusGrid: false
};

export const getters: GetterTree<
  IGridUploadStatusState,
  IGridUploadStatusState
> &
  IGridUploadStatusGetters = {
  [GRID_UPLOAD_STATUS.GETTERS.GET_UPLOAD_STATUS_GRID_ITEMS]: (state) =>
    state.uploadStatusGridItems,
  [GRID_UPLOAD_STATUS.GETTERS.IS_LOADING_UPLOAD_STATUS_GRID]: (state) =>
    state.loadingUploadStatusGrid
};

export const actions: ActionTree<IState, IState> & IGridUploadStatusActions = {
  [GRID_UPLOAD_STATUS.ACTIONS.GET_UPLOAD_STATUS_GRID]({ commit }, filters) {
    return new Promise((resolve, reject) => {
      commit(GRID_UPLOAD_STATUS.MUTATIONS.GET_UPLOAD_STATUS_GRID_REQUEST);

      const url =
        process.env.VUE_APP_CATALOG_SERVICE +
        '/products/import/grid' +
        gridQuery.getGridQuery(filters);

      ApiService.get(url)
        .then((response) => {
          commit(
            GRID_UPLOAD_STATUS.MUTATIONS.SET_UPLOAD_STATUS_GRID_SUCCESS,
            response.data
          );

          resolve(response);
        })
        .catch((response) => {
          reject(response);
        });
    });
  },

  [GRID_UPLOAD_STATUS.ACTIONS.DOWNLOAD_UPLOAD_STATUS_REPORT](
    { commit },
    { type, id }
  ) {
    return new Promise((resolve, reject) => {
      commit(GRID_UPLOAD_STATUS.MUTATIONS.DOWNLOAD_UPLOAD_STATUS_REQUEST);
      const url =
        process.env.VUE_APP_CATALOG_SERVICE +
        '/products/import/download/' +
        type +
        '/' +
        id;

      ApiService.get(url)
        .then((response) => {
          resolve(response);
        })
        .catch((response) => {
          reject(response);
        });
    });
  }
};

const mutations: MutationTree<IState> & GridUploadStatusMutationsTypes = {
  [GRID_UPLOAD_STATUS.MUTATIONS.GET_UPLOAD_STATUS_GRID_REQUEST](state) {
    state.loadingUploadStatusGrid = true;
  },
  [GRID_UPLOAD_STATUS.MUTATIONS.SET_UPLOAD_STATUS_GRID_SUCCESS](
    state,
    products
  ) {
    state.uploadStatusGridItems = null;
    state.loadingUploadStatusGrid = false;
    state.uploadStatusGridItems = products;
  },

  [GRID_UPLOAD_STATUS.MUTATIONS.DOWNLOAD_UPLOAD_STATUS_REQUEST]() {}
};

export default {
  state,
  getters,
  actions,
  mutations
};
