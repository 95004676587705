<template src="./template.pug" lang="pug"></template>
<script>
export default {
  name: 'MarginStackProposalsTab'
};
</script>

<script setup>
import { defineProps } from 'vue';
import { isNil } from 'lodash';
import { useFormatters } from '@/composables/formatters';
import { useMarginStackInputTypes } from '@/composables/views/products/product-details/margin-stack/marketplace/margin-stack-inputs/types';
import { useSelected } from '@/composables/views/products/product-details/margin-stack/marketplace/proposals/proposals-tab/selected';

const { getCurrencyFormat } = useFormatters();
const { isInputTypeWithParenthesis } = useMarginStackInputTypes();

const props = defineProps({
  proposals: {
    type: Array,
    required: true
  },
  title: {
    type: String,
    default: ''
  }
});

const {
  selected,
  isClearingProposals,
  isSelectedEmpty,
  clearSelected
} = useSelected();

const getProposalDisplayedValue = (proposal) => {
  if (proposal.dateValue) {
    return proposal.dateValue;
  }

  let value = proposal.calculatedValue || proposal.doubleValue;

  if (isNil(value)) {
    return '';
  }

  value = getCurrencyFormat(value);

  if (isInputTypeWithParenthesis(proposal.type)) {
    value = '(' + value + ')';
  }

  return value;
};

const isProposalEmpty = () => {
  return props.proposals?.length === 0;
};
</script>
