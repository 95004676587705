export enum OrderGetters {
  GET_ORDERS_GRID_ITEMS = 'GET_ORDERS_GRID_ITEMS',
  LOADING_ORDERS_GRID = 'LOADING_ORDERS_GRID',
  GET_ORDER = 'GET_ORDER',
  LOADING_ORDER = 'LOADING_ORDER',
  UPDATING_ORDER = 'UPDATING_ORDER',
  SUBMITTING_NOTE = 'SUBMITTING_NOTE',
  GET_NEW_NOTE = 'GET_NEW_NOTE',
  LOADING_NOTES_AND_ATTACHMENTS = 'LOADING_NOTES_AND_ATTACHMENTS',
  GET_NOTES_AND_ATTACHMENTS = 'GET_NOTES_AND_ATTACHMENTS',
  RESENDING_ORDER_TO_PARTNER = 'RESENDING_ORDER_TO_PARTNER',
  RESENDING_ORDER_TO_MARKETPLACE = 'RESENDING_ORDER_TO_MARKETPLACE'
}
export enum OrderMutations {
  GET_ORDERS_GRID_REQUEST = 'GET_ORDERS_GRID_REQUEST',
  GET_ORDERS_GRID_SUCCESS = 'GET_ORDERS_GRID_SUCCESS',
  GET_ORDER_REQUEST = 'GET_ORDER_REQUEST',
  GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS',
  CREATE_NOTE_REQUEST = 'CREATE_NOTE_REQUEST',
  CREATE_NOTE_SUCCESS = 'CREATE_NOTE_SUCCESS',
  CREATE_ATTACHMENT_REQUEST = 'CREATE_ATTACHMENT_REQUEST',
  CREATE_ATTACHMENT_SUCCESS = 'CREATE_ATTACHMENT_SUCCESS',
  SET_NEW_NOTE = 'SET_NEW_NOTE',
  GET_NOTES_REQUEST = 'GET_NOTES_REQUEST',
  GET_NOTES_SUCCESS = 'GET_NOTES_SUCCESS',
  GET_ATTACHMENTS_REQUEST = 'GET_ATTACHMENT_REQUEST',
  GET_ATTACHMENTS_SUCCESS = 'GET_ATTACHMENTS_SUCCESS',
  SET_ORDER_ITEMS = 'SET_ORDER_ITEMS',
  UPDATE_ORDER_ITEMS_REQUEST = 'UPDATE_ORDER_ITEMS_REQUEST',
  UPDATE_ORDER_ITEMS_SUCCESS = 'UPDATE_ORDER_ITEMS_SUCCESS',
  UPDATE_ORDER_ITEMS_ERROR = 'UPDATE_ORDER_ITEMS_ERROR',
  DOWNLOAD_ATTACHMENT_REQUEST = 'DOWNLOAD_ATTACHMENT_REQUEST',
  RESENDING_ORDER_TO_PARTNER_REQUEST = 'RESENDING_ORDER_TO_PARTNER_REQUEST',
  RESENDING_ORDER_TO_PARTNER_SUCCESS = 'RESENDING_ORDER_TO_PARTNER_SUCCESS',
  RESENDING_ORDER_TO_MARKETPLACE_REQUEST = 'RESENDING_ORDER_TO_MARKETPLACE_REQUEST',
  RESENDING_ORDER_TO_MARKETPLACE_SUCCESS = 'RESENDING_ORDER_TO_MARKETPLACE_SUCCESS'
}
export enum OrderActions {
  GET_ORDERS_GRID = 'GET_ORDERS_GRID',
  GET_ORDER = 'GET_ORDER',
  UPDATE_ORDER_ITEMS = 'UPDATE_ORDER_ITEMS',
  CREATE_NOTE = 'CREATE_NOTE',
  GET_NOTES = 'GET_NOTES',
  CREATE_ATTACHMENT = 'CREATE_ATTACHMENT',
  GET_ATTACHMENTS = 'GET_ATTACHMENTS',
  DOWNLOAD_ATTACHMENT = 'DOWNLOAD_ATTACHMENT',
  RESUBMIT_ORDER_TO_PARTNER = 'RESUBMIT_ORDER_TO_PARTNER',
  RESUBMIT_ORDER_TO_MARKETPLACE = 'RESUBMIT_ORDER_TO_MARKETPLACE'
}

export enum OrderStateType {
  NOT_SEND = 'NOT_SEND',
  FAILED_SEND_SHIPMENT_TO_MARKETPLACE = 'FAILED_SEND_SHIPMENT_TO_MARKETPLACE',
  SENT_SHIPMENT = 'SENT_SHIPMENT',
  SENDING_SHIPMENT = 'SENDING_SHIPMENT'
}

export enum OrderStatusType {
  CREATED = 'CREATED',
  PARTIALLY_SHIPPED = 'PARTIALLY_SHIPPED',
  PARTIALLY_DELIVERED = 'PARTIALLY_DELIVERED',
  PARTIALLY_CANCELLED = 'PARTIALLY_CANCELLED',
  CANCELLED = 'CANCELLED',
  SHIPPED = 'SHIPPED',
  DELIVERED = 'DELIVERED',
  COMPLETE = 'COMPLETE',
  ERROR = 'ERROR',
  ACKNOWLEDGED = 'ACKNOWLEDGED',
  PARTIALLY_CREATED = 'PARTIALLY_CREATED',
  SUBMITTED = 'SUBMITTED',
  SUBMISSION_ERROR = 'SUBMISSION_ERROR'
}

export enum OrderItemStatusType {
  NEW = 'New',
  SHIPPED = 'Shipped',
  DELIVERED = 'Delivered',
  RETURN = 'Return',
  CANCELLED = 'Cancelled'
}

export enum MarketplaceNameType {
  WALMART = 'WALMART',
  PLUS_SHOP = 'PLUS_SHOP',
  AMAZON = 'AMAZON',
  E_BAY = 'E_BAY',
  GOOGLE_SHOPPING = 'GOOGLE_SHOPPING'
}
