import axios from 'axios';
import store from '@/store';
import StorageService from '@/services/storage.service';
import { USER } from '../types/constants';

const ApiService = {
  _401interceptor: null,

  init(baseUrl) {
    axios.defaults.baseURL = baseUrl;
  },

  setHeader() {
    if (store.state.user.token) {
      axios.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${store.state.user.token}`;
    }
    axios.defaults.headers.common['Content-Type'] = 'application/json';
  },

  removeHeader() {
    axios.defaults.headers.common = {};
  },

  get(resource) {
    return axios.get(resource);
  },

  post(resource, data, config) {
    return axios.post(resource, data, config);
  },

  put(resource, data, config) {
    return axios.put(resource, data, config);
  },

  patch(resource, data) {
    return axios.patch(resource, data);
  },

  delete(resource) {
    return axios.delete(resource);
  },

  /**
   * Perform a custom Axios request.
   *
   * data is an object containing the following properties:
   *  - method
   *  - url
   *  - data ... request payload
   *  - auth (optional)
   *    - username
   *    - password
   */
  customRequest(data) {
    return axios(data);
  },

  mount401Interceptor() {
    this._401interceptor = axios.interceptors.response.use(
      undefined,
      async (err) => {
        return new Promise((resolve, reject) => {
          if (!err.response) {
            reject(err);
            return;
          }

          const errorResponse = err.response;
          if (errorResponse.status !== 401) {
            reject(err);
            return;
          }

          let refreshToken = StorageService.getItem('refreshToken');
          if (!refreshToken) {
            store.dispatch('user/' + USER.ACTIONS.LOGOUT);
            reject(err);
          } else {
            const config = errorResponse.config;
            if (config && !config.url.includes('/refreshToken')) {
              store
                .dispatch('user/' + USER.ACTIONS.REFRESH_TOKEN)
                .then(() => {
                  this.setHeader();

                  delete config.headers.Authorization;

                  // Parsing and stringifying headers as workaround to axios 1.1.3 bug which mutates headers
                  return resolve(
                    this.customRequest({
                      ...config,
                      headers: JSON.parse(JSON.stringify(config.headers))
                    })
                  );
                })
                .catch(() => {
                  store.dispatch('user/' + USER.ACTIONS.LOGOUT);
                  reject(err);
                });
            }
          }
        });
      }
    );
  },

  unmount401Interceptor() {
    // Eject the interceptor
    axios.interceptors.response.eject(this._401interceptor);
  }
};

export default ApiService;
