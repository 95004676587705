import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "px-6 pt-8 pb-px" }

export function render(_ctx, _cache) {
  const _component_t_image_uploader_per_marketplace = _resolveComponent("t-image-uploader-per-marketplace")
  const _component_t_collapsible_card = _resolveComponent("t-collapsible-card")

  return (_openBlock(), _createBlock(_component_t_collapsible_card, {
    "trigger-label": "Media",
    open: true
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_t_image_uploader_per_marketplace, {
          modelValue: _ctx.images,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.images) = $event)),
          id: "product_images"
        }, null, 8, ["modelValue"])
      ])
    ]),
    _: 1
  }))
}