import { SplitFactory } from '@splitsoftware/splitio';
import { mapGetters } from 'vuex';
import { EventEmitter } from 'events';
import { TREATMENT } from './types/constants';
const SPLIT_AUTH_KEY = process.env.VUE_APP_SPLIT_IO_AUTHORIZATION_KEY;

const mixin = {
  data() {
    return {
      splitFactory: null,
      userClient: null,
      accountClient: null,
      anonymousClient: null,
      userSplitsList: ['exposing-changes-to-users', 'product-compliance'],
      accountSplitsList: [],
      anonymousSplitsList: []
    };
  },

  computed: {
    ...mapGetters('user', ['IS_LOGGED_IN'])
  },

  watch: {
    IS_LOGGED_IN() {
      this.setSplitClients();
    }
  },

  created() {
    // this.setSplitClients();
  },

  beforeUnmount() {
    // this.destroySplitClients();
  },

  methods: {
    setSplitClients() {
      this.clearTreatments();
      this.setSplitFactory();

      const userId = this.$store.state.user.id;
      const accountId = this.$store.state.user.accountId;

      if (userId) {
        this.setUserClient(userId);
      }
      if (userId && accountId) {
        this.setAccountClient(accountId);
      }
      this.setAnonymousClient();
    },

    destroySplitClients() {
      this.userClient.destroy();
      this.userClient = null;

      this.accountClient.destroy();
      this.accountClient = null;

      this.anonymousClient.destroy();
      this.anonymousClient = null;
    },

    setSplitFactory() {
      EventEmitter.defaultMaxListeners = 13;
      this.splitFactory = SplitFactory({
        core: {
          authorizationKey: SPLIT_AUTH_KEY,
          key: 'default'
        }
      });
    },

    setUserClient(userId) {
      this.userClient = this.splitFactory.client(userId.toString(), 'user');

      this.userClient.on(this.userClient.Event.SDK_READY, () => {
        this.setTreatments('User', this.userClient, this.userSplitsList);
      });

      this.userClient.on(this.userClient.Event.SDK_UPDATE, () => {
        this.setTreatments('User', this.userClient, this.userSplitsList);
      });
    },

    setAccountClient(accountId) {
      this.accountClient = this.splitFactory.client(
        accountId.toString(),
        'account'
      );

      this.accountClient.on(this.accountClient.Event.SDK_READY, () => {
        this.setTreatments(
          'Account',
          this.accountClient,
          this.accountSplitsList
        );
      });

      this.accountClient.on(this.accountClient.Event.SDK_UPDATE, () => {
        this.setTreatments(
          'Account',
          this.accountClient,
          this.accountSplitsList
        );
      });
    },

    setAnonymousClient() {
      const randomKey = (Math.random() + 1).toString(36).substring(7);
      this.anonymousClient = this.splitFactory.client(randomKey, 'anonymous');

      this.anonymousClient.on(this.anonymousClient.Event.SDK_READY, () => {
        this.setTreatments(
          'Anonymous',
          this.anonymousClient,
          this.anonymousSplitsList
        );
      });

      this.anonymousClient.on(this.anonymousClient.Event.SDK_UPDATE, () => {
        this.setTreatments(
          'Anonymous',
          this.anonymousClient,
          this.anonymousSplitsList
        );
      });
    },

    clearTreatments() {
      this.$store.commit(
        'treatment/' + TREATMENT.MUTATIONS.SET_USER_TREATMENTS,
        {}
      );
      this.$store.commit(
        'treatment/' + TREATMENT.MUTATIONS.SET_ACCOUNT_TREATMENTS,
        {}
      );
      this.$store.commit(
        'treatment/' + TREATMENT.MUTATIONS.SET_ANONYMOUS_TREATMENTS,
        {}
      );
    },

    setTreatments(trafficType, client, splitList) {
      const treatments = client.getTreatments(splitList);
      this.$store.commit(
        'treatment/SET_' + trafficType.toUpperCase() + '_TREATMENTS',
        treatments
      );
    }
  }
};

export default mixin;
