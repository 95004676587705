import { cloneDeep } from 'lodash';
import ApiService from '@/services/api.service';
import {
  IOfferSpecificContentState,
  IOfferSpecificContentActions,
  IOfferSpecificContentGetters,
  OfferSpecificContentMutationsTypes
} from '@/types/interfaces/store/product/offer/accordions/offer-specific-content';
import { PD_OFFER } from '@/types/constants';
import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { AxiosError, AxiosResponse } from 'axios';
import {
  IOfferSpecificContentError,
  IOfferSpecificContent
} from '@/types/interfaces/product/offer/accordions/offer-specific-content';
import { IState } from '@/types/interfaces/store';
import { IOfferState } from '@/types/interfaces/store/product/offer';

export const state: IOfferSpecificContentState = {
  isEditingOfferSpecificContent: false,
  isLoadingOfferSpecificContent: false,

  offerSpecificContent: null,
  originalOfferSpecificContent: null,
  emptyOfferSpecificContent: {
    shortDescription: null,
    longDescription: null
  },
  offerSpecificContentErrors: null
};

export const getters: GetterTree<
  IOfferSpecificContentState,
  IOfferSpecificContentState
> &
  IOfferSpecificContentGetters = {};

export const actions: ActionTree<IOfferState, IState> &
  IOfferSpecificContentActions = {
  [PD_OFFER.ACTIONS.GET_OFFER_SPECIFIC_CONTENT](
    { commit },
    { productId, offerId }: { productId: number; offerId: number }
  ) {
    return new Promise((resolve, reject) => {
      commit(PD_OFFER.MUTATIONS.SET_IS_LOADING_OFFER_SPECIFIC_CONTENT, true);

      const url =
        process.env.VUE_APP_CATALOG_SERVICE +
        '/products/' +
        productId +
        '/offers/' +
        offerId +
        '/specific-content';

      ApiService.get(url)
        .then((response: AxiosResponse<IOfferSpecificContent>) => {
          commit(
            PD_OFFER.MUTATIONS.SET_IS_LOADING_OFFER_SPECIFIC_CONTENT,
            false
          );
          commit(PD_OFFER.MUTATIONS.SET_OFFER_SPECIFIC_CONTENT, response.data);
          commit(
            PD_OFFER.MUTATIONS.SET_ORIGINAL_OFFER_SPECIFIC_CONTENT,
            response.data
          );
          resolve(response.data);
        })
        .catch((error: AxiosError) => {
          commit(
            PD_OFFER.MUTATIONS.SET_IS_LOADING_OFFER_SPECIFIC_CONTENT,
            false
          );
          reject(error);
        });
    });
  }
};

const mutations: MutationTree<IOfferState> &
  OfferSpecificContentMutationsTypes = {
  [PD_OFFER.MUTATIONS.SET_IS_EDITING_OFFER_SPECIFIC_CONTENT](
    state: IOfferSpecificContentState,
    value: boolean
  ) {
    state.isEditingOfferSpecificContent = value;
  },

  [PD_OFFER.MUTATIONS.SET_IS_LOADING_OFFER_SPECIFIC_CONTENT](
    state: IOfferSpecificContentState,
    value: boolean
  ) {
    state.isLoadingOfferSpecificContent = value;
  },

  [PD_OFFER.MUTATIONS.SET_OFFER_SPECIFIC_CONTENT](
    state: IOfferSpecificContentState,
    value: IOfferSpecificContent
  ) {
    state.offerSpecificContent = value;
  },

  [PD_OFFER.MUTATIONS.SET_ORIGINAL_OFFER_SPECIFIC_CONTENT](
    state: IOfferSpecificContentState,
    value: IOfferSpecificContent
  ) {
    state.originalOfferSpecificContent = cloneDeep(value);
  },

  [PD_OFFER.MUTATIONS.SET_OFFER_SPECIFIC_CONTENT_ERRORS](
    state: IOfferSpecificContentState,
    value: IOfferSpecificContentError
  ) {
    state.offerSpecificContentErrors = value;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
