<template src="./template.pug" lang="pug"></template>

<script>
import { Form } from 'vee-validate';
import InlineSvg from 'vue-inline-svg';
import { USER } from '../../types/constants';

export default {
  name: 'ForgotPassword',
  components: {
    Form,
    InlineSvg
  },
  data() {
    return {
      appName: process.env.VUE_APP_NAME,
      currentYear: new Date().getFullYear(),
      email: '',
      isSubmitting: false,
      sendEmailError: false,
      expiredTokenError: false,
      isBannerOpen: false,
      bannerMessage: '',
      bannerTitle: ''
    };
  },

  created() {
    this.setExpiredTokenError();
  },

  methods: {
    setExpiredTokenError() {
      if (!this.$route.params) {
        return false;
      }
      if (this.$route.params.expiredTokenError) {
        this.expiredTokenError = this.$route.params.expiredTokenError;
        this.showBanner();
      }
    },

    async onSubmit() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        return false;
      }
      this.sendResetPasswordEmail();
    },

    sendResetPasswordEmail() {
      this.isSubmitting = true;
      this.$store
        .dispatch('user/' + USER.ACTIONS.FORGOT_PASSWORD, {
          email: this.email
        })
        .then((result) => {
          this.isSubmitting = false;
          if (result.success) {
            this.$router.push({
              name: 'login',
              params: { passwordBannerState: 'send_email_success' }
            });
          } else {
            this.handleError();
          }
        })
        .catch(() => this.handleError());
    },

    handleError() {
      this.isSubmitting = false;
      this.sendEmailError = true;
      this.showBanner();
    },

    getBannerTitle() {
      if (this.sendEmailError) {
        return 'Unable to send link';
      }
      if (this.expiredTokenError) {
        return 'Link expired';
      }
      return '';
    },

    getBannerMessage() {
      if (this.sendEmailError) {
        return 'The email address you provided cannot be found in our system. Try again or contact your account manager.';
      }
      if (this.expiredTokenError) {
        return 'Enter your email below to send a new link to reset your password.';
      }
      return '';
    },

    showBanner() {
      this.bannerMessage = this.getBannerMessage();
      this.bannerTitle = this.getBannerTitle();
      this.$nextTick(() => {
        this.isBannerOpen = true;
      });
    }
  }
};
</script>
<style scoped lang="scss">
.logo-container {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}
img {
  width: 380px;
  padding-left: 20px;
  text-align: center;
}
</style>
