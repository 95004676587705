export enum GridProductGetters {
  GET_GRID_PRODUCTS = 'GET_GRID_PRODUCTS',
  GET_GRID_AN_PRODUCTS = 'GET_GRID_AN_PRODUCTS',
  IS_LOADING_PRODUCTS = 'IS_LOADING_PRODUCTS'
}
export enum GridProductMutations {
  GET_PRODUCT_GRID_ERROR = 'GET_PRODUCT_GRID_ERROR',
  GET_PRODUCTS_GRID_REQUEST = 'GET_PRODUCTS_GRID_REQUEST',
  SET_PRODUCTS_GRID_SUCCESS = 'SET_PRODUCTS_GRID_SUCCESS',
  GET_AN_PRODUCTS_GRID_REQUEST = 'GET_AN_PRODUCTS_GRID_REQUEST',
  SET_AN_PRODUCTS_GRID_SUCCESS = 'SET_AN_PRODUCTS_GRID_SUCCESS'
}
export enum GridProductActions {
  GET_PRODUCTS_GRID = 'GET_PRODUCTS_GRID',
  GET_AN_PRODUCTS_GRID = 'GET_AN_PRODUCTS_GRID'
}
