<template src="./template.pug" lang="pug"></template>
<script>
import ProposalTab from './proposals-tab';
export default {
  name: 'MarginStackProposals',
  components: { ProposalTab }
};
</script>

<script setup>
import { inject, defineProps } from 'vue';
import { useTabOwners } from '@/composables/views/products/product-details/margin-stack/marketplace/tabOwners';
import { useEditPermissions } from '@/composables/views/products/product-details/margin-stack/marketplace/editPermissions';

const props = defineProps({
  proposals: {
    type: Object,
    required: true
  }
});

const marketplaceId = inject('marketplaceId');
const { tabOwners } = useTabOwners(marketplaceId);
const { canEditTab } = useEditPermissions();
const isEveryProposalEmpty = () => {
  return Object.values(props.proposals).every((proposal) => {
    return proposal.length === 0;
  });
};
</script>
