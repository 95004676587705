export enum PartnerGetters {
  GET_MARKETPLACES = 'GET_MARKETPLACES',
  GET_MARKETPLACE_BY_ID = 'GET_MARKETPLACE_BY_ID',
  GET_MARKETPLACE_NAME_BY_ID = 'GET_MARKETPLACE_NAME_BY_ID',
  GET_MARKETPLACE_STOREFRONT_BY_ID = 'GET_MARKETPLACE_STOREFRONT_BY_ID',
  GET_MARKETPLACE_STOREFRONT_BY_STOREFRONT_IDS_LIST = 'GET_MARKETPLACE_STOREFRONT_BY_STOREFRONT_IDS_LIST',
  GET_FULFILLMENT_PARTNERS = 'GET_FULFILLMENT_PARTNERS',
  GET_FULFILLMENT_PARTNER_BY_NAME = 'GET_FULFILLMENT_PARTNER_BY_NAME',
  GET_FULFILLMENT_PARTNER_BY_ID = 'GET_FULFILLMENT_PARTNER_BY_ID',
  GET_BRAND_PARTNERS = 'GET_BRAND_PARTNERS',
  GET_BRAND_BY_ID = 'GET_BRAND_BY_ID',
  GET_BRAND_NAME_BY_ID = 'GET_BRAND_NAME_BY_ID',
  GET_FULFILLMENT_AND_BRAND_PARTNERS = 'GET_FULFILLMENT_AND_BRAND_PARTNERS',
  GET_MARKETPLACE_STOREFRONTS_OPTIONS = 'GET_MARKETPLACE_STOREFRONTS_OPTIONS',
  GET_MARKETPLACE_OPTIONS = 'GET_MARKETPLACE_OPTIONS',
  GET_BRAND_OPTIONS = 'GET_BRAND_OPTIONS',
  GET_FULFILLMENT_PARTNER_OPTIONS = 'GET_FULFILLMENT_PARTNER_OPTIONS'
}
export enum PartnerMutations {
  GET_MARKETPLACES_REQUEST = 'GET_MARKETPLACES_REQUEST',
  GET_MARKETPLACES_SUCCESS = 'GET_MARKETPLACES_SUCCESS',
  SET_MARKETPLACES_STOREFRONTS = 'SET_MARKETPLACES_STOREFRONTS',
  GET_PARTNERS_REQUEST = 'GET_PARTNERS_REQUEST',
  GET_PARTNERS_SUCCESS = 'GET_PARTNERS_SUCCESS'
}
export enum PartnerActions {
  GET_PARTNERS_FROM_BACKEND = 'GET_PARTNERS_FROM_BACKEND',
  GET_MARKETPLACES_FROM_BACKEND = 'GET_MARKETPLACES_FROM_BACKEND',
  GET_ALL_PARTNERS_FROM_BACKEND = 'GET_ALL_PARTNERS_FROM_BACKEND'
}

export enum PartnerType {
  DISTRIBUTION_PARTNER = 'DISTRIBUTION_PARTNER',
  BRAND = 'BRAND',
  MARKETPLACE = 'MARKETPLACE',
  MARKETNATION = 'MARKETNATION'
}

export enum OwnerType {
  FULFILLMENT = 'fulfillment',
  BRAND = 'brand',
  MARKETPLACE = 'marketplace',
  MARKETNATION = 'marketnation'
}

export enum StorefrontType {
  BRAND_CERTIFIED = 'Brand Certified',
  BUNDLE_MORE = 'BundleMore',
  SHARK_NINJA = 'SharkNinja',
  DIGITAL_DIRECT = 'Digital Direct',
  CUSTOMER_NATION = 'CustomerNation'
}
