import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "p-6 pb-2" }
const _hoisted_2 = { class: "grid grid-cols-12 gap-6 items-end" }
const _hoisted_3 = { class: "col-span-7" }
const _hoisted_4 = { class: "col-span-5" }
const _hoisted_5 = { class: "grid grid-cols-12 gap-6 items-end" }
const _hoisted_6 = { class: "col-span-7" }
const _hoisted_7 = { class: "col-span-5" }

export function render(_ctx, _cache) {
  const _component_t_input_masked = _resolveComponent("t-input-masked")
  const _component_t_select = _resolveComponent("t-select")
  const _component_t_input_decimal = _resolveComponent("t-input-decimal")
  const _component_t_collapsible_card = _resolveComponent("t-collapsible-card")

  return (_ctx.dimensions.wrapper)
    ? (_openBlock(), _createBlock(_component_t_collapsible_card, {
        key: 0,
        "trigger-label": "Dimensions & weight",
        open: true
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_t_input_masked, {
                  id: "dimensions",
                  name: "dimensions",
                  label: "Shipping Dimensions",
                  modelValue: [_ctx.dimensions.wrapper.shippingLength, _ctx.dimensions.wrapper.shippingWidth, _ctx.dimensions.wrapper.shippingHeight],
                  "onUpdate:modelValue": _ctx.setOfferDimensions,
                  placeholder: ['L', 'W', 'H']
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_t_select, {
                  id: "dimensions_unit",
                  modelValue: _ctx.dimensions.wrapper.shippingDimensionsUnit,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.dimensions.wrapper.shippingDimensionsUnit) = $event)),
                  name: "dimensions_unit",
                  options: _ctx.dimensionsUnitOptions
                }, null, 8, ["modelValue", "options"])
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_t_input_decimal, {
                  id: "weight",
                  name: "weight",
                  label: "Shipping Weight",
                  modelValue: _ctx.dimensions.wrapper.shippingWeight,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.dimensions.wrapper.shippingWeight) = $event)),
                  placeholder: "0.00"
                }, null, 8, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_t_select, {
                  id: "weight_unit",
                  name: "weight_unit",
                  modelValue: _ctx.dimensions.wrapper.shippingWeightUnit,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.dimensions.wrapper.shippingWeightUnit) = $event)),
                  options: _ctx.weightUnitOptions
                }, null, 8, ["modelValue", "options"])
              ])
            ])
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}