export function useNetProceeds(
  getListingPrice: () => number,
  getTotalOfAllInputs: () => number
) {
  const getNetProceeds = () => {
    const netProceeds = getTotalOfAllInputs();
    return {
      value: netProceeds,
      color: getNetProceedsColor(netProceeds)
    };
  };

  const getNetProceedsColor = (netProceeds: number) => {
    if (netProceeds >= 0) {
      return 'success';
    }
    return 'error';
  };

  return {
    getNetProceeds
  };
}
