import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-dark-knight font-bold mb-5" }
const _hoisted_2 = { class: "relative" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = { class: "mr-8 w-full" }
const _hoisted_5 = { class: "w-full" }

export function render(_ctx, _cache) {
  const _component_t_tooltip = _resolveComponent("t-tooltip")
  const _component_t_input_decimal = _resolveComponent("t-input-decimal")
  const _component_t_select = _resolveComponent("t-select")
  const _component_t_input_date = _resolveComponent("t-input-date")
  const _component_t_bullet_card = _resolveComponent("t-bullet-card")

  return (_ctx.promotions.wrapper)
    ? (_openBlock(), _createBlock(_component_t_bullet_card, {
        key: 0,
        modelValue: _ctx.promotions.wrapper,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.promotions.wrapper) = $event)),
        itemObject: _ctx.emptyPromotion(),
        triggerLabel: "Promotions",
        addButtonLabel: "Add another promo",
        open: true
      }, {
        default: _withCtx((props) => [
          _createElementVNode("p", _hoisted_1, "Promotion " + _toDisplayString(props.index + 1), 1),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_t_tooltip, {
              show: _ctx.showRebateTooltip,
              positionY: "top-2",
              content: "This is the total amount that will be funded by the owner of the promo and subtracted from their portion of the margin stack."
            }, null, 8, ["show"]),
            _createVNode(_component_t_input_decimal, {
              id: 'promo-' + props.index + 1 + '-rebate',
              name: 'promo-' + props.index + 1 + '-rebate',
              modelValue: props.item.rebate,
              "onUpdate:modelValue": $event => ((props.item.rebate) = $event),
              label: "Promo rebate",
              placeholder: "$0.00",
              symbol: { prefix: '$' },
              onFocus: _cache[0] || (_cache[0] = $event => (_ctx.showRebateTooltip = true)),
              onBlur: _cache[1] || (_cache[1] = $event => (_ctx.showRebateTooltip = false))
            }, null, 8, ["id", "name", "modelValue", "onUpdate:modelValue"])
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_t_select, {
              id: 'promo-' + props.index + 1 + '-owner',
              name: 'promo-' + props.index + 1 + '-owner',
              modelValue: props.item.owner,
              "onUpdate:modelValue": $event => ((props.item.owner) = $event),
              label: "Owner of promo",
              options: _ctx.ownerOptions,
              tooltipContent: "The promo amount will be supported by the indicated owner."
            }, null, 8, ["id", "name", "modelValue", "onUpdate:modelValue", "options"])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_t_input_date, {
                id: 'promo-' + props.index + 1 + '-start-date',
                name: 'promo-' + props.index + 1 + '-start-date',
                modelValue: props.item.startDate,
                "onUpdate:modelValue": $event => ((props.item.startDate) = $event),
                label: "Promo start date"
              }, null, 8, ["id", "name", "modelValue", "onUpdate:modelValue"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_t_input_date, {
                id: 'promo-' + props.index + 1 + '-end-date',
                name: 'promo-' + props.index + 1 + '-end-date',
                modelValue: props.item.endDate,
                "onUpdate:modelValue": $event => ((props.item.endDate) = $event),
                label: "Promo end date"
              }, null, 8, ["id", "name", "modelValue", "onUpdate:modelValue"])
            ])
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_t_select, {
              id: 'promo-' + props.index + 1 + '-marketplace',
              name: 'promo-' + props.index + 1 + '-marketplace',
              modelValue: props.item.applicableMarketplaceId,
              "onUpdate:modelValue": $event => ((props.item.applicableMarketplaceId) = $event),
              label: "Applicable marketplace",
              options: _ctx.marketplaceOptions
            }, null, 8, ["id", "name", "modelValue", "onUpdate:modelValue", "options"])
          ])
        ]),
        _: 1
      }, 8, ["modelValue", "itemObject"]))
    : _createCommentVNode("", true)
}