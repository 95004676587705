import ApiService from '@/services/api.service';
import {
  GridProductsMutationsTypes,
  IGridProductsActions,
  IGridProductsGetters,
  IGridProductsState
} from '@/types/interfaces/store/product/grids/products';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { GRID_PRODUCTS } from '@/types/constants';
import { IState } from '@/types/interfaces/store';
import helper from '@/store/modules/product/helper';
import gridQuery from '../../../utility/gridQuery';
import { AxiosError, AxiosResponse } from 'axios';
import { IProductsGridItems } from '@/types/interfaces/product/grids/grids';

export const state: IGridProductsState = {
  gridProducts: {},
  gridANProducts: {},
  loadingProducts: false
};

export const getters: GetterTree<IGridProductsState, IGridProductsState> &
  IGridProductsGetters = {
  [GRID_PRODUCTS.GETTERS.GET_GRID_PRODUCTS]: (state) => state.gridProducts,
  [GRID_PRODUCTS.GETTERS.GET_GRID_AN_PRODUCTS]: (state) => state.gridANProducts,
  [GRID_PRODUCTS.GETTERS.IS_LOADING_PRODUCTS]: (state) => state.loadingProducts
};

export const actions: ActionTree<IState, IState> & IGridProductsActions = {
  [GRID_PRODUCTS.ACTIONS.GET_PRODUCTS_GRID]({ commit }, filters) {
    return new Promise((resolve, reject) => {
      commit(GRID_PRODUCTS.MUTATIONS.GET_PRODUCTS_GRID_REQUEST);

      let filtersCopy = JSON.parse(JSON.stringify(filters));
      filtersCopy.filter = helper.extractMarketplacesFromStatusFilter(
        filtersCopy.filter
      );
      const query = gridQuery.getGridQuery(filtersCopy);

      const url =
        process.env.VUE_APP_CATALOG_SERVICE + '/products/grid' + query;

      ApiService.get(url)
        .then((response: AxiosResponse<IProductsGridItems>) => {
          commit(
            GRID_PRODUCTS.MUTATIONS.SET_PRODUCTS_GRID_SUCCESS,
            response.data
          );

          resolve(response);
        })
        .catch((error: AxiosError) => {
          commit(GRID_PRODUCTS.MUTATIONS.GET_PRODUCT_GRID_ERROR, error.message);

          reject(error);
        });
    });
  },

  [GRID_PRODUCTS.ACTIONS.GET_AN_PRODUCTS_GRID]({ commit }, filters) {
    return new Promise((resolve, reject) => {
      commit(GRID_PRODUCTS.MUTATIONS.GET_AN_PRODUCTS_GRID_REQUEST);

      let filtersCopy = JSON.parse(JSON.stringify(filters));
      filtersCopy.filter = helper.extractMarketplacesFromStatusFilter(
        filtersCopy.filter
      );
      const query = gridQuery.getGridQuery(filtersCopy);

      const url =
        process.env.VUE_APP_CATALOG_SERVICE +
        '/products/grid' +
        query +
        '&productStatus=PRODUCT_STATUS_MISSING_INPUTS';

      ApiService.get(url)
        .then((response: AxiosResponse<IProductsGridItems>) => {
          commit(
            GRID_PRODUCTS.MUTATIONS.SET_AN_PRODUCTS_GRID_SUCCESS,
            response.data
          );

          resolve(response);
        })
        .catch((error) => {
          commit(GRID_PRODUCTS.MUTATIONS.GET_PRODUCT_GRID_ERROR, error.message);

          reject(error);
        });
    });
  }
};

const mutations: MutationTree<IState> & GridProductsMutationsTypes = {
  [GRID_PRODUCTS.MUTATIONS.GET_PRODUCT_GRID_ERROR](state) {
    state.loadingProducts = false;
  },

  [GRID_PRODUCTS.MUTATIONS.GET_PRODUCTS_GRID_REQUEST](state) {
    state.loadingProducts = true;
  },

  [GRID_PRODUCTS.MUTATIONS.SET_PRODUCTS_GRID_SUCCESS](state, products) {
    state.gridProducts = null;
    state.loadingProducts = false;
    state.gridProducts = products;
  },

  [GRID_PRODUCTS.MUTATIONS.GET_AN_PRODUCTS_GRID_REQUEST](state) {
    state.loadingProducts = true;
  },

  [GRID_PRODUCTS.MUTATIONS.SET_AN_PRODUCTS_GRID_SUCCESS](state, products) {
    state.gridANProducts = null;
    state.loadingProducts = false;
    state.gridANProducts = products;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
