export enum GridUpdateQueueGetters {
  GET_UPDATE_QUEUE_GRID_ITEMS = 'GET_UPDATE_QUEUE_GRID_ITEMS',
  IS_LOADING_UPDATE_QUEUE_GRID = 'IS_LOADING_UPDATE_QUEUE_GRID'
}
export enum GridUpdateQueueMutations {
  EXPORT_UPDATE_QUEUE_PRODUCTS_REQUEST = 'EXPORT_UPDATE_QUEUE_PRODUCTS_REQUEST',
  UPDATE_UPDATE_QUEUE_PRODUCTS_REQUEST = 'UPDATE_UPDATE_QUEUE_PRODUCTS_REQUEST',
  GET_UPDATE_QUEUE_GRID_REQUEST = 'GET_UPDATE_QUEUE_GRID_REQUEST',
  SET_UPDATE_QUEUE_GRID_SUCCESS = 'SET_UPDATE_QUEUE_GRID_SUCCESS'
}
export enum GridUpdateQueueActions {
  GET_UPDATE_QUEUE_GRID = 'GET_UPDATE_QUEUE_GRID',
  EXPORT_UPDATE_QUEUE_PRODUCTS = 'EXPORT_UPDATE_QUEUE_PRODUCTS',
  UPDATE_UPDATE_QUEUE_PRODUCTS = 'UPDATE_UPDATE_QUEUE_PRODUCTS'
}
