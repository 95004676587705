import { useStore } from 'vuex';
import { USER } from '@/types/constants';

export function useUserRoles() {
  const store = useStore();
  const userRoles = store.getters['user/' + USER.GETTERS.GET_USER_ROLES];

  const ADMIN_ROLE = 'ROLE_ADMIN';
  const USER_ROLE = 'ROLE_USER';

  const hasUserAdminRole = () => {
    return userRoles.some((role) => {
      return role === ADMIN_ROLE;
    });
  };

  const hasUserUserRole = () => {
    return userRoles.some((role) => {
      return role === USER_ROLE;
    });
  };

  return {
    ADMIN_ROLE,
    USER_ROLE,
    hasUserAdminRole,
    hasUserUserRole
  };
}
