import { useOptions } from '@/composables/views/products/product-details/options';

export function useAttributeOptions() {
  const { eavAttributeBooleanOptions } = useOptions();

  const getPreparedAttributeOptions = (
    placeholder: string,
    options,
    type: string
  ) => {
    if (type === 'boolean') {
      return eavAttributeBooleanOptions;
    }

    let preparedOptions = [
      {
        value: '',
        label: placeholder,
        position: 0
      }
    ];

    if (!options || !options.length) {
      return preparedOptions;
    }

    options.forEach((item) => {
      preparedOptions.push({
        value: item.id,
        label: item.name,
        position: item.position
      });
    });

    preparedOptions = sortOptionsByPosition(preparedOptions);

    return preparedOptions;
  };

  const sortOptionsByPosition = (options) => {
    return options.sort((a, b) => {
      if (a.position < b.position) {
        return -1;
      }
      if (a.position > b.position) {
        return 1;
      }
      return 0;
    });
  };

  return {
    getPreparedAttributeOptions
  };
}
