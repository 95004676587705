import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faArrowLeft,
  faBars,
  faCaretDown,
  faCaretRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faInfoCircle,
  faMinusSquare,
  faPaperclip,
  faPlusCircle,
  faPlusSquare,
  faTag,
  faTimes
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faArrowLeft,
  faBars,
  faCaretDown,
  faCaretRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faInfoCircle,
  faMinusSquare,
  faPaperclip,
  faPlusCircle,
  faPlusSquare,
  faTag,
  faTimes
);

export default FontAwesomeIcon;
