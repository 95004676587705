<template src="./template.pug" lang="pug">
</template>
<script>
import MarginStackInputTrashButton from '../trash-button';
export default {
  name: 'MarginStackInput',
  components: { MarginStackInputTrashButton },
  computed: {
    mnMinimumFee() {
      return this.getInputDollarValueByFieldName(
        'MarketNation Minimum Transaction Service Fee'
      );
    }
  }
};
</script>

<script setup>
import { ref, defineProps, defineEmits, inject } from 'vue';
import { useFormatters } from '@/composables/formatters';
import { useValueTypes } from '@/composables/views/products/product-details/margin-stack/marketplace/margin-stack-inputs/valueTypes';

const { getCurrencyFormat } = useFormatters();

const props = defineProps({
  input: {
    type: Object,
    required: true
  },
  tabName: {
    type: String,
    default: ''
  },
  enableDeleteButton: {
    type: Boolean,
    default: true
  },
  textAlign: {
    type: String,
    default: 'left'
  }
});
const emits = defineEmits(['delete']);

const getInputValueWithDecorators = inject('getInputValueWithDecorators');
const getInputDollarValueByFieldName = inject('getInputDollarValueByFieldName');
const isEditing = ref(false);
const isHovered = ref(false);

const {
  isInputDate,
  isInputDecimal,
  isInputPriceTypePercentage
} = useValueTypes();

const getInputComponentName = () => {
  if (isInputDecimal(props.input)) {
    return 't-input-decimal';
  }
  if (isInputDate(props.input)) {
    return 't-input-date';
  }
  return 't-input';
};

const getInputDecimalSymbol = () => {
  if (isInputDate(props.input)) {
    return null;
  }
  if (isInputPriceTypePercentage(props.input)) {
    return { suffix: '%' };
  }
  return { prefix: '$' };
};

const deleteInput = () => {
  emits('delete');
};

const inputRef = ref();
const startEditingInput = () => {
  isEditing.value = true;

  const input = inputRef.value.$el.querySelector('input');
  setTimeout(() => {
    input.focus();
  }, 0);
};

const finishEditingInput = () => {
  isEditing.value = false;
};
</script>
