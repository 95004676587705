import { inject, ref } from 'vue';
import { useStore } from 'vuex';
import { cloneDeep } from 'lodash';
import { MARGIN_STACK, PRODUCT } from '@/types/constants';

export function usePublish(
  marginStackMarketplace: {
    value: { productStatus: string; marketplaceStatus: string };
  },
  isLoading: boolean,
  storefrontId: number,
  marketplaceId: number,
  getMarginStackMarketplace: () => any
) {
  const store = useStore();
  const toastNotification = inject<any>('toastNotification');

  const getProductId = () => {
    return store.state.product.productId;
  };

  const getOfferId = () => {
    return store.state.product.offer.offerId;
  };

  const canPublish = () => {
    return marginStackMarketplace.value?.productStatus === 'PRODUCT_STATUS_READY_FOR_MARKETPLACE';
  };

  const isPublished = () => {
    return marginStackMarketplace.value?.marketplaceStatus === 'published';
  };

  const getPublishButtonLabel = () => {
    // If else rather than switch is prefered
    if (marginStackMarketplace.value?.marketplaceStatus === 'pending_publish') {
      return 'Publishing'
    } else if (marginStackMarketplace.value?.marketplaceStatus === 'pending_unpublish') {
      return 'Unpublishing'
    } else if (marginStackMarketplace.value?.marketplaceStatus === 'publish_failed') {
      return 'Failed To Publish - Contact Admin'
    } else if (marginStackMarketplace.value?.marketplaceStatus === 'unpublish_failed') {
      return 'Failed To Unpublish - Contact Admin'
    }

    // Default to simple behavior
    return !isPublished() ? 'Publish' : 'Unpublish';
  };

  const publishedMarginStackMarketplace = ref();
  const isPublishing = ref<boolean>();

  const getPublishedMarginStackMarketplace = async () => {
    if (!isPublished()) {
      return;
    }
    return new Promise((resolve, reject) => {
      store
        .dispatch(
          'product/marginStack/' +
          MARGIN_STACK.ACTIONS.GET_PUBLISHED_MARGIN_STACK_MARKETPLACE,
          storefrontId
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            return resolve(cloneDeep(marginStackMarketplace.value));
          }
          reject(error);
        });
    });
  };



  const publishMarginStackMarketplace = () => {
    isPublishing.value = true;
    store
      .dispatch('product/' + PRODUCT.ACTIONS.PUBLISH_MARKETPLACE, {
        marketplaceId,
        offerId: getOfferId(),
        productId: getProductId()
      })
      .then(async () => {
        await getMarginStackMarketplace();

        toastNotification.success(
          'Product will be published within 48 hours'
        );
      })
      .finally(() => {
        isPublishing.value = false;
      })
      .catch(() => {
        toastNotification.error(
          isPublished()
            ? 'Product couldn’t be unpublished. Try again later'
            : 'Product couldn’t be published. Try again later'
        );
      });

  };
  const publishSavedChangesToMarginStackMarketplace = () => {
    isPublishing.value = true;
    store
      .dispatch('product/' + PRODUCT.ACTIONS.PUBLISH_MARKETPLACE, {
        marketplaceId,
        offerId: getOfferId(),
        productId: getProductId()
      })
      .then(async () => {
        await getMarginStackMarketplace();

        toastNotification.success(
          'Product will be updated within 48 hours'
        );
      })
      .finally(() => {
        isPublishing.value = false;
      })
      .catch(() => {
        toastNotification.error(
          'Product could not be updated. Please try again later.'
        );
      });

  };


  const publishOrUnpublishMarginStackMarketplace = () => {
    isPublishing.value = true;
    if (isPublished()) {
      store
        .dispatch('product/' + PRODUCT.ACTIONS.UNPUBLISH_MARKETPLACE, {
          marketplaceId,
          offerId: getOfferId(),
          productId: getProductId()
        })
        .then(async () => {
          await getMarginStackMarketplace();

          toastNotification.success(
            'Product will be unpublished within 24 hours',
            {
              modalTrigger: 'Expedite',
              modalTitle: 'Expedite unpublish process',
              modalContent:
                'For immediate attention in unpublishing this product, please email your MarketNation representative.'
            }
          )
        })
        .finally(() => {
          isPublishing.value = false;
        })
        .catch(() => {
          toastNotification.error(
            isPublished()
              ? 'Product couldn’t be unpublished. Try again later'
              : 'Product couldn’t be published. Try again later'
          );
        });
    } else {
      store
        .dispatch('product/' + PRODUCT.ACTIONS.PUBLISH_MARKETPLACE, {
          marketplaceId,
          offerId: getOfferId(),
          productId: getProductId()
        })
        .then(async () => {
          await getMarginStackMarketplace();

          toastNotification.success(
            'Product will be published within 48 hours'
          );
        })
        .finally(() => {
          isPublishing.value = false;
        })
        .catch(() => {
          toastNotification.error(
            isPublished()
              ? 'Product couldn’t be unpublished. Try again later'
              : 'Product couldn’t be published. Try again later'
          );
        });
    }
  };

  const publishMarketplaceAll = () => {
    store
      .dispatch('product/' + PRODUCT.ACTIONS.PUBLISH_MARKETPLACE_ALL,
        getOfferId(),
      )
      .then(async () => {
        toastNotification.success(
          'Product will be published within 48 hours'
        );
      })
      .finally(() => {
        isPublishing.value = false;
      })
      .catch(() => {
        toastNotification.error("Channel could not be updated at this time."
        );
      });

  };

  return {
    canPublish,
    isPublished,
    getPublishButtonLabel,
    getPublishedMarginStackMarketplace,
    publishOrUnpublishMarginStackMarketplace,
    publishedMarginStackMarketplace,
    isPublishing,
    publishMarketplaceAll,
    publishMarginStackMarketplace,
    publishSavedChangesToMarginStackMarketplace
  };
}
