import ApiService from '@/services/api.service';
import { cloneDeep } from 'lodash';
import {
  IInventoryState,
  IInventoryGetters,
  IInventoryActions,
  InventoryMutationsTypes
} from '@/types/interfaces/store/product/offer/accordions/inventory';
import { PD_OFFER, PARTNER } from '@/types/constants';
import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { AxiosError, AxiosResponse } from 'axios';
import {
  IInventory,
  IInventoryError,
  IOfferStorefrontInventories
} from '@/types/interfaces/product/offer/accordions/inventory';
import { IState } from '@/types/interfaces/store';
import { IOfferState } from '@/types/interfaces/store/product/offer';

const helpers = {
  addMarketplaceIdToInventoryStorefronts: (
    offerStorefrontInventories: IOfferStorefrontInventories[],
    rootGetters: any
  ) => {
    offerStorefrontInventories.forEach((storefrontInventory) => {
      const storefront = rootGetters[
        'partner/' + PARTNER.GETTERS.GET_MARKETPLACE_STOREFRONT_BY_ID
      ](storefrontInventory.marketplaceStorefrontId);
      storefrontInventory.marketplaceId = storefront.marketplaceId;
    });
  },
  sortInventoryStorefronts: (
    offerStorefrontInventories: IOfferStorefrontInventories[]
  ) => {
    offerStorefrontInventories.sort((a, b) => {
      return a.priority - b.priority;
    });
  }
};

export const state: IInventoryState = {
  isEditingInventory: false,
  isSavingInventory: false,
  isLoadingInventory: false,

  inventory: null,
  originalInventory: null,
  emptyInventory: {
    totalAvailableInventory: 0,
    partnerTotalAvailableInventory: 0,
    partnerReservedInventory: 0,
    holdbackAmount: 0,
    holdbackAmountType: 'PERCENT',
    offerStorefrontInventories: []
  },
  inventoryErrors: null
};

export const getters: GetterTree<IInventoryState, IInventoryState> &
  IInventoryGetters = {};

export const actions: ActionTree<IOfferState, IState> & IInventoryActions = {
  [PD_OFFER.ACTIONS.GET_INVENTORY](
    { commit, rootGetters },
    { productId, offerId }: { productId: number; offerId: number }
  ) {
    return new Promise((resolve, reject) => {
      commit(PD_OFFER.MUTATIONS.SET_IS_LOADING_INVENTORY, true);

      const url =
        process.env.VUE_APP_CATALOG_SERVICE +
        '/products/' +
        productId +
        '/offers/' +
        offerId +
        '/inventory';

      ApiService.get(url)
        .then((response: AxiosResponse<IInventory>) => {
          const inventory = response.data;
          helpers.addMarketplaceIdToInventoryStorefronts(
            inventory.offerStorefrontInventories,
            rootGetters
          );
          helpers.sortInventoryStorefronts(
            inventory.offerStorefrontInventories
          );
          commit(PD_OFFER.MUTATIONS.SET_IS_LOADING_INVENTORY, false);
          commit(PD_OFFER.MUTATIONS.SET_INVENTORY, inventory);
          commit(PD_OFFER.MUTATIONS.SET_ORIGINAL_INVENTORY, inventory);
          resolve(inventory);
        })
        .catch((error: AxiosError) => {
          commit(PD_OFFER.MUTATIONS.SET_IS_LOADING_INVENTORY, false);
          reject(error);
        });
    });
  },

  [PD_OFFER.ACTIONS.UPDATE_INVENTORY](
    { commit },
    { productId, offerId }: { productId: number; offerId: number }
  ) {
    return new Promise((resolve, reject) => {
      const url =
        process.env.VUE_APP_CATALOG_SERVICE +
        '/products/' +
        productId +
        '/offers/' +
        offerId +
        '/inventory';
      const data = state.inventory;

      ApiService.put(url, data)
        .then((response: AxiosResponse<IInventory>) => {
          resolve(response);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }
};

const mutations: MutationTree<IOfferState> & InventoryMutationsTypes = {
  [PD_OFFER.MUTATIONS.SET_INVENTORY_STOREFRONTS](
    state: IInventoryState,
    data: IOfferStorefrontInventories[]
  ) {
    if (!state.inventory) {
      return;
    }
    state.inventory.offerStorefrontInventories = data;
  },

  [PD_OFFER.MUTATIONS.SET_IS_EDITING_INVENTORY](
    state: IInventoryState,
    value: boolean
  ) {
    state.isEditingInventory = value;
  },

  [PD_OFFER.MUTATIONS.SET_IS_LOADING_INVENTORY](
    state: IInventoryState,
    value: boolean
  ) {
    state.isLoadingInventory = value;
  },

  [PD_OFFER.MUTATIONS.SET_INVENTORY](
    state: IInventoryState,
    value: IInventory
  ) {
    state.inventory = value;
  },

  [PD_OFFER.MUTATIONS.SET_ORIGINAL_INVENTORY](
    state: IInventoryState,
    value: IInventory
  ) {
    state.originalInventory = cloneDeep(value);
  },

  [PD_OFFER.MUTATIONS.SET_INVENTORY_ERRORS](
    state: IInventoryState,
    value: IInventoryError
  ) {
    state.inventoryErrors = value;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
