import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "p-6 pb-px" }

export function render(_ctx, _cache) {
  const _component_t_textarea = _resolveComponent("t-textarea")
  const _component_t_image_uploader_per_marketplace = _resolveComponent("t-image-uploader-per-marketplace")
  const _component_t_collapsible_card = _resolveComponent("t-collapsible-card")

  return (_ctx.offerSpecificContent.wrapper)
    ? (_openBlock(), _createBlock(_component_t_collapsible_card, {
        key: 0,
        "trigger-label": "Offer specific content",
        open: true
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_t_textarea, {
              limiter: 300,
              id: "short_description",
              label: "Short description",
              modelValue: _ctx.offerSpecificContent.wrapper.shortDescription,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.offerSpecificContent.wrapper.shortDescription) = $event))
            }, null, 8, ["modelValue"]),
            _createVNode(_component_t_textarea, {
              limiter: 2000,
              id: "long_description",
              label: "Long description",
              modelValue: _ctx.offerSpecificContent.wrapper.longDescription,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.offerSpecificContent.wrapper.longDescription) = $event))
            }, null, 8, ["modelValue"]),
            _createVNode(_component_t_image_uploader_per_marketplace, {
              label: "Images",
              modelValue: _ctx.offerSpecificContent.wrapper.images,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.offerSpecificContent.wrapper.images) = $event)),
              id: "offer_images"
            }, null, 8, ["modelValue"])
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}