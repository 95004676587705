import { computed, watch } from 'vue';
import { useStore } from 'vuex';
import { PD_CONT, CATEGORY } from '@/types/constants';

export function useCategory() {
  const store = useStore();

  const categoryId = computed(() => {
    const getters = 'product/' + PD_CONT.GETTERS.GET_CATEGORY_ID;
    return store.getters[getters];
  });

  const categoryDetails = computed(
    () => store.getters['category/' + CATEGORY.GETTERS.GET_CATEGORY_DETAILS]
  );

  const categoryAttributeGroups = computed(
    () => categoryDetails.value.productType?.eavAttributeGroups
  );

  const loadCategoryDetails = (id: number) => {
    return store.dispatch(
      'category/' + CATEGORY.ACTIONS.GET_CATEGORY_DETAILS_FROM_BACKEND,
      id
    );
  };

  watch(
    categoryId,
    (value) => {
      if (!value) {
        return;
      }
      return loadCategoryDetails(value);
    },
    { immediate: true }
  );

  return {
    categoryId,
    categoryDetails,
    categoryAttributeGroups
  };
}
