import OrderDetails from '@/views/orders/details';
import Orders from '@/views/orders';

let orderRoutes = [
  {
    path: '/orders',
    name: 'orders',
    component: Orders
  },
  {
    path: '/orders/:id',
    name: 'order_details',
    component: OrderDetails,
    meta: {
      withSidenav: true
    }
  }
];

export default orderRoutes;
