<template src="./template.pug" lang="pug"></template>

<script>
import { mapGetters } from 'vuex';
import { Form } from 'vee-validate';
import InlineSvg from 'vue-inline-svg';
import { USER } from '../../types/constants';

export default {
  name: 'Login',
  components: {
    Form,
    InlineSvg
  },
  data() {
    return {
      email: '',
      password: '',
      isSubmitting: false,
      appName: process.env.VUE_APP_NAME,
      currentYear: new Date().getFullYear(),
      loginError: false,
      loginErrorMessage: '',
      passwordBannerState: '',
      isPasswordBannerOpen: false,
      passwordBannerMessage: '',
      passwordBannerTitle: ''
    };
  },

  computed: {
    ...mapGetters('user', ['IS_AUTHENTICATING'])
  },

  created() {
    this.setPasswordBannerState();
  },

  watch: {
    IS_AUTHENTICATING(newValue) {
      this.isSubmitting = newValue;
    }
  },

  methods: {
    setPasswordBannerState() {
      if (!this.$route.params) {
        return false;
      }
      if (this.$route.params.passwordBannerState) {
        this.passwordBannerState = this.$route.params.passwordBannerState;
        this.showPasswordBanner();
      }
    },

    async onSubmit() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        return false;
      }
      this.loginUser();
    },

    loginUser() {
      this.$store
        .dispatch('user/' + USER.ACTIONS.LOGIN, {
          email: this.email,
          password: this.password
        })
        .then(() => {
          this.setUserProfile();
        })
        .catch((err) => this.handleError(err));
    },

    setUserProfile() {
      this.$store
        .dispatch('user/' + USER.ACTIONS.GET_PROFILE)
        .then(() => {
          // Redirect the user to the page he first tried to visit or to the home view
          this.$router.push(this.$route.query?.redirect || '/');
        })
        .catch((err) => {
          this.handleError(err);
          this.$store.dispatch('user/' + USER.ACTIONS.LOGOUT);
        });
    },

    handleError(err) {
      if (err.response && err.response.data) {
        this.loginErrorMessage = this.getLoginErrorMessage(err.response.data);
      } else {
        this.loginErrorMessage = err.message;
      }
      this.$nextTick(() => {
        this.loginError = true;
      });
    },

    getLoginErrorMessage(data) {
      switch (data) {
        case 'ACCOUNT_EXPIRED':
          return 'This account has expired.';
        case 'ACCOUNT_LOCKED':
          return 'This account is locked.';
        case 'ACCOUNT_INACTIVE':
          return 'This account is no longer active.';
        case 'ACCOUNT_BAD_CREDENTIALS':
          return 'Invalid e-mail address or password.';
        default:
          return 'Error';
      }
    },

    getPasswordBannerTitle() {
      switch (this.passwordBannerState) {
        case 'set_password_success':
          return 'Password reset';
        case 'send_email_success':
          return 'Email sent';
        default:
          return '';
      }
    },

    getPasswordBannerMessage() {
      switch (this.passwordBannerState) {
        case 'set_password_success':
          return 'You can sign in using your new password.';
        case 'send_email_success':
          return 'A link to reset your password has been emailed to you.';
        default:
          return '';
      }
    },

    showPasswordBanner() {
      this.passwordBannerMessage = this.getPasswordBannerMessage();
      this.passwordBannerTitle = this.getPasswordBannerTitle();
      this.$nextTick(() => {
        this.isPasswordBannerOpen = true;
      });
    },

    forgotPasswordHandler() {
      this.$router.push('/forgot-password');
    }
  }
};
</script>
<style scoped lang="scss">
.logo-container {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}
img {
  width: 380px;
  padding-left: 20px;
  text-align: center;
}
</style>
