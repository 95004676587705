<template src="./template.pug" lang="pug"></template>
<script>
export default {
  name: 'MarginStackProposalModal'
};
</script>

<script setup>
import { defineProps, defineEmits, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { MARGIN_STACK } from '../../../../../types/constants';

const store = useStore();

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false
  },
  isSendingProposal: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['update:isOpen', 'submit']);

const _isOpen = ref(false);

watch(
  () => props.isOpen,
  (value) => {
    _isOpen.value = value;
  }
);

const closeProposalModal = () => {
  _isOpen.value = false;
  setTimeout(() => {
    emit('update:isOpen', false);
  }, 400);
};

const getContainedButtonLabel = () => {
  const isUpdating =
    store.getters[
      'product/marginStack/' + MARGIN_STACK.GETTERS.IS_UPDATING_MARGIN_STACK
    ];
  const isProposing =
    store.getters[
      'product/marginStack/' + MARGIN_STACK.GETTERS.IS_PROPOSING_MARGIN_STACK
    ];

  if (isUpdating && isProposing) {
    return 'Save and send proposal';
  }

  if (isProposing) {
    return 'Send proposal';
  }

  return 'Save';
};

const submitProposalModal = () => {
  emit('submit');
  closeProposalModal();
};
</script>
