export enum UserGetters {
  IS_LOGGED_IN = 'IS_LOGGED_IN',
  IS_AUTHENTICATING = 'IS_AUTHENTICATING',
  IS_AUTHENTICATION_ERROR = 'IS_AUTHENTICATION_ERROR',
  GET_PROFILE = 'GET_PROFILE',
  GET_PROFILE_TYPE = 'GET_PROFILE_TYPE',
  GET_USER_ROLES = 'GET_USER_ROLES'
}
export enum UserMutations {
  LOGIN_REQUEST = 'LOGIN_REQUEST',
  LOGIN_ERROR = 'LOGIN_ERROR',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGOUT_REQUEST = 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
  GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST',
  GET_PROFILE_ERROR = 'GET_PROFILE_ERROR',
  GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS',
  VERIFY_SET_PASSWORD_REQUEST = 'VERIFY_SET_PASSWORD_REQUEST',
  VERIFY_SET_PASSWORD_SUCCESS = 'VERIFY_SET_PASSWORD_SUCCESS',
  VERIFY_SET_PASSWORD_ERROR = 'VERIFY_SET_PASSWORD_ERROR',
  SET_PASSWORD_REQUEST = 'SET_PASSWORD_REQUEST',
  SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS',
  SET_PASSWORD_ERROR = 'SET_PASSWORD_ERROR',
  FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR',
  SET_PROFILE_TYPE = 'SET_PROFILE_TYPE'
}
export enum UserActions {
  LOGIN = 'LOGIN',
  GET_PROFILE = 'GET_PROFILE',
  LOGOUT = 'LOGOUT',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  VERIFY_SET_PASSWORD = 'VERIFY_SET_PASSWORD',
  SET_PASSWORD = 'SET_PASSWORD',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD'
}
