export enum GridUploadStatusGetters {
  GET_UPLOAD_STATUS_GRID_ITEMS = 'GET_UPLOAD_STATUS_GRID_ITEMS',
  IS_LOADING_UPLOAD_STATUS_GRID = 'IS_LOADING_UPLOAD_STATUS_GRID'
}
export enum GridUploadStatusMutations {
  GET_UPLOAD_STATUS_GRID_REQUEST = 'GET_UPLOAD_STATUS_GRID_REQUEST',
  SET_UPLOAD_STATUS_GRID_SUCCESS = 'SET_UPLOAD_STATUS_GRID_SUCCESS',
  DOWNLOAD_UPLOAD_STATUS_REQUEST = 'DOWNLOAD_UPLOAD_STATUS_REQUEST'
}
export enum GridUploadStatusActions {
  GET_UPLOAD_STATUS_GRID = 'GET_UPLOAD_STATUS_GRID',
  DOWNLOAD_UPLOAD_STATUS_REPORT = 'DOWNLOAD_UPLOAD_STATUS_REPORT'
}

export enum UploadStatus {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED'
}
