import { useStore } from 'vuex';
import { ref, watch } from 'vue';
import { MARGIN_STACK } from '@/types/constants';

export function useStateValues(props: {
  isOpen: boolean;
  storefrontId: number;
}) {
  const store = useStore();

  const _isOpen = ref(false);
  watch(
    () => props.isOpen,
    (value) => {
      _isOpen.value = value;
    },
    { immediate: true }
  );

  const isEditing = ref(false);
  const setIsEditing = (value: boolean) => {
    isEditing.value = value;
    store.commit(
      'product/marginStack/' +
        MARGIN_STACK.MUTATIONS.SET_IS_EDITING_MARKETPLACE,
      {
        storefrontId: props.storefrontId,
        value
      }
    );
  };

  const isProposing = ref(false);
  const setIsProposing = (value: boolean) => {
    isProposing.value = value;
    store.commit(
      'product/marginStack/' +
        MARGIN_STACK.MUTATIONS.SET_IS_PROPOSING_MARKETPLACE,
      {
        storefrontId: props.storefrontId,
        value
      }
    );
  };

  const isUpdating = ref(false);
  const setIsUpdating = (value: boolean) => {
    isUpdating.value = value;
    store.commit(
      'product/marginStack/' +
        MARGIN_STACK.MUTATIONS.SET_IS_UPDATING_MARKETPLACE,
      {
        storefrontId: props.storefrontId,
        value
      }
    );
  };

  let isLoading = ref(false);

  return {
    _isOpen,
    isEditing,
    setIsEditing,
    isProposing,
    setIsProposing,
    isUpdating,
    setIsUpdating,
    isLoading
  };
}
