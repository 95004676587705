import { ref, inject } from 'vue';
import { useFormatters } from '@/composables/formatters';
import { useStatuses } from '@/composables/statuses';
import { useOptions } from '@/composables/views/products/product-details/options';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { usePostOrderActivity } from '@/composables/views/order/post-order-activity';
import { useUtilityFunction } from '@/composables/utilityFunctions';
import { ORDER } from '@/types/constants';
import { IItem } from '@/types/interfaces/order/order';

export function useProducts() {
  const { getCurrencyFormat } = useFormatters();
  const { orderItemStatusOptions } = useStatuses();
  const { carrierOptions } = useOptions();
  const { setPostOrderActivityItems } = usePostOrderActivity();
  const { isValueEmpty } = useUtilityFunction();
  const route = useRoute();
  const store = useStore();
  const toastNotification = inject<any>('toastNotification');

  const productTh = ref([
    { label: 'sku', primaryName: 'sku', secondaryName: 'opportunityId' },
    { label: 'unit price', primaryName: 'unitPrice' },
    { label: 'qty', primaryName: 'quantity' },
    { label: 'serial #', primaryName: 'serial' },
    { label: 'item status', primaryName: 'itemStatus' },
    {
      label: 'fulfilled by',
      primaryName: 'fulfilledBy',
      secondaryName: 'shipFrom'
    },
    {
      label: 'tracking',
      primaryName: 'trackingNumber',
      secondaryName: 'carrier'
    }
  ]);

  const showEditProductsModal = ref<boolean>(false);

  const getProductsItemPrimaryValue = (value, name: string) => {
    switch (name) {
      case 'unitPrice':
        return getCurrencyFormat(value);
      case 'itemStatus':
        return getItemStatusLabel(value);
      default:
        return value;
    }
  };

  const getProductsItemSecondaryValue = (value, name: string) => {
    switch (name) {
      case 'opportunityId':
        return getOpportunityIdLabel(value);
      case 'carrier':
        return getCarrierLabel(value);
      default:
        return value;
    }
  };

  const getItemStatusLabel = (value: string) => {
    return orderItemStatusOptions.find((status) => status.value === value)
      ?.label;
  };

  const getCarrierLabel = (value: string) => {
    if (!value) {
      return '';
    }
    return carrierOptions.find((status) => status.value === value)?.label;
  };

  const getOpportunityIdLabel = (opportunityId: number) => {
    if (!opportunityId) {
      return '';
    }
    return 'Opportunity ID: ' + opportunityId;
  };

  const openEditProductsModal = () => {
    showEditProductsModal.value = true;
  };

  const updateOrderItems = (items: IItem[]) => {
    return store
      .dispatch('order/' + ORDER.ACTIONS.UPDATE_ORDER_ITEMS, {
        orderId: route.params.id,
        items
      })
      .then(() => {
        store.commit('order/' + ORDER.MUTATIONS.SET_ORDER_ITEMS, items);
        setPostOrderActivityItems(items);
        toastNotification.success('Edits saved.');
      })
      .catch(() => {
        toastNotification.error('Edits couldn’t be saved.');
      });
  };

  return {
    productTh,
    showEditProductsModal,
    getProductsItemPrimaryValue,
    getProductsItemSecondaryValue,
    getItemStatusLabel,
    getCarrierLabel,
    getOpportunityIdLabel,
    openEditProductsModal,
    updateOrderItems
  };
}
