import { resolveComponent as _resolveComponent, createVNode as _createVNode, toHandlers as _toHandlers, mergeProps as _mergeProps, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "p-6 pb-8" }

export function render(_ctx, _cache) {
  const _component_t_textarea = _resolveComponent("t-textarea")
  const _component_t_input = _resolveComponent("t-input")
  const _component_t_bullet_input = _resolveComponent("t-bullet-input")
  const _component_t_collapsible_card = _resolveComponent("t-collapsible-card")

  return (_ctx.description)
    ? (_openBlock(), _createBlock(_component_t_collapsible_card, {
        key: 0,
        "trigger-label": "Description",
        open: true
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_t_textarea, {
              limiter: 300,
              id: "short_description",
              label: "Short description",
              modelValue: _ctx.description.shortDescription,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.description.shortDescription) = $event))
            }, null, 8, ["modelValue"]),
            _createVNode(_component_t_textarea, {
              limiter: 2000,
              id: "long_description",
              label: "Long description",
              modelValue: _ctx.description.longDescription,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.description.longDescription) = $event))
            }, null, 8, ["modelValue"]),
            _createVNode(_component_t_bullet_input, {
              id: "bullet_description",
              label: "Feature bullets",
              modelValue: _ctx.description.featureBullets,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.description.featureBullets) = $event)),
              "add-button-label": "Add feature bullet"
            }, {
              default: _withCtx(({ array, index, props, events }) => [
                _createVNode(_component_t_input, _mergeProps(props, _toHandlers(events), {
                  modelValue: array[index],
                  "onUpdate:modelValue": $event => ((array[index]) = $event),
                  limiter: 799
                }), null, 16, ["modelValue", "onUpdate:modelValue"])
              ]),
              _: 1
            }, 8, ["modelValue"])
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}