import placeholderImgError from '../assets/img/placeholders/loading-error.svg';
import placeholderNoImg from '../assets/img/placeholders/no-image.svg';

export default function useProductImageUrl() {
  const getProductImageUrl = (url: string) => {
    let productImage = url;

    if (!productImage || productImage === '') {
      return placeholderNoImg;
    }

    checkImageUrl(url)
      .then(() => {
        return productImage;
      })
      .catch(() => {
        return placeholderImgError;
      });

    return productImage;
  };

  const checkImageUrl = (url: string) => {
    return new Promise<void>((resolve, reject) => {
      let image = new Image();

      image.onload = function () {
        if (image.width > 0) {
          resolve();
        }
      };

      image.onerror = function () {
        reject();
      };

      image.src = url;
    });
  };

  return {
    getProductImageUrl
  };
}
