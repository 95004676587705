import * as PartnerType from '@/mixins/partnerType';
import isGridFilterEmpty from '@/mixins/isGridFilterEmpty';

const mixin = {
  mixins: [isGridFilterEmpty],
  data() {
    return {
      downloadOptions: []
    };
  },
  computed: {
    disabledDownloadOptions() {
      return this.downloadOptions
        .filter((option) => {
          return option.disabled;
        })
        .map((option) => {
          return option.value;
        });
    }
  },
  methods: {
    setDownloadOptions() {
      const profileType = this.$store.state.user.profile.type;
      const displayedAmountOfANProducts = this.isCurrentTabAN
        ? this.amountOfANProducts
        : 0;
      const displayedAmountFilteredProducts = this.isCurrentlyFiltering()
        ? this.isCurrentTabAN
          ? this.amountOfANProducts
          : this.amountOfProducts
        : 0;

      if (profileType === PartnerType.MARKETNATION) {
        this.downloadOptions = [
          { value: 'ALL', label: 'All opportunities (only current version)' },
          {
            value: 'ALL_HISTORY',
            label: 'All opportunities (includes historical source of truth)'
          },
          {
            value: 'MATCH',
            label:
              displayedAmountFilteredProducts +
              ' products matching your search (only current version)',
            disabled: !this.isCurrentlyFiltering()
          },
          {
            value: 'MATCH_HISTORY',
            label:
              displayedAmountFilteredProducts +
              ' products matching your search (includes historical source of truth)',
            disabled: !this.isCurrentlyFiltering()
          },
          {
            value: 'NEED_ATTENTION',
            label:
              displayedAmountOfANProducts +
              ' products needing your attention (only current version)',
            disabled: !this.isCurrentTabAN
          },
          {
            value: 'NEED_ATTENTION_HISTORY',
            label:
              displayedAmountOfANProducts +
              ' products needing your attention (includes historical source of truth)',
            disabled: !this.isCurrentTabAN
          }
        ];
      } else {
        this.downloadOptions = [
          { value: 'ALL', label: 'All opportunities' },
          {
            value: 'MATCH',
            label:
              displayedAmountFilteredProducts +
              ' products matching your search',
            disabled: !this.isCurrentlyFiltering()
          },
          {
            value: 'NEED_ATTENTION',
            label:
              displayedAmountOfANProducts + ' products needing your attention',
            disabled: !this.isCurrentTabAN
          }
        ];
      }
    },
    isCurrentlyFiltering() {
      if (this.isCurrentTabAN) {
        return !this.isFilterEmpty(this.ANProductFilters.filter);
      }
      return !this.isFilterEmpty(this.productFilters.filter);
    }
  }
};

export default mixin;
