import { useStore } from 'vuex';
import { USER } from '@/types/constants';

export function usePartnerTypes() {
  const store = useStore();
  const profileType = store.getters['user/' + USER.GETTERS.GET_PROFILE_TYPE];

  const BRAND = 'BRAND';
  const FULFILLMENT = 'DISTRIBUTION_PARTNER';
  const MARKETNATION = 'MARKETNATION';
  const MARKETPLACE = 'MARKETPLACE';

  const isUserMarketnation = () => {
    return profileType === MARKETNATION;
  };

  const isUserBrand = () => {
    return profileType === BRAND;
  };

  const isUserFulfillment = () => {
    return profileType === FULFILLMENT;
  };

  const isUserMarketplace = () => {
    return profileType === MARKETPLACE;
  };

  return {
    BRAND,
    FULFILLMENT,
    MARKETNATION,
    MARKETPLACE,
    isUserMarketnation,
    isUserBrand,
    isUserFulfillment,
    isUserMarketplace
  };
}
