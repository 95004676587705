import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center flex-wrap sm:flex-nowrap" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "md:text-4xl font-bold text-dark-knight ml-4 leading-relaxed text-3xl my-8" }

export function render(_ctx, _cache) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("button", {
      class: "focus:outline-none",
      onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.pushOrShowModal && _ctx.pushOrShowModal(...args)))
    }, [
      _createVNode(_component_font_awesome_icon, {
        class: "md:text-2xl",
        icon: "arrow-left"
      })
    ]),
    _createElementVNode("img", {
      class: "ml-8 mr-3 max-h-24 my-5",
      src: _ctx.getMainImageUrl()
    }, null, 8, _hoisted_2),
    _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.getProductPageTitle()), 1)
  ]))
}