import { computed, inject, Ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useOnPageLoad } from './onPageLoad';
import { MARGIN_STACK, PD_OFFER, PRODUCT, PD_CONT } from '@/types/constants';

export function useSaveBar(
  activeHorizontalTab: Ref<string | undefined>,
  horizontalTabCodes: {
    PRODUCT_CONTENT: 'product-content';
    SKU_DETAILS: 'sku-details';
    MARGIN_STACK: 'margin-stack';
  },
  marginStack: Ref<any>
) {
  const store = useStore();
  const router = useRouter();
  const toastNotification = inject<any>('toastNotification');
  const { onProductPageLoad } = useOnPageLoad();

  const isSaveBarVisible = computed(() => {
    return (
      store.getters['product/' + PRODUCT.GETTERS.IS_EDITING_PRODUCT] ||
      store.getters['product/offer/' + PD_OFFER.GETTERS.IS_EDITING_OFFER] ||
      store.getters[
      'product/marginStack/' + MARGIN_STACK.GETTERS.IS_EDITING_MARGIN_STACK
      ] ||
      store.getters['product/offer/' + PD_OFFER.GETTERS.SAVED_DATA_NOT_PUBLISHED] ||
      store.getters['product/marginStack' + MARGIN_STACK.GETTERS.SAVED_DATA_NOT_PUBLISHED]
    );
  });

  const onSaveButtonClick = () => {
    switch (activeHorizontalTab.value) {
      case horizontalTabCodes.PRODUCT_CONTENT:
        return triggerProductContentSaving();
      case horizontalTabCodes.SKU_DETAILS:
        return triggerSkuDetailsSaving();
      case horizontalTabCodes.MARGIN_STACK:
        return triggerMarginStackSaving();
    }
  };

  const triggerProductContentSaving = () => {
    const isAddingNewProduct: boolean = store.state.product.isAddingNewProduct;

    if (isAddingNewProduct) {
      return createProduct();
    }
    updateProduct();

    store.commit('product/' + PRODUCT.MUTATIONS.CLEAR_ERRORS_OBJECT);
  };

  const createProduct = () => {
    return store
      .dispatch('product/' + PRODUCT.ACTIONS.CREATE_PRODUCT_CONTENT)
      .then(async (response) => {
        await router.push(response.data.id + '/edit');
        await onProductPageLoad();
        onSaveSuccess();
      })
      .catch(onSaveError);
  };

  const updateProduct = () => {
    store
      .dispatch('product/' + PRODUCT.ACTIONS.UPDATE_PRODUCT_CONTENT)
      .then(onSaveSuccess)
      .catch(onSaveError);
  };

  const triggerSkuDetailsSaving = () => {
    const isAddingNewOffer =
      store.getters['product/offer/' + PD_OFFER.GETTERS.IS_ADDING_NEW_OFFER];

    if (isAddingNewOffer) {
      return createOffer();
    }
    updateOffer();

    store.commit('product/offer/' + PD_OFFER.MUTATIONS.CLEAR_ERRORS_OBJECT);
  };

  const createOffer = () => {
    return store
      .dispatch('product/offer/' + PD_OFFER.ACTIONS.CREATE_OFFER)
      .then(async (response) => {
        await store.dispatch('product/' + PRODUCT.ACTIONS.GET_OFFERS_LIST);
        store.commit(
          'product/offer/' + PD_OFFER.MUTATIONS.SET_OFFER_ID,
          response.data.id
        );
        onSaveSuccess();
      })
      .catch(onSaveError);
  };

  const updateOffer = () => {
    store
      .dispatch('product/offer/' + PD_OFFER.ACTIONS.UPDATE_OFFER)
      .then(onSaveSuccess)
      .catch(onSaveError);
  };

  const triggerMarginStackSaving = async () => {
    store.commit(
      'product/marginStack/' + MARGIN_STACK.MUTATIONS.SET_CONTAINER_SCROLL_TOP,
      containerScrollTopPosition()
    );
    marginStack.value.triggerMarginStackSaving();
  };

  const containerScrollTopPosition = () => {
    const scrollContainer = document.getElementById('scroll_container');
    return scrollContainer?.scrollTop;
  };

  const onSaveSuccess = () => {
    if (activeHorizontalTab.value === horizontalTabCodes.MARGIN_STACK) {
      toastNotification.warn('Additional action required to activate changes in channels.');

    } else {
      toastNotification.success('Edits saved.');
    }
  };

  const onSaveError = () => {
    toastNotification.error('Changes need to be made in order to save.');
  };

  return {
    isSaveBarVisible,
    onSaveButtonClick
  };
}
