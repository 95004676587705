export enum MarketplaceValueType {
  WALMART = 'WALMART',
  EBAY = 'EBAY',
  CUSTOMER_NATION = 'CUSTOMER_NATION',
  PLUS_SHOP = 'PLUS_SHOP',
  AMAZON = 'AMAZON'
}

export enum CarrierValueType {
  UPS = 'UPS',
  USPS = 'USPS',
  FEDEX = 'FedEx',
  AIRBORNE = 'Airborne',
  ONTRAC = 'OnTrac',
  DHL = 'DHL',
  LS = 'LS',
  UDS = 'UDS',
  UPSMI = 'UPSMI',
  FDX = 'FDX',
  PILOT = 'PILOT',
  ESTES = 'ESTES',
  SAIA = 'SAIA',
  FDS_EXPRESS = 'FDS Express',
  SEKO_WORLDWIDE = 'Seko Worldwide',
  HIT_DELIVERY = 'HIT Delivery',
  FEDEXSP = 'FEDEXSP'
}
