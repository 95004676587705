export enum MarginStackGetters {
  IS_EDITING_MARGIN_STACK = 'IS_EDITING_MARGIN_STACK',
  IS_PROPOSING_MARGIN_STACK = 'IS_PROPOSING_MARGIN_STACK',
  IS_UPDATING_MARGIN_STACK = 'IS_UPDATING_MARGIN_STACK',
  DISCARD_CHANGES_TRIGGER = 'DISCARD_CHANGES_TRIGGER',
  IS_LOADING = 'IS_LOADING',
  CONTAINER_SCROLL_TOP = 'CONTAINER_SCROLL_TOP',
  SAVED_DATA_NOT_PUBLISHED = "SAVED_DATA_NOT_PUBLISHED"
}
export enum MarginStackMutations {
  SET_IS_EDITING_MARKETPLACE = 'SET_IS_EDITING_MARKETPLACE',
  SET_IS_EDITING = 'SET_IS_EDITING',
  SET_IS_PROPOSING_MARKETPLACE = 'SET_IS_PROPOSING_MARKETPLACE',
  SET_IS_UPDATING_MARKETPLACE = 'SET_IS_UPDATING_MARKETPLACE',
  SET_IS_SAVING = 'SET_IS_SAVING',
  SET_IS_LOADING = 'SET_IS_LOADING',
  SET_DISCARD_CHANGES_TRIGGER = 'SET_DISCARD_CHANGES_TRIGGER',
  SET_CONTAINER_SCROLL_TOP = 'SET_CONTAINER_SCROLL_TOP',
  SET_SAVED_DATA_NOT_PUBLISHED = "SET_SAVED_DATA_NOT_PUBLISHED"
}
export enum MarginStackActions {
  GET_MARGIN_STACK_MARKETPLACE = 'GET_MARGIN_STACK_MARKETPLACE',
  GET_PUBLISHED_MARGIN_STACK_MARKETPLACE = 'GET_PUBLISHED_MARGIN_STACK_MARKETPLACE',
  UPDATE_MARGIN_STACK = 'UPDATE_MARGIN_STACK',
  CLEAR_PROPOSALS = 'CLEAR_PROPOSALS',
  SEND_PROPOSALS = 'SEND_PROPOSALS',
  APPROVE_SECTION = 'APPROVE_SECTION',
  FILL_MARGIN_STACK_MARKETPLACE = 'FILL_MARGIN_STACK_MARKETPLACE'
}

export enum ApprovalStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED'
}

export enum OpportunityValueType {
  DATE = 'DATE',
  NUMBER = 'NUMBER',
  DOUBLE = 'DOUBLE',
  BOOLEAN = 'BOOLEAN',
  STRING = 'STRING'
}

export enum InputType {
  BUILT_IN_FULFILLMENT_COST = 'BUILT_IN_FULFILLMENT_COST',
  BUILT_IN_PRODUCT_COST_WITH_FULFILLMENT_INCLUDING_FREIGHT = 'BUILT_IN_PRODUCT_COST_WITH_FULFILLMENT_INCLUDING_FREIGHT',
  BUILT_IN_FULFILLMENT_MARGIN = 'BUILT_IN_FULFILLMENT_MARGIN',
  BUILT_IN_FREIGHT_COST = 'BUILT_IN_FREIGHT_COST',
  BUILT_IN_LISTING_PRICE = 'BUILT_IN_LISTING_PRICE',
  BUILT_IN_CONSUMER_PAID_SHIPPING = 'BUILT_IN_CONSUMER_PAID_SHIPPING',
  BUILT_IN_MARKETPLACE_MARGIN = 'BUILT_IN_MARKETPLACE_MARGIN',
  BUILT_IN_PAYMENT_PROCESSING_FEE = 'BUILT_IN_PAYMENT_PROCESSING_FEE',
  BUILT_IN_TRANSACTION_FEE = 'BUILT_IN_TRANSACTION_FEE',
  BUILT_IN_MINIMUM_FEE = 'BUILT_IN_MINIMUM_FEE',
  BUILT_IN_PROMO_START = 'BUILT_IN_PROMO_START',
  BUILT_IN_PROMO_END = 'BUILT_IN_PROMO_END',
  BUILT_IN_RETURNS_OVERAGE_FEE = 'BUILT_IN_RETURNS_OVERAGE_FEE',
  BUILT_IN_MARKETPLACE_REFERRAL_ON_SHIPPING = 'BUILT_IN_MARKETPLACE_REFERRAL_ON_SHIPPING',
  BUILT_IN_STREET_PRICE = 'BUILT_IN_STREET_PRICE',
  BUILT_IN_AMAZON_BUY_BOX_DATE = 'BUILT_IN_AMAZON_BUY_BOX_DATE',
  BUILT_IN_COST_OF_RETURNS = 'BUILT_IN_COST_OF_RETURNS',
  BUILT_IN_TRANSACTION_SERVICE_FEE = 'BUILT_IN_TRANSACTION_SERVICE_FEE',
  BRAND_MARGIN = 'BRAND_MARGIN',
  FULFILLMENT_MARGIN = 'FULFILLMENT_MARGIN',
  FULFILLMENT_COST = 'FULFILLMENT_COST',
  FREIGHT_COST = 'FREIGHT_COST',
  SALES_MARGIN = 'SALES_MARGIN',
  MDF = 'MDF',
  MARKETING = 'MARKETING'
}

export enum ProductStatus {
  PRODUCT_STATUS_MISSING_INPUTS = 'PRODUCT_STATUS_MISSING_INPUTS',
  PRODUCT_STATUS_READY_FOR_MARKETPLACE = 'PRODUCT_STATUS_READY_FOR_MARKETPLACE',
  PRODUCT_STATUS_ADJUSTMENTS_NEEDED = 'PRODUCT_STATUS_ADJUSTMENTS_NEEDED',
  PRODUCT_STATUS_UPDATE_IN_PROGRESS = 'PRODUCT_STATUS_UPDATE_IN_PROGRESS'
}

export enum MarketplaceStatus {
  PUBLISHED = 'published',
  PENDING_PUBLISH = 'pending_publish',
  PUBLISH_FAILED = 'publish_failed',
  UNPUBLISHED = 'unpublished',
  PENDING_UNPUBLISH = 'pending_unpublish',
  UNPUBLISH_FAILED = 'unpublish_failed',
  INACTIVE = 'inactive',
  ACTIVE = 'active'
}

export enum MoneyType {
  PERCENT = 'PERCENT',
  MONEY = 'MONEY'
}

export enum SectionType {
  GENERAL = 'GENERAL',
  BRAND = 'BRAND',
  FULFILLMENT = 'FULFILLMENT',
  MARKETPLACE = 'MARKETPLACE',
  MARKETNATION = 'MARKETNATION'
}
