import { ref } from 'vue';
import { useFormatters } from '@/composables/formatters';
import { IItem, IPostOrderActivityItem } from '@/types/interfaces/order/order';

export function usePostOrderActivity() {
  const { getCurrencyFormat } = useFormatters();

  const postOrderActivityTh = ref([
    { label: 'sku', primaryName: 'sku' },
    { label: 'serial #', primaryName: 'serial' },
    { label: 'status', primaryName: 'status' },
    { label: 'seller return id', primaryName: 'sellerReturnId' },
    { label: 'mp return id', primaryName: 'mpReturnId' },
    { label: 'credit id', primaryName: 'creditId' },
    { label: 'refund amt', primaryName: 'refundAmt' }
  ]);
  const postOrderActivityStatusOptions = ref([
    {
      value: 'Return',
      label: 'Return'
    },
    {
      value: 'Refunded',
      label: 'Refunded'
    }
  ]);

  const showEditPostOrderActivity = ref<boolean>(false);
  const postOrderActivityItems = ref<IPostOrderActivityItem[] | null | []>([]);

  const getPostOrderActivityItemPrimaryValue = (
    value: string,
    name: string
  ) => {
    switch (name) {
      case 'status':
        return getPostOrderActivityStatusLabel(value);
      case 'refundAmt':
        return getRefundAmt(value);
      default:
        return value;
    }
  };

  const getPostOrderActivityStatusLabel = (value: string) => {
    return postOrderActivityStatusOptions.value.find(
      (status) => status.value === value
    )?.label;
  };

  const getRefundAmt = (value) => {
    if (!value) {
      return '';
    }
    return getCurrencyFormat(value);
  };

  const openEditPostOrderActivityModal = () => {
    showEditPostOrderActivity.value = true;
  };

  const setPostOrderActivityItems = (items: IItem[]) => {
    if (!items) {
      postOrderActivityItems.value = [];
      return;
    }
    let postOrderActivityItemsCopy: any = [];

    items.forEach((item) => {
      if (item.itemStatus === 'Return') {
        postOrderActivityItemsCopy.push(getPostOrderActivityItem(item));
      }
    });

    postOrderActivityItems.value = postOrderActivityItemsCopy;
  };

  const getPostOrderActivityItem = (item: IItem) => {
    return {
      sku: item.sku,
      serial: item.serial,
      status: 'Return',
      sellerReturnId: '',
      mpReturnId: '',
      creditId: '',
      refundAmt: null
    };
  };

  const getPostOrderActivityModalChanges = (changes) => {
    postOrderActivityItems.value = null;
    postOrderActivityItems.value = changes;
  };
  return {
    openEditPostOrderActivityModal,
    setPostOrderActivityItems,
    getPostOrderActivityModalChanges,
    getPostOrderActivityItemPrimaryValue,
    postOrderActivityTh,
    postOrderActivityStatusOptions,
    showEditPostOrderActivity,
    postOrderActivityItems
  };
}
