<template>
  <t-modal
    id="upload_products_modal"
    :isOpen="isOpen"
    @update:isOpen="closeUploadProductsModal"
    widthClass="w-90vw md:w-700px"
    title="Upload products"
    outlinedButtonLabel="Cancel"
    :outlinedButtonClick="closeUploadProductsModal"
    :containedButtonLabel="
      !uploadMessage ? 'Upload products' : 'Download Errors'
    "
    :containedButtonClick="!uploadMessage ? uploadProducts : downloadErrors"
    :isContainedButtonLoading="isUploading"
    :isContainedButtonDisabled="uploadFiles.length === 0"
    :enableDefaultFooter="bannerState !== 'success'"
  >
    <template v-slot:content="{ contentMaxHeight }">
      <div class="px-8 pt-4">
        <t-horizontal-tabs
          :activeTab="activeTab"
          :tabs="tabs"
          fullWidth="fullWidth"
        ></t-horizontal-tabs>
        <div
          class="overflow-y-auto pt-6 pb-1"
          ref="main"
          :style="{ maxHeight: contentMaxHeight - 99 + 'px' }"
        >
          <t-banner
            :show="successCount"
            type="success"
            :action="null"
            action-button-label="View upload status"
            title="Upload Success"
            containerClass="mb-6"
          >
            <p>
              {{ successCount }} product records successfully processed
            </p></t-banner
          >

          <t-banner
            :show="showBanner"
            :type="getBannerConfig().type"
            :action="getBannerConfig().action"
            action-button-label="View upload status"
            :title="getBannerConfig().title"
            containerClass="mb-6"
          >
            <p
              v-html="uploadMessage ? uploadMessage : getBannerConfig().message"
            ></p
          ></t-banner>

          <div
            class="px-1"
            v-if="
              activeTab === 'bulk_upload' && !uploadMessage && !uploadSuccessful
            "
          >
            <p class="inline">Add multiple products in the following steps.</p>
            <div class="pb-8 pt-6 border-b border-light-gray">
              <p class="text-dark-gray text-xs font-bold uppercase">step 1</p>
              <p class="mt-1 mb-3 font-bold">Need a MarketNation template?</p>
              <p class="inline">
                Select a category from the dropdown list to download the correct
                template to complete.
              </p>

              <div class="flex mt-4 fw">
                <t-tree-dropdown
                  id="category"
                  v-model="selectedTemplate"
                  name="category"
                  label="Select product category"
                  :options="categoryOptions"
                  :lowestLevel="true"
                  :key="categoryDropdownKey"
                  containerCssClass="w-full"
                ></t-tree-dropdown>
              </div>
              <div class="mt-3 text-right">
                <t-button
                  class="self-end"
                  color="primary"
                  variant="contained"
                  label="Email Template"
                  :isDisabled="!selectedTemplate"
                  @click="downloadTemplate"
                  id="download_template_button"
                ></t-button>
              </div>
            </div>
            <div class="pt-7">
              <p class="text-dark-gray text-xs font-bold uppercase">step 2</p>
              <p class="mt-1 mb-3 font-bold">
                Upload completed template (.csv)
              </p>
              <p class="inline">
                The products from your completed MarketNation template will be
                added to your catalog.
              </p>

              <div class="mt-7">
                <t-file-uploader
                  id="bulk_upload"
                  v-model="uploadFiles"
                  :file-types="['', 'text/csv', 'application/vnd.ms-excel']"
                  :multiple="false"
                  :key="fileUploaderKey"
                  v-if="!uploadMessage"
                ></t-file-uploader>

                <t-button
                  class="self-end"
                  color="primary"
                  variant="contained"
                  label="Email Errors"
                  :isDisabled="!selectedTemplate"
                  v-if="uploadMessage"
                  @click="downloadTemplate"
                  id="download_template_button"
                ></t-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </t-modal>
</template>
<script>
import StorageService from '@/services/storage.service';
import downloadOptions from '@/views/products/mixins/downloadOptions';
import getters from '@/views/products/mixins/getters';
import setters from '@/views/products/mixins/setters';
import utility from '@/mixins/utilityFunctions';
import axios from 'axios';

export default {
  name: 'BulkUploadModal',
  mixins: [downloadOptions, getters, setters, utility],
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    isCurrentTabAN: {
      type: Boolean,
      default: false
    }
  },
  emits: ['closeModal'],
  data() {
    return {
      activeTab: 'bulk_upload',
      tabs: [
        {
          name: 'New Product Bulk upload',
          code: 'bulk_upload'
        }
      ],
      categoryOptions: [
        { value: 'productOffer', label: 'Basic Content/Sku Setup' }
      ],
      selectedTemplate: null,
      uploadFiles: [],
      showBanner: false,
      bannerState: '',
      fileUploaderKey: 0,
      categoryDropdownKey: 0,
      isUploading: false,
      uploadMessage: null,
      successCount: 0,
      uploadSuccessful: false
    };
  },
  async created() {
    // this.setDownloadOptions();
    this.categoryDropdownKey++;
  },
  methods: {
    closeUploadProductsModal() {
      this.$emit('closeModal');
      StorageService.setItem('showBulkUploadModal', false);
    },
    viewUploadStatus() {
      this.closeUploadProductsModal();
      this.$nextTick(() => this.$router.push('/products/upload_status'));
    },
    async uploadProducts() {
      this.isUploading = true;
      this.uploadMessage = null;
      this.uploadSuccessful = false;

      let files = this.uploadFiles;

      for (let i = 0; i < files.length; i++) {
        files[i].state = 'pending';
        await this.$store
          .dispatch('product/BULK_UPLOAD_PRODUCTS', {
            file: files[i]
          })
          .then((response) => {
            this.handleUploadProductSuccess(files[i], response);
            this.uploadSuccessful = true;
          })
          .catch((response) => {
            this.handleUploadProductError(files[i], response);
            this.uploadMessage = JSON.stringify(
              response.response.data.message,
              null,
              2
            );
            this.uploadMessage = this.jsonEscape(this.uploadMessage);
            this.successCount = response.response.data.successful;
          })
          .finally(() => {
            if (i === files.length - 1) {
              setTimeout(() => this.afterUploadProducts(files), 1000);
            }
          });
      }
    },
    async downloadErrors() {
      const userEmail = this.$store.state.user.profile.email;

      this.uploadFiles[0].state = 'pending';
      this.isUploading = true;
      await this.$store
        .dispatch('product/DOWNLOAD_ERRORS', {
          file: this.uploadFiles[0],
          isProductImport: true
        })
        .then((response) => {
          this.$toastNotification.success(
            `The errors has been emailed to ${userEmail}`
          );
        })
        .catch((response) => {
          this.handleDownloadError();
        });
      this.isUploading = false;
    },
    jsonEscape(str) {
      return str.replace(/\\n/g, '<br>').replace(/[{}]/g, '');
    },
    afterUploadProducts(files) {
      if (files.every((file) => file.state === 'error')) {
        this.bannerState = 'error';
      } else {
        this.bannerState = 'success';
      }
      this.showBanner = true;
      this.isUploading = false;
      // this.fileUploaderKey++;
      this.$refs.main.scrollTo({ top: 0, behavior: 'smooth' });
    },
    handleUploadProductSuccess(file, response) {
      if (!file) {
        return false;
      }
      if (axios.isCancel(response)) {
        file.state = 'error';
      } else {
        file.state = 'success';
      }
    },
    handleUploadProductError(file) {
      if (!file) {
        return false;
      }
      file.state = 'error';
    },
    downloadTemplate() {
      const userEmail = this.$store.state.user.profile.email;
      this.$store
        .dispatch('product/EXPORT_TEMPLATE', this.selectedTemplate)
        .then((resp) => {
          this.$toastNotification.success(
            `The template has been emailed to ${userEmail}`
          );
        })
        .catch((resp) => {
          this.handleDownloadError();
        });
    },
    handleDownloadError() {
      this.$toastNotification.error(
        'Download could not start, please try again later'
      );
    },
    getBannerConfig() {
      switch (this.bannerState) {
        case 'success':
          return {
            title: 'Upload Successful',
            message: 'All files have been successfully uploaded',
            type: 'success',
            action: null
          };
        case 'error':
          return {
            title: 'Upload failed',
            message: 'Files could not be uploaded, please try again later.',
            type: 'warning',
            action: null
          };
        default:
          return {
            title: '',
            message: '',
            type: 'warning',
            action: null
          };
      }
    }
  }
};
</script>
