import { IInput } from '@/types/interfaces/product/margin-stack/margin-stack';

export function useValueTypes() {
  const isInputDate = (input: IInput) => {
    return input?.opportunityValue.type === 'DATE';
  };

  const isInputDecimal = (input: IInput) => {
    return input?.opportunityValue.type === 'DOUBLE';
  };

  const isInputPriceTypeMoney = (input: IInput) => {
    return input?.priceType === 'MONEY';
  };

  const isInputPriceTypePercentage = (input: IInput) => {
    return input?.priceType === 'PERCENT';
  };

  return {
    isInputDate,
    isInputDecimal,
    isInputPriceTypeMoney,
    isInputPriceTypePercentage
  };
}
