const mixin = {
  methods: {
    getProductCategoriesFromBackend() {
      return this.$store
        .dispatch('category/GET_CATEGORIES_FROM_BACKEND', {
          hierarchy: true
        })
        .then((data) => {
          this.setCategoryDropdownOptions(data);
        })
        .catch(() => {
          if (this.$route?.path !== '/products') this.$router.push('/products');
        });
    },

    getManufacturersFromBackend() {
      return this.$store
        .dispatch('product/GET_MANUFACTURERS_FROM_BACKEND')
        .then((data) => {
          this.setManufacturerOptions(data);
        })
        .catch(() => {
          if (this.$route?.path !== '/products') this.$router.push('/products');
        });
    },

    getProductCategoryDetail() {
      return this.$store
        .dispatch(
          'category/GET_CATEGORY_DETAILS_FROM_BACKEND',
          this.formData.basicInfo.categoryId
        )
        .catch(() => {
          if (this.$route?.path !== '/products') this.$router.push('/products');
        });
    }
  }
};

export default mixin;
