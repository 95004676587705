import { cloneDeep } from 'lodash';
import { watch, computed } from 'vue';
import { useStore } from 'vuex';
import { MARGIN_STACK } from '@/types/constants';
import {
  IInput,
  IRebate
} from '@/types/interfaces/product/margin-stack/margin-stack';

export function useDiscardChanges(
  props: { inputs: IInput[] },
  emits: (arg0: string, arg1: any) => void,
  originalInputs: { value: IInput[] },
  originalRebate: { value: IRebate | null }
) {
  const store = useStore();

  const discardInputs = () => {
    props.inputs.length = originalInputs.value.length;
    for (let i = 0; i < props.inputs.length; i++) {
      props.inputs[i] = cloneDeep(originalInputs.value[i]);
    }
  };

  const discardRebate = () => {
    emits('update:rebate', originalRebate.value);
  };

  const discardChanges = () => {
    discardInputs();
    discardRebate();
  };

  const discardChangesTrigger = computed(() => {
    return store.getters[
      'product/marginStack/' + MARGIN_STACK.GETTERS.DISCARD_CHANGES_TRIGGER
    ];
  });

  watch(discardChangesTrigger, (value) => {
    if (!value) {
      return;
    }
    discardChanges();
  });

  return { discardChanges };
}
