import {
  OrderItemStatusType,
  OrderStatusType
} from '@/types/enums/store/order/order';
import { UploadStatus } from '@/types/enums/store/product/grids/upload-status';

export function useStatuses() {
  const orderStatusOptions = [
    { value: OrderStatusType.CREATED, label: 'Created' },
    { value: OrderStatusType.ACKNOWLEDGED, label: 'Acknowledged' },
    { value: OrderStatusType.PARTIALLY_SHIPPED, label: 'Partially Shipped' },
    {
      value: OrderStatusType.PARTIALLY_DELIVERED,
      label: 'Partially Delivered'
    },
    { value: OrderStatusType.PARTIALLY_CANCELLED, label: 'Partially Canceled' },
    { value: OrderStatusType.CANCELLED, label: 'Cancelled' },
    { value: OrderStatusType.SHIPPED, label: 'Shipped' },
    { value: OrderStatusType.DELIVERED, label: 'Delivered' },
    { value: OrderStatusType.COMPLETE, label: 'Complete' },
    { value: OrderStatusType.ERROR, label: 'Error' },
    { value: OrderStatusType.PARTIALLY_CREATED, label: 'Partially Created' },
    { value: OrderStatusType.SUBMITTED, label: 'Submitted' },
    { value: OrderStatusType.SUBMISSION_ERROR, label: 'Submission Error' }
  ];

  const orderItemStatusOptions = [
    {
      value: OrderItemStatusType.NEW,
      label: 'Acknowledged'
    },
    {
      value: OrderItemStatusType.SHIPPED,
      label: 'Shipped'
    },
    {
      value: OrderItemStatusType.DELIVERED,
      label: 'Delivered'
    },
    {
      value: OrderItemStatusType.RETURN,
      label: 'Return'
    },
    {
      value: OrderItemStatusType.CANCELLED,
      label: 'Cancelled'
    }
  ];

  const uploadStatusOptions = [
    { value: UploadStatus.PENDING, label: 'Pending' },
    { value: UploadStatus.COMPLETED, label: 'Processed' },
    { value: UploadStatus.FAILED, label: 'Failed' }
  ];

  return {
    orderStatusOptions,
    orderItemStatusOptions,
    uploadStatusOptions
  };
}
