export enum ProductContentGetters {
  GET_BRAND_ID = 'GET_BRAND_ID',
  GET_CATEGORY_ID = 'GET_CATEGORY_ID',
  GET_MSRP = 'GET_MSRP',
  IS_CATEGORY_ATTRIBUTES_ACCORDION_VISIBLE = 'IS_CATEGORY_ATTRIBUTES_ACCORDION_VISIBLE',
  SAVED_DATA_NOT_PUBLISHED = "SAVED_DATA_NOT_PUBLISHED"
}
export enum ProductContentMutations {
  SET_IS_EDITING_BASIC_PRODUCT_INFO = 'SET_IS_EDITING_BASIC_PRODUCT_INFO',
  SET_IS_LOADING_BASIC_PRODUCT_INFO = 'SET_IS_LOADING_BASIC_PRODUCT_INFO',
  SET_BASIC_PRODUCT_INFO = 'SET_BASIC_PRODUCT_INFO',
  SET_ORIGINAL_BASIC_PRODUCT_INFO = 'SET_ORIGINAL_BASIC_PRODUCT_INFO',
  SET_BASIC_PRODUCT_INFO_ERRORS = 'SET_BASIC_PRODUCT_INFO_ERRORS',

  SET_IS_EDITING_CATEGORY_ATTRIBUTES = 'SET_IS_EDITING_CATEGORY_ATTRIBUTES',
  SET_IS_LOADING_CATEGORY_ATTRIBUTES = 'SET_IS_LOADING_CATEGORY_ATTRIBUTES',
  SET_CATEGORY_ATTRIBUTES = 'SET_CATEGORY_ATTRIBUTES',
  SET_ORIGINAL_CATEGORY_ATTRIBUTES = 'SET_ORIGINAL_CATEGORY_ATTRIBUTES',
  SET_CATEGORY_ATTRIBUTES_ERRORS = 'SET_CATEGORY_ATTRIBUTES_ERRORS',
  SET_IS_CATEGORY_ATTRIBUTES_ACCORDION_VISIBLE = 'SET_IS_CATEGORY_ATTRIBUTES_ACCORDION_VISIBLE',

  SET_IS_EDITING_DESCRIPTION = 'SET_IS_EDITING_DESCRIPTION',
  SET_IS_LOADING_DESCRIPTION = 'SET_IS_LOADING_DESCRIPTION',
  SET_DESCRIPTION = 'SET_DESCRIPTION',
  SET_ORIGINAL_DESCRIPTION = 'SET_ORIGINAL_DESCRIPTION',
  SET_DESCRIPTION_ERRORS = 'SET_DESCRIPTION_ERRORS',

  SET_IS_EDITING_DIMENSIONS = 'SET_IS_EDITING_DIMENSIONS',
  SET_IS_LOADING_DIMENSIONS = 'SET_IS_LOADING_DIMENSIONS',
  SET_DIMENSIONS = 'SET_DIMENSIONS',
  SET_ORIGINAL_DIMENSIONS = 'SET_ORIGINAL_DIMENSIONS',
  SET_DIMENSIONS_ERRORS = 'SET_DIMENSIONS_ERRORS',

  SET_IS_EDITING_MEDIA = 'SET_IS_EDITING_MEDIA',
  SET_IS_LOADING_MEDIA = 'SET_IS_LOADING_MEDIA',
  SET_MEDIA = 'SET_MEDIA',
  SET_ORIGINAL_MEDIA = 'SET_ORIGINAL_MEDIA',
  SET_MEDIA_ERRORS = 'SET_MEDIA_ERRORS'
}
export enum ProductContentActions {
  GET_BASIC_PRODUCT_INFO = 'GET_BASIC_PRODUCT_INFO',
  GET_CATEGORY_ATTRIBUTES = 'GET_CATEGORY_ATTRIBUTES',
  GET_DESCRIPTION = 'GET_DESCRIPTION',
  GET_DIMENSIONS = 'GET_DIMENSIONS',
  GET_MEDIA = 'GET_MEDIA'
}
