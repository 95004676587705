import ApiService from '@/services/api.service';
import helper from './helper';
import gridQuery from '../../utility/gridQuery';
import { upperFirst, cloneDeep } from 'lodash';
import basicProductInfo from './accordions/basic-product-info';
import media from './accordions/media';
import description from './accordions/description';
import dimensions from './accordions/dimensions';
import categoryAttributes from './accordions/category-attributes';
import updateQueue from './grids/update-queue';
import uploadStatus from './grids/upload-status';
import productsGrid from './grids/products';
import marginStack from './margin-stack/index';
import offer from './offer/index';
import axios from 'axios';
import {
  IProductIndexState,
  IProductIndexActions,
  IProductIndexGetters,
  ProductIndexMutationsTypes
} from '@/types/interfaces/store/product/product';
import { IProductState } from '@/types/interfaces/store/product';
import { PRODUCT, PD_CONT } from '@/types/constants';
import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { AxiosError, AxiosResponse } from 'axios';
import { IState } from '@/types/interfaces/store';
import { IProgressEvent } from '@/types/interfaces/product';

export const state: IProductIndexState = {
  productId: null,
  isAddingNewProduct: false,
  isCreatingProduct: false,
  isUpdatingProduct: false,

  manufacturers: [],
  isLoadingManufacturers: false,

  offersList: [],
  isLoadingOffersList: false,

  accordionNames: [
    'basicProductInfo',
    'categoryAttributes',
    'description',
    'dimensions',
    'media'
  ],

  accordionNamesOnBackend: {
    basicProductInfo: 'basicInfo',
    categoryAttributes: 'productCategoryAttributes',
    description: 'description',
    dimensions: 'dimensions',
    media: 'media'
  },

  accordionActionsNames: [
    'BASIC_PRODUCT_INFO',
    'CATEGORY_ATTRIBUTES',
    'DESCRIPTION',
    'DIMENSIONS',
    'MEDIA'
  ],

  accordionNamesAndActions: {
    basicProductInfo: 'BASIC_PRODUCT_INFO',
    categoryAttributes: 'CATEGORY_ATTRIBUTES',
    description: 'DESCRIPTION',
    dimensions: 'DIMENSIONS',
    media: 'MEDIA'
  },

  ...basicProductInfo.state,
  ...media.state,
  ...description.state,
  ...categoryAttributes.state,
  ...dimensions.state,
  ...updateQueue.state,
  ...uploadStatus.state,
  ...productsGrid.state
};

export const getters: GetterTree<IProductState, IProductState> &
  IProductIndexGetters = {
  [PRODUCT.GETTERS.GET_PRODUCT_CONTENT_DATA_FOR_REQUEST]: (state) => (
    onlyEdited: boolean
  ) => {
    const data = {};

    state.accordionNames.forEach((name) => {
      // @ts-ignore
      if (onlyEdited && !state['isEditing' + upperFirst(name)]) {
        return;
      }

      if (name === 'media') {
        // @ts-ignore
        data[state.accordionNamesOnBackend[name]] = cloneDeep(
          state[name]?.images
        );
        return;
      }

      // @ts-ignore
      data[state.accordionNamesOnBackend[name]] = state[name];
    });

    return data;
  },

  [PRODUCT.GETTERS.GET_MANUFACTURER_OPTIONS]: (state) => {
    return state.manufacturers?.map((item) => {
      return {
        value: item.id,
        label: item.name
      };
    });
  },

  [PRODUCT.GETTERS.GET_OFFERS_OPTIONS]: (state) => {
    const offersOptions = state.offersList?.map((item) => {
      return {
        value: item.id,
        label: item.sku
      };
    });

    offersOptions.push({
      value: 'new',
      label: '+ Add offer'
    });

    return offersOptions;
  },

  [PRODUCT.GETTERS.GET_ACCORDION_PRODUCT_ERRORS]: (
    state,
    getters,
    rootState
  ) => (accordionName) => {
    const accordionErrors = cloneDeep(state[accordionName + 'Errors']);
    if (!accordionErrors) {
      return null;
    }

    Object.keys(accordionErrors).forEach((fieldName) => {
      const errorCode = accordionErrors[fieldName];
      if (!errorCode) {
        return;
      }

      // @ts-ignore
      const errorMessage: string = rootState.$t(
        'productError.' + accordionName + '.' + fieldName + '.' + errorCode
      );
      accordionErrors[fieldName] = errorMessage || '';
    });

    return accordionErrors;
  },

  [PRODUCT.GETTERS.IS_EDITING_PRODUCT]: (state) => {
    return state.accordionNames.some((name) => {
      return state['isEditing' + upperFirst(name)];
    });
  },

  [PRODUCT.GETTERS.IS_LOADING_PRODUCT]: (state) => {
    return state.accordionNames.some((name) => {
      return state['isLoading' + upperFirst(name)];
    });
  },

  ...basicProductInfo.getters,
  ...categoryAttributes.getters,
  ...updateQueue.getters,
  ...uploadStatus.getters,
  ...productsGrid.getters
};

export const actions: ActionTree<IProductState, IState> &
  IProductIndexActions = {
  [PRODUCT.ACTIONS.GET_PRODUCT_CONTENT]({ dispatch, state, commit }) {
    if (state.isAddingNewProduct) {
      dispatch(PRODUCT.ACTIONS.SET_EMPTY_PRODUCT_DATA);
      // @ts-ignore
      commit(PRODUCT.MUTATIONS.CLEAR_OFFERS_LIST);
      return;
    }
  },

  [PRODUCT.ACTIONS.CREATE_PRODUCT_CONTENT]({ dispatch, commit, getters }) {
    return new Promise((resolve, reject) => {
      commit(PRODUCT.MUTATIONS.SET_IS_CREATING_PRODUCT, true);

      const url = process.env.VUE_APP_CATALOG_SERVICE + '/products';
      const data = getters.GET_PRODUCT_CONTENT_DATA_FOR_REQUEST();

      ApiService.post(url, data)
        .then((response: AxiosResponse) => {
          commit(PRODUCT.MUTATIONS.SET_IS_CREATING_PRODUCT, false);
          commit(PD_CONT.MUTATIONS.SET_IS_EDITING_BASIC_PRODUCT_INFO, false);
          resolve(response);
        })
        .catch((error: AxiosError<any>) => {
          const errors = error?.response?.data?.message;
          commit(PRODUCT.MUTATIONS.SET_IS_CREATING_PRODUCT, false);
          dispatch(PRODUCT.ACTIONS.SET_PRODUCT_ERRORS, errors);
          reject(error);
        });
    });
  },

  [PRODUCT.ACTIONS.UPDATE_PRODUCT_CONTENT]({
    dispatch,
    commit,
    state,
    getters
  }) {
    return new Promise((resolve, reject) => {
      commit(PRODUCT.MUTATIONS.SET_IS_UPDATING_PRODUCT, true);

      const productId = state.productId;
      const url =
        process.env.VUE_APP_CATALOG_SERVICE + '/products/' + productId;
      const data = getters.GET_PRODUCT_CONTENT_DATA_FOR_REQUEST(true);
      ApiService.put(url, data)
        .then((response: AxiosResponse) => {
          commit(PRODUCT.MUTATIONS.SET_IS_UPDATING_PRODUCT, false);
          dispatch(PRODUCT.ACTIONS.UPDATE_ORIGINAL_PRODUCT_DATA);
          resolve(response);
        })
        .catch((error: AxiosError<any>) => {
          const errors = error?.response?.data?.message;
          commit(PRODUCT.MUTATIONS.SET_IS_UPDATING_PRODUCT, false);
          dispatch(PRODUCT.ACTIONS.SET_PRODUCT_ERRORS, errors);
          reject(error);
        });
    });
  },

  [PRODUCT.ACTIONS.GET_OFFERS_LIST]({ commit, state }) {
    if (state.isAddingNewProduct) {
      return;
    }

    return new Promise((resolve, reject) => {
      commit(PRODUCT.MUTATIONS.SET_IS_LOADING_OFFERS_LIST, true);

      const url =
        process.env.VUE_APP_CATALOG_SERVICE +
        '/products/' +
        state.productId +
        '/offers';

      ApiService.get(url)
        .then((response: AxiosResponse) => {
          commit(PRODUCT.MUTATIONS.SET_IS_LOADING_OFFERS_LIST, false);
          commit(PRODUCT.MUTATIONS.SET_OFFERS_LIST, response.data);
          resolve(response);
        })
        .catch((error: AxiosError) => {
          commit(PRODUCT.MUTATIONS.SET_IS_LOADING_OFFERS_LIST, false);
          reject(error);
        });
    });
  },

  [PRODUCT.ACTIONS.GET_MANUFACTURERS_FROM_BACKEND]({ commit }) {
    commit(PRODUCT.MUTATIONS.SET_IS_LOADING_MANUFACTURERS, true);
    return new Promise((resolve, reject) => {
      const url = process.env.VUE_APP_CATALOG_SERVICE + '/manufacturers';

      ApiService.get(url)
        .then((response: AxiosResponse) => {
          const manufacturers = response.data.manufacturers;
          commit(PRODUCT.MUTATIONS.SET_IS_LOADING_MANUFACTURERS, false);
          commit(PRODUCT.MUTATIONS.SET_MANUFACTURERS, manufacturers);
          resolve(manufacturers);
        })
        .catch((error: AxiosError) => {
          commit(PRODUCT.MUTATIONS.SET_IS_LOADING_MANUFACTURERS, false);
          reject(error);
        });
    });
  },

  [PRODUCT.ACTIONS.EXPORT_PRODUCTS]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const url = process.env.VUE_APP_CATALOG_SERVICE + '/products/export/productOffer';

      ApiService.post(url, payload)
        .then((response: AxiosResponse) => {
          resolve(response);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },


  [PRODUCT.ACTIONS.EXPORT_RECORDS]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const url = process.env.VUE_APP_CATALOG_SERVICE + '/products/export/productMarginStack';

      ApiService.post(url, payload)
        .then((response: AxiosResponse) => {
          resolve(response);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },


  [PRODUCT.ACTIONS.EXPORT_TEMPLATE]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const url =
        process.env.VUE_APP_CATALOG_SERVICE +
        '/products/import/template/' +
        payload;

      ApiService.post(url)
        .then((response: AxiosResponse) => {
          resolve(response);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },

  [PRODUCT.ACTIONS.BULK_UPLOAD_PRODUCTS]({ commit }, { file }) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append('file', file.data);
      const data = formData;
      const url =
        process.env.VUE_APP_CATALOG_SERVICE + '/products/import/UPLOAD/productOffer';

      const cancelToken = axios.CancelToken;
      const cancelTokenSource = cancelToken.source();
      file.cancelTokenSource = cancelTokenSource;

      const config = {
        onUploadProgress: (progressEvent: IProgressEvent) => {
          file.progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        },
        cancelToken: cancelTokenSource.token
      };


      ApiService.post(url, data, config)
        .then((response: AxiosResponse) => {
          resolve(response);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },

  [PRODUCT.ACTIONS.DOWNLOAD_ERRORS]({ commit }, { file, isProductImport }) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append('file', file.data);
      formData.append('productImport', isProductImport);
      const data = formData;
      const url =
        process.env.VUE_APP_CATALOG_SERVICE + '/products/import/errors/download';


      ApiService.post(url, formData)
        .then((response: AxiosResponse) => {
          resolve(response);
        })
        .catch((error: AxiosError) => {
          reject(error);

        });
    });
  },

  [PRODUCT.ACTIONS.IMPORT_PRODUCTS]({ commit }, { type, file }) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append('file', file.data);
      const data = formData;
      const url =
        process.env.VUE_APP_CATALOG_SERVICE + '/products/import/' + type;

      const cancelToken = axios.CancelToken;
      const cancelTokenSource = cancelToken.source();
      file.cancelTokenSource = cancelTokenSource;

      const config = {
        onUploadProgress: (progressEvent: IProgressEvent) => {
          file.progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        },
        cancelToken: cancelTokenSource.token
      };

      ApiService.post(url, data, config)
        .then((response: AxiosResponse) => {
          resolve(response);
        })
        .catch((error: AxiosError) => {
          if (axios.isCancel(error)) {
            resolve(error);
          } else {
            reject(error);
          }
        });
    });
  },

  [PRODUCT.ACTIONS.PUBLISH_MARKETPLACE](
    { commit },
    { marketplaceId, offerId, productId }
  ) {
    return new Promise((resolve, reject) => {
      const url =
        process.env.VUE_APP_CATALOG_SERVICE +
        '/products/' +
        productId +
        '/offers/' +
        offerId +
        '/marketplace/' +
        marketplaceId +
        '/publish';

      ApiService.put(url)
        .then((response: AxiosResponse) => {
          resolve(response);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },

  [PRODUCT.ACTIONS.PUBLISH_MARKETPLACE_ALL](
    { commit },
    offerId
  ) {
    return new Promise((resolve, reject) => {
      const url =
        process.env.VUE_APP_CATALOG_SERVICE +
        '/offers/' +
        offerId +
        '/publish_marketplace_all'

      ApiService.put(url)
        .then((response: AxiosResponse) => {
          resolve(response);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },


  [PRODUCT.ACTIONS.UNPUBLISH_MARKETPLACE](
    { commit },
    { marketplaceId, offerId, productId }
  ) {
    return new Promise((resolve, reject) => {
      const url =
        process.env.VUE_APP_CATALOG_SERVICE +
        '/products/' +
        productId +
        '/offers/' +
        offerId +
        '/marketplace/' +
        marketplaceId +
        '/unpublish';

      ApiService.put(url)
        .then((response: AxiosResponse) => {
          resolve(response);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },

  [PRODUCT.ACTIONS.DISCARD_CHANGES_PRODUCT]({ commit, state }) {
    state.accordionNames.forEach((name) => {
      const originalName = 'original' + upperFirst(name);
      commit(PRODUCT.MUTATIONS.DISCARD_CHANGES_IN_ACCORDION_PRODUCT, {
        name,
        originalName
      });
    });
  },

  [PRODUCT.ACTIONS.CLEAR_PRODUCT_DATA]({ state, commit }) {
    state.accordionNames.forEach((name) => {
      const originalName = 'original' + upperFirst(name);
      commit(PRODUCT.MUTATIONS.CLEAR_ACCORDION_PRODUCT_DATA, {
        name,
        originalName
      });
    });
  },

  [PRODUCT.ACTIONS.SET_EMPTY_PRODUCT_DATA]({ state, commit }) {
    state.accordionNames.forEach((name) => {
      const originalName = 'original' + upperFirst(name);
      commit(PRODUCT.MUTATIONS.SET_EMPTY_ACCORDION_PRODUCT_DATA, {
        name,
        originalName
      });
    });
  },

  [PRODUCT.ACTIONS.UPDATE_ORIGINAL_PRODUCT_DATA]({ state, commit }) {
    for (const key in state.accordionNamesAndActions) {
      const data = state[key];
      // @ts-ignore
      commit('SET_ORIGINAL_' + state.accordionNamesAndActions[key], data);
    }
  },

  [PRODUCT.ACTIONS.SET_PRODUCT_ERRORS]({ state, commit }, errors) {
    for (const key in state.accordionNamesAndActions) {
      const accordionErrors = errors[state.accordionNamesOnBackend[key]];

      commit(
        // @ts-ignore
        'SET_' + state.accordionNamesAndActions[key] + '_ERRORS',
        accordionErrors
      );
    }
  },

  ...basicProductInfo.actions,
  ...media.actions,
  ...description.actions,
  ...categoryAttributes.actions,
  ...dimensions.actions,
  ...updateQueue.actions,
  ...uploadStatus.actions,
  ...productsGrid.actions
};

const mutations: MutationTree<IProductState> & ProductIndexMutationsTypes = {
  [PRODUCT.MUTATIONS.SET_IS_CREATING_PRODUCT](state, value) {
    state.isCreatingProduct = value;
  },

  [PRODUCT.MUTATIONS.SET_IS_UPDATING_PRODUCT](state, value) {
    state.isUpdatingProduct = value;
  },

  [PRODUCT.MUTATIONS.SET_IS_LOADING_OFFERS_LIST](state, value) {
    state.isLoadingOffersList = value;
  },

  [PRODUCT.MUTATIONS.SET_OFFERS_LIST](state, data) {
    state.offersList = data;
  },

  [PRODUCT.MUTATIONS.CLEAR_OFFERS_LIST](state) {
    state.offersList = [];
  },

  [PRODUCT.MUTATIONS.SET_IS_LOADING_MANUFACTURERS](state, value) {
    state.isLoadingManufacturers = value;
  },

  [PRODUCT.MUTATIONS.SET_MANUFACTURERS](state, data) {
    state.manufacturers = data;
  },

  [PRODUCT.MUTATIONS.SET_IS_ADDING_NEW_PRODUCT](state, value) {
    state.isAddingNewProduct = value;
  },

  [PRODUCT.MUTATIONS.SET_PRODUCT_ID](state, value) {
    state.productId = value;
  },

  [PRODUCT.MUTATIONS.CLEAR_ERRORS_OBJECT](state) {
    state.accordionNames.forEach((name) => {
      state[name + 'Errors'] = null;
    });
  },

  [PRODUCT.MUTATIONS.DISCARD_CHANGES_IN_ACCORDION_PRODUCT](
    state,
    { name, originalName }
  ) {
    const data = state[name];
    const originalData = state[originalName];
    Object.keys(data).forEach((key) => {
      data[key] = cloneDeep(originalData[key]);
    });
  },

  [PRODUCT.MUTATIONS.CLEAR_ACCORDION_PRODUCT_DATA](
    state,
    { name, originalName }
  ) {
    state[name] = null;
    state[originalName] = null;
  },

  [PRODUCT.MUTATIONS.SET_EMPTY_ACCORDION_PRODUCT_DATA](
    state,
    { name, originalName }
  ) {
    const emptyDataName = 'empty' + upperFirst(name);
    state[name] = cloneDeep(state[emptyDataName]);
    state[originalName] = cloneDeep(state[emptyDataName]);
  },

  [PRODUCT.MUTATIONS.CLEAR_FIELD_PRODUCT_ERROR](
    state,
    { accordionName, fieldName }
  ) {
    const accordionErrors = state[accordionName + 'Errors'];
    if (!(accordionErrors && accordionErrors[fieldName])) {
      return;
    }
    state[accordionName + 'Errors'][fieldName] = null;
  },

  ...basicProductInfo.mutations,
  ...media.mutations,
  ...description.mutations,
  ...categoryAttributes.mutations,
  ...dimensions.mutations,
  ...updateQueue.mutations,
  ...uploadStatus.mutations,
  ...productsGrid.mutations
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: { marginStack, offer }
};
