export function useMarketplaceStatus(marketplaceStatus: string) {
  const getMarketplaceStatusLabel = () => {
    switch (marketplaceStatus) {
      case 'active':
        return 'Active';
      case 'pending_publish':
        return 'Pending publish';
      case 'publish_failed':
        return 'Publish failed';
      case 'unpublished':
        return 'Unpublished';
      case 'pending_unpublish':
        return 'Pending unpublish';
      case 'unpublish_failed':
        return 'Unpublish failed';
      case 'published':
        return 'Published';
      case 'publish_inventory_hold':
        return 'Active';
      default:
        return 'Inactive';
    }
  };

  return {
    getMarketplaceStatusLabel
  };
}
