import ApiService from '@/services/api.service';
import {
  IMarginStackState,
  IMarginStackGetters,
  IMarginStackActions,
  MarginStackMutationsTypes
} from '@/types/interfaces/store/product/margin-stack/margin-stack';
import {
  IApproveSection,
  IMarketplace,
  IProposal,
  ISendProposal,
  MarginStackMarketplace
} from '@/types/interfaces/product/margin-stack/margin-stack';
import { MARGIN_STACK } from '@/types/constants';
import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { AxiosError, AxiosResponse } from 'axios';
import { IState } from '@/types/interfaces/store';

export const state: IMarginStackState = {
  isEditing: {},
  isProposing: {},
  isUpdating: {},
  isSaving: false,
  isLoading: false,
  discardChangesTrigger: false,
  savedDataNotPublished: false,
  containerScrollTop: 0
};

export const getters: GetterTree<IMarginStackState, IMarginStackState> &
  IMarginStackGetters = {
  [MARGIN_STACK.GETTERS.IS_EDITING_MARGIN_STACK]: (
    state: IMarginStackState
  ): boolean => {
    return Object.values(state.isEditing).includes(true);
  },

  [MARGIN_STACK.GETTERS.IS_PROPOSING_MARGIN_STACK]: (
    state: IMarginStackState
  ): boolean => {
    return Object.values(state.isProposing).includes(true);
  },

  [MARGIN_STACK.GETTERS.IS_UPDATING_MARGIN_STACK]: (
    state: IMarginStackState
  ): boolean => {
    return Object.values(state.isUpdating).includes(true);
  },


  [MARGIN_STACK.GETTERS.SAVED_DATA_NOT_PUBLISHED]: (
    state: IMarginStackState
  ): boolean => {
    return state.savedDataNotPublished
  },

  [MARGIN_STACK.GETTERS.DISCARD_CHANGES_TRIGGER]: (
    state: IMarginStackState
  ): boolean => state.discardChangesTrigger,

  [MARGIN_STACK.GETTERS.IS_LOADING]: (state: IMarginStackState): boolean =>
    state.isLoading,

  [MARGIN_STACK.GETTERS.CONTAINER_SCROLL_TOP]: (
    state: IMarginStackState
  ): number => state.containerScrollTop
};

export const actions: ActionTree<IMarginStackState, IState> &
  IMarginStackActions = {
  [MARGIN_STACK.ACTIONS.GET_MARGIN_STACK_MARKETPLACE](
    { commit, rootState }: { commit: any; rootState: any },
    storefrontId: number
  ) {
    const productId = rootState.product.productId;
    const offerId = rootState.product.offer.offerId;
    return new Promise((resolve, reject) => {
      const url =
        process.env.VUE_APP_CATALOG_SERVICE +
        '/productoffers/' +
        offerId +
        '/marginstack/' +
        storefrontId;

      ApiService.get(url)
        .then((response: AxiosResponse<IMarketplace>) => {
          resolve(response.data);
        })

        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },

  [MARGIN_STACK.ACTIONS.GET_PUBLISHED_MARGIN_STACK_MARKETPLACE](
    { commit, rootState }: { commit: any; rootState: any },
    storefrontId: number
  ) {
    const productId = rootState.product.productId;
    const offerId = rootState.product.offer.offerId;
    return new Promise((resolve, reject) => {
      const url =
        process.env.VUE_APP_CATALOG_SERVICE +
        '/productoffers/' +
        offerId +
        '/marginstack/' +
        storefrontId +
        '/published';

      ApiService.get(url)
        .then((response: AxiosResponse<IMarketplace>) => {
          resolve(response.data);
        })

        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },

  [MARGIN_STACK.ACTIONS.UPDATE_MARGIN_STACK](
    { commit, rootState }: { commit: any; rootState: any },
    data: IMarketplace[]
  ) {
    const productId = rootState.product.productId;
    const offerId = rootState.product.offer.offerId;

    return new Promise((resolve, reject) => {
      const url =
        process.env.VUE_APP_CATALOG_SERVICE +
        '/productoffers/' +
        offerId +
        '/marginstack';

      ApiService.put(url, data)
        .then((response: AxiosResponse) => {
          resolve(response);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },

  [MARGIN_STACK.ACTIONS.CLEAR_PROPOSALS](
    { commit, rootState }: { commit: any; rootState: any },
    {
      storefrontId,
      proposalIds
    }: { storefrontId: number; proposalIds: number[] }
  ) {
    const productId = rootState.product.productId;
    const offerId = rootState.product.offer.offerId;

    return new Promise((resolve, reject) => {
      const url =
        process.env.VUE_APP_CATALOG_SERVICE +
        '/products/' +
        productId +
        '/offers/' +
        offerId +
        '/marginstack/' +
        storefrontId +
        '/proposal/clean';

      ApiService.patch(url, proposalIds)
        .then((response: AxiosResponse) => {
          resolve(response);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },

  [MARGIN_STACK.ACTIONS.SEND_PROPOSALS](
    { commit, rootState }: { commit: any; rootState: any },
    {
      storefrontId,
      proposals
    }: { storefrontId: number; proposals: ISendProposal }
  ) {
    const productId = rootState.product.productId;
    const offerId = rootState.product.offer.offerId;

    return new Promise((resolve, reject) => {
      const url =
        process.env.VUE_APP_CATALOG_SERVICE +
        '/products/' +
        productId +
        '/offers/' +
        offerId +
        '/marginstack/' +
        storefrontId +
        '/proposal';

      ApiService.post(url, proposals)
        .then((response: AxiosResponse) => {
          resolve(response);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },

  [MARGIN_STACK.ACTIONS.APPROVE_SECTION](
    { commit, rootState }: { commit: any; rootState: any },
    {
      storefrontId,
      sectionType
    }: { storefrontId: number; sectionType: IApproveSection }
  ) {
    const offerId = rootState.product.offer.offerId;

    return new Promise((resolve, reject) => {
      const url =
        process.env.VUE_APP_CATALOG_SERVICE +
        '/offers/' +
        offerId +
        '/marginstack/' +
        storefrontId +
        '/approve-section';

      ApiService.patch(url, { sectionType })
        .then((response: AxiosResponse) => {
          resolve(response.data);
        })

        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },

  [MARGIN_STACK.ACTIONS.FILL_MARGIN_STACK_MARKETPLACE]() {
    return new Promise((resolve, reject) => {
      const url =
        process.env.VUE_APP_CATALOG_SERVICE +
        '/products/offers/fillmarginstack';

      ApiService.post(url)
        .then((response: AxiosResponse) => {
          resolve(response);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }
};

const mutations: MutationTree<IMarginStackState> & MarginStackMutationsTypes = {
  [MARGIN_STACK.MUTATIONS.SET_IS_EDITING_MARKETPLACE](
    state: IMarginStackState,
    { storefrontId, value }: { storefrontId: number; value: boolean }
  ) {
    state.isEditing[storefrontId] = value;
  },

  [MARGIN_STACK.MUTATIONS.SET_IS_EDITING](
    state: IMarginStackState,
    value: MarginStackMarketplace
  ) {
    state.isEditing = value;
  },

  [MARGIN_STACK.MUTATIONS.SET_IS_PROPOSING_MARKETPLACE](
    state: IMarginStackState,
    {
      storefrontId,
      value
    }: { storefrontId: number; value: MarginStackMarketplace }
  ) {
    state.isProposing[storefrontId] = value;
  },

  [MARGIN_STACK.MUTATIONS.SET_IS_UPDATING_MARKETPLACE](
    state: IMarginStackState,
    {
      storefrontId,
      value
    }: { storefrontId: number; value: MarginStackMarketplace }
  ) {
    state.isUpdating[storefrontId] = value;
  },

  [MARGIN_STACK.MUTATIONS.SET_IS_SAVING](
    state: IMarginStackState,
    value: boolean
  ) {
    state.isSaving = value;
  },

  [MARGIN_STACK.MUTATIONS.SET_DISCARD_CHANGES_TRIGGER](
    state: IMarginStackState,
    value: boolean
  ) {
    state.discardChangesTrigger = value;
  },

  [MARGIN_STACK.MUTATIONS.SET_IS_LOADING](
    state: IMarginStackState,
    value: boolean
  ) {
    state.isLoading = value;
  },

  [MARGIN_STACK.MUTATIONS.SET_CONTAINER_SCROLL_TOP](
    state: IMarginStackState,
    value: number
  ) {
    state.containerScrollTop = value;
  },

  [MARGIN_STACK.MUTATIONS.SET_SAVED_DATA_NOT_PUBLISHED](state: IMarginStackState, value: boolean) {
    state.savedDataNotPublished = value
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
