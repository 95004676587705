import { createRouter, createWebHistory } from 'vue-router';

import store from '@/store';
import * as PartnerType from '@/mixins/partnerType';
import productRoutes from './productRoutes';
import loginRoutes from './loginRoutes';
import orderRoutes from './orderRoutes';
import { USER } from '../types/constants';

const routes = productRoutes.concat(loginRoutes, orderRoutes);

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const isPublic = to.matched.some((record) => record.meta.public);
  const onlyWhenLoggedOut = to.matched.some(
    (record) => record.meta.onlyWhenLoggedOut
  );
  const onlyWhenMarketnation = to.matched.some(
    (record) => record.meta.onlyWhenMarketnation
  );

  const loggedIn = store.getters['user/' + USER.GETTERS.IS_LOGGED_IN];
  if (!isPublic && !loggedIn) {
    return next({
      path: '/login',
      query: { redirect: to.fullPath }
    });
  }

  // Do not allow user to visit login page or register page if they are logged in
  if (loggedIn && onlyWhenLoggedOut) {
    return next('/');
  }

  const profile = store.getters['user/' + USER.GETTERS.GET_PROFILE];
  const isProfileTypeMarketnation =
    profile && profile.type && profile.type !== PartnerType.MARKETNATION;

  if (onlyWhenMarketnation && isProfileTypeMarketnation) {
    return next('/products');
  }

  next();
});

export default router;
