import ApiService from '@/services/api.service';
import {
  ICategoryState,
  ICategoryActions,
  ICategoryGetters,
  CategoryMutationsTypes
} from '@/types/interfaces/store/category/category';
import {
  ICategory,
  ICategoryChildren
} from '@/types/interfaces/category/category';
import { CATEGORY } from '@/types/constants';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { AxiosError, AxiosResponse } from 'axios';
import { IState } from '@/types/interfaces/store';

export const state: ICategoryState = {
  categories: [],
  categoryDetails: null,
  loadingCategoryDetails: true
};

export const getters: GetterTree<ICategoryState, ICategoryState> &
  ICategoryGetters = {
  [CATEGORY.GETTERS.GET_CATEGORY_DETAILS]: (state: ICategoryState) =>
    state.categoryDetails,
  [CATEGORY.GETTERS.LOADING_CATEGORY_DETAILS]: (state: ICategoryState) =>
    state.loadingCategoryDetails,
  [CATEGORY.GETTERS.GET_CATEGORIES]: (state: ICategoryState) =>
    state.categories,
  [CATEGORY.GETTERS.GET_CATEGORY_OPTIONS]: (state: ICategoryState) => {
    const renameObjKeys = (array: ICategory[]) => {
      return array.map((item: ICategory) => {
        if (item.children) {
          return {
            value: item.id,
            label: item.name,
            children: renameObjKeys(item.children)
          };
        }
        return {
          value: item.id,
          label: item.name
        };
      });
    };
    return renameObjKeys(state?.categories);
  }
};

export const actions: ActionTree<IState, IState> & ICategoryActions = {
  [CATEGORY.ACTIONS.GET_CATEGORIES_FROM_BACKEND](
    { commit },
    hierarchy: boolean
  ) {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      commit(CATEGORY.MUTATIONS.GET_CATEGORIES_REQUEST);

      let endpoint = hierarchy ? '/categories?hierarchy=true' : '/categories';
      const url = process.env.VUE_APP_CATALOG_SERVICE + endpoint;

      ApiService.get(url)
        .then((response: AxiosResponse) => {
          const categories = response.data.data;
          commit(CATEGORY.MUTATIONS.GET_CATEGORIES_SUCCESS, categories);
          resolve(categories);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },

  [CATEGORY.ACTIONS.GET_CATEGORY_DETAILS_FROM_BACKEND]({ commit }, categoryId) {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      commit(CATEGORY.MUTATIONS.GET_CATEGORY_DETAILS_REQUEST);

      const url =
        process.env.VUE_APP_CATALOG_SERVICE + '/categories/' + categoryId;

      ApiService.get(url)
        .then((response: AxiosResponse) => {
          commit(
            CATEGORY.MUTATIONS.GET_CATEGORY_DETAILS_SUCCESS,
            response.data
          );
          resolve(response.data);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }
};

const mutations: MutationTree<IState> & CategoryMutationsTypes = {
  [CATEGORY.MUTATIONS.GET_CATEGORIES_REQUEST]() {},

  [CATEGORY.MUTATIONS.GET_CATEGORIES_SUCCESS](state, data) {
    state.categories = data;
  },

  [CATEGORY.MUTATIONS.GET_CATEGORY_DETAILS_REQUEST]() {
    state.loadingCategoryDetails = true;
    state.categoryDetails = {};
  },

  [CATEGORY.MUTATIONS.GET_CATEGORY_DETAILS_SUCCESS](state, data) {
    state.loadingCategoryDetails = false;
    state.categoryDetails = data;
  },

  [CATEGORY.MUTATIONS.CLEAR_CATEGORY_DETAILS](state) {
    state.categoryDetails = null;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
