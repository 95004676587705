<template src="./template.pug" lang="pug"></template>

<script>
import { Form } from 'vee-validate';
import InlineSvg from 'vue-inline-svg';
import { USER } from '../../types/constants';

export default {
  name: 'SetPassword',
  components: {
    Form,
    InlineSvg
  },

  data() {
    return {
      appName: process.env.VUE_APP_NAME,
      currentYear: new Date().getFullYear(),
      newPassword: '',
      confirmPassword: '',
      isSubmitting: false,
      isReady: false,
      isBannerOpen: false,
      complexityRequirements: [
        { rule: 'min:10', label: '10 characters minimum' },
        { rule: 'oneUpperCase', label: 'One uppercase letter' },
        { rule: 'oneLowerCase', label: 'One lowercase letter' },
        { rule: 'oneNumber', label: 'One number' },
        { rule: 'oneSymbol', label: 'One symbol' }
      ]
    };
  },

  computed: {
    isPasswordMatchingConfirm() {
      if (!this.newPassword || !this.confirmPassword) {
        return false;
      }
      return this.newPassword === this.confirmPassword;
    }
  },

  created() {
    this.verifySetPasswordToken();
  },

  methods: {
    verifySetPasswordToken() {
      this.$store
        .dispatch('user/' + USER.ACTIONS.VERIFY_SET_PASSWORD, {
          id: this.$route.query?.id,
          token: this.$route.query?.token
        })
        .then(() => {
          this.isReady = true;
        })
        .catch(() => {
          this.$router.push({
            name: 'forgot-password',
            params: { expiredTokenError: true }
          });
        });
    },

    async onSubmit() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        return false;
      }
      this.setNewPassword();
    },

    setNewPassword() {
      this.isSubmitting = true;
      this.$store
        .dispatch('user/' + USER.ACTIONS.SET_PASSWORD, {
          userId: this.$route.query?.id,
          passwordToken: this.$route.query?.token,
          password: this.newPassword
        })
        .then((result) => {
          this.isSubmitting = false;
          if (result.success) {
            this.$router.push({
              name: 'login',
              params: { passwordBannerState: 'set_password_success' }
            });
          } else {
            this.handleSetPasswordError();
          }
        })
        .catch(() => this.handleSetPasswordError());
    },

    handleSetPasswordError() {
      this.isSubmitting = false;
      this.showBanner();
    },

    showBanner() {
      this.isBannerOpen = true;
    }
  }
};
</script>
<style scoped lang="scss">
.logo-container {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}
img {
  width: 380px;
  padding-left: 20px;
  text-align: center;
}
</style>
