import { mapGetters } from 'vuex';

const mixin = {
  data() {
    return {
      marketplaceOptions: [],
      categoryOptions: [{ value: '', label: '' }],
      brandOptions: [],
      manufacturerOptions: []
    };
  },

  computed: {
    ...mapGetters('partner', ['GET_BRAND_PARTNERS', 'GET_MARKETPLACES'])
  },

  methods: {
    setBrandDropdownOptions() {
      let dataForDropdown = this.GET_BRAND_PARTNERS.map((item) => {
        return { value: item.id, label: item.name };
      });
      dataForDropdown.sort((a, b) => a.label.localeCompare(b.label));

      this.brandOptions = dataForDropdown;
    },

    setMarketplaceOptions() {
      let dataForDropdown = this.GET_MARKETPLACES.map((item) => {
        return {
          value: item.id,
          label: item.name,
          storefronts: item.storefronts
        };
      });
      dataForDropdown.unshift({ value: '', label: 'Select marketplace' });

      this.marketplaceOptions = dataForDropdown;
    },

    setCategoryDropdownOptions(data) {
      let keys = {
        name: 'label',
        id: 'value'
      };

      function renameObjKeys(value) {
        if (!value || typeof value !== 'object') return value;
        if (Array.isArray(value)) return value.map(renameObjKeys);
        return Object.fromEntries(
          Object.entries(value).map(([k, v]) => [
            keys[k] || k,
            renameObjKeys(v)
          ])
        );
      }

      const sort = (array) => {
        array.sort((a, b) => a.label.localeCompare(b.label));
        array.forEach(({ children = [] }) => sort(children));

        return array;
      };

      this.categoryOptions[0].children = sort(renameObjKeys(data));
    },

    setManufacturerOptions(data) {
      data.forEach((item) => {
        this.manufacturerOptions.push({
          value: item.id,
          label: item.name
        });
      });
    }
  },

  beforeUnmount() {
    this.manufacturerOptions = null;
    this.categoryOptions = null;
    this.brandOptions = null;
    this.marketplaceOptions = null;
  }
};

export default mixin;
