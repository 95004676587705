import Component from './component.vue';
import { createApp } from 'vue';

const Methods = (Vue, globalOptions = {}) => {
  return {
    open(options) {
      let message;
      if (typeof options === 'string') message = options;

      const defaultOptions = {
        message
      };

      const propsData = Object.assign(
        {},
        defaultOptions,
        globalOptions,
        options
      );

      const root = document.createElement('div');
      const toastNotification = createApp(Component, propsData);

      toastNotification.mount(root);
    },

    info(message, options = {}) {
      return this.open(
        Object.assign(
          {},
          {
            message,
            type: 'info'
          },
          options
        )
      );
    },

    success(message, options = {}) {
      return this.open(
        Object.assign(
          {},
          {
            message,
            type: 'success'
          },
          options
        )
      );
    },

    error(message, options = {}) {
      return this.open(
        Object.assign(
          {},
          {
            message,
            type: 'error'
          },
          options
        )
      );
    },

    warning(message, options = {}) {
      return this.open(
        Object.assign(
          {},
          {
            message,
            type: 'warning'
          },
          options
        )
      );
    },

    default(message, options = {}) {
      return this.open(
        Object.assign(
          {},
          {
            message,
            type: 'default'
          },
          options
        )
      );
    }
  };
};

export default Methods;
