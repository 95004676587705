import { computed, onMounted, watch } from 'vue';
import { isEqual } from 'lodash';
import { useStore } from 'vuex';
import { PD_OFFER } from '@/types/constants';

export function useAccordionOfferData(
  accordionName: string,
  accordionData: { wrapper: any },
  moduleName: string,
  actionAccordionName: string
) {
  const store = useStore();

  const originalData = computed(() => {
    return store.getters[
      moduleName + '/' + PD_OFFER.GETTERS.GET_ACCORDION_OFFER_ORIGINAL_DATA
    ](accordionName);
  });

  const errors = computed(() => {
    return store.getters[
      moduleName + '/' + PD_OFFER.GETTERS.GET_ACCORDION_OFFER_ERRORS
    ](accordionName);
  });

  const setAccordionDataFromStoredData = () => {
    accordionData.wrapper = store.getters[
      moduleName + '/' + PD_OFFER.GETTERS.GET_ACCORDION_OFFER_DATA
    ](accordionName);
  };

  const clearFieldError = (fieldName: string) => {
    store.commit(
      moduleName + '/' + PD_OFFER.MUTATIONS.CLEAR_FIELD_OFFER_ERROR,
      { accordionName, fieldName }
    );
  };

  onMounted(() => {
    setAccordionDataFromStoredData();
  });

  watch(
    [accordionData, originalData],
    () => {
      setIsEditing();
    },
    { deep: true }
  );

  const setIsEditing = () => {
    const isEditing = !isEqual(accordionData.wrapper, originalData.value);
    store.commit(
      moduleName + '/SET_IS_EDITING_' + actionAccordionName,
      isEditing
    );
  };

  return {
    errors,
    clearFieldError
  };
}
