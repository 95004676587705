<template>
  <t-modal
    id="upload_products_modal"
    :isOpen="isOpen"
    @update:isOpen="closeUploadProductsModal"
    widthClass="w-90vw md:w-700px"
    title="Upload"
    outlinedButtonLabel="Cancel"
    :outlinedButtonClick="closeUploadProductsModal"
    :containedButtonLabel="
      !uploadMessage ? 'Upload products' : 'Download Errors'
    "
    :containedButtonClick="!uploadMessage ? uploadProducts : downloadErrors"
    :isContainedButtonLoading="isUploading"
    :isContainedButtonDisabled="uploadFiles.length === 0"
    :enableDefaultFooter="bannerState !== 'success'"
  >
    <template v-slot:content="{ contentMaxHeight }">
      <div
        class="px-8 pt-4"
        :style="{ maxHeight: contentMaxHeight - 99 + 'px' }"
      >
        <t-horizontal-tabs
          :activeTab="activeTab"
          :tabs="tabs"
          :fullWidth="true"
        ></t-horizontal-tabs>

        <t-banner
          :show="successCount"
          type="success"
          :action="null"
          action-button-label="View upload status"
          title="Upload Success"
          containerClass="mb-6 mt-6"
        >
          <p>
            {{ successCount }} product records successfully processed
          </p></t-banner
        >

        <t-banner
          :show="showBanner"
          :type="getBannerConfig().type"
          :action="getBannerConfig().action"
          action-button-label="View upload status"
          :title="getBannerConfig().title"
          containerClass="mb-6 mt-6"
        >
          <p
            v-html="uploadMessage ? uploadMessage : getBannerConfig().message"
          ></p
        ></t-banner>

        <div class="pt-7" v-if="!uploadMessage && !uploadSuccessful">
          <p class="mb-4">
            Using the template created from the
            <button @click="openAdminEditModal" class="easter-egg">P</button
            >roduct Content download, editable inputs can be added or changed
            for an existing product through this bulk edit process. Prior to
            uploading sheet, remove any products that don’t have updates from
            the sheet prior to saving.
          </p>
          <p class="mt-1 mb-3 font-bold">Upload edited template (.csv)</p>
          <p class="inline">
            Ensure the uploaded template is saved as a CSV file. The information
            in your file will overwrite existing product information for
            editable fields.
          </p>
          <div class="mt-7">
            <t-file-uploader
              id="bulk_edit"
              v-model="uploadFiles"
              :file-types="['', 'text/csv', 'application/vnd.ms-excel']"
              :multiple="false"
              :key="fileUploaderKey"
              v-if="!uploadMessage"
            ></t-file-uploader>
          </div>
        </div>
      </div>
    </template>
  </t-modal>
</template>
<script>
import downloadOptions from '@/views/products/mixins/downloadOptions';
import getters from '@/views/products/mixins/getters';
import setters from '@/views/products/mixins/setters';
import utility from '@/mixins/utilityFunctions';
import axios from 'axios';

export default {
  name: 'BulkEditModal',
  mixins: [downloadOptions, getters, setters, utility],
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    isCurrentTabAN: {
      type: Boolean,
      default: false
    }
  },
  inject: ['toastNotification'],
  data() {
    return {
      activeTab: 'bulk_edit',
      tabs: [
        {
          name: 'Bulk edit',
          code: 'bulk_edit'
        }
      ],
      uploadFiles: [],
      bannerState: '',
      fileUploaderKey: 0,
      categoryDropdownKey: 0,
      isUploading: false,
      uploadMessage: null,
      successCount: 0,
      showBanner: false,
      uploadSuccessful: false
    };
  },
  methods: {
    closeUploadProductsModal() {
      this.fileUploaderKey++;
      this.uploadMessage = null;
      this.showBanner = false;
      this.uploadFiles = [];
      this.$emit('closeModal');
    },
    openAdminEditModal() {
      this.$emit('openAdminEditModal');
    },
    viewUploadStatus() {
      this.closeUploadProductsModal();
      this.$nextTick(() => this.$router.push('/products/upload_status'));
    },
    async uploadProducts() {
      this.isUploading = true;
      this.uploadMessage = null;
      this.uploadSuccessful = false;
      this.bannerState = null;

      const files = this.uploadFiles;

      for (let i = 0; i < files.length; i++) {
        files[i].state = 'pending';
        await this.$store
          .dispatch('product/IMPORT_PRODUCTS', {
            type: 'EDIT/productOffer',
            file: files[i]
          })
          .then((resp) => {
            this.handleUploadProductSuccess(files[i], resp);
            this.uploadSuccessful = true;
            this.bannerState = 'success';
          })
          .catch((resp) => {
            this.uploadMessage = JSON.stringify(
              resp.response.data.message,
              null,
              2
            );
            this.uploadMessage = this.jsonEscape(this.uploadMessage);
            this.successCount = resp.response.data.successful;
            this.bannerState = 'error';
          })
          .finally(() => {
            if (i === files.length - 1) {
              setTimeout(() => this.afterUploadProducts(files), 1000);
            }
          });
      }
    },
    async downloadErrors() {
      const userEmail = this.$store.state.user.profile.email;
      this.uploadFiles[0].state = 'pending';
      this.isUploading = true;
      await this.$store
        .dispatch('product/DOWNLOAD_ERRORS', {
          file: this.uploadFiles[0],
          isProductImport: true
        })
        .then((response) => {
          this.$toastNotification.success(
            `The errors has been emailed to ${userEmail}`
          );
        })
        .catch((response) => {
          this.handleDownloadError();
        });
      this.isUploading = false;
    },
    jsonEscape(str) {
      return str.replace(/\\n/g, '<br>').replace(/[{}]/g, '');
    },
    afterUploadProducts(files) {
      this.showBanner = true;
      this.isUploading = false;
      // this.fileUploaderKey++;
      this.$refs.main.scrollTo({ top: 0, behavior: 'smooth' });
    },
    handleUploadProductSuccess(file, response) {
      if (!file) {
        return false;
      }
      if (axios.isCancel(response)) {
        file.state = 'error';
      } else {
        file.state = 'success';
      }
    },
    handleUploadProductError(file) {
      if (!file) {
        return false;
      }
      file.state = 'error';
    },
    getBannerConfig() {
      switch (this.bannerState) {
        case 'success':
          return {
            title: 'Upload Successful',
            message: 'All files have been successfully uploaded',
            type: 'success',
            action: null
          };
        case 'error':
          return {
            title: 'Upload failed',
            message: 'Files could not be uploaded, please try again later.',
            type: 'warning',
            action: null
          };
        default:
          return {
            title: '',
            message: '',
            type: 'error',
            action: null
          };
      }
    }
  }
};
</script>
