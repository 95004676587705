import { inject, ref } from 'vue';
import { usePartnerTypes } from '@/composables/partnerTypes';
import { useStore } from 'vuex';
import { MARGIN_STACK } from '@/types/constants';

export function useSavingTrigger(marketplacesRefs) {
  const isProposalModalOpen = ref(false);
  const isSendingProposal = ref(false);
  const { isUserMarketnation } = usePartnerTypes();
  const store = useStore();
  const toastNotification = inject<any>('toastNotification');

  const triggerMarginStackSaving = () => {
    if (shouldShowProposalModal()) {
      isProposalModalOpen.value = true;
      return;
    }
    updateMarginStack();
  };

  const updateMarginStack = () => {
    const data = [];
    setIsSavingMarginStack(true);

    marketplacesRefs.value.forEach((ref) => {
      if (!ref.isEditing) {
        return;
      }
      // @ts-ignore
      data.push(ref.getMarginStackMarketplaceDataForUpdate());
    });

    store
      .dispatch(
        'product/marginStack/' + MARGIN_STACK.ACTIONS.UPDATE_MARGIN_STACK,
        data
      )
      .then(() => {
        marketplacesRefs.value.forEach((ref) => {
          if (!ref.isEditing) {
            return;
          }
          ref.loadMarginStackMarketplaceAfterSaving();
        });
        onSaveSuccess();
      })
      .catch(onSaveError)
      .finally(() => {
        setIsSavingMarginStack(false);
      });
  };

  const shouldShowProposalModal = () => {
    return !isUserMarketnation();
  };

  const triggerProposalSending = () => {
    isSendingProposal.value = true;
    const sendingProposalPromises = [];

    marketplacesRefs.value.forEach((ref) => {
      // @ts-ignore
      return sendingProposalPromises.push(ref.proposalModalPart.sendProposal());
    });

    Promise.all(sendingProposalPromises)
      .finally(() => {
        isSendingProposal.value = false;
        isProposalModalOpen.value = false;
      })
      .then(() => {
        updateMarginStack();
      });
  };

  const setIsSavingMarginStack = (value: boolean) => {
    store.commit(
      'product/marginStack/' + MARGIN_STACK.MUTATIONS.SET_IS_SAVING,
      value
    );
  };

  const onSaveSuccess = () => {
    toastNotification.warning('Additional action required to activate changes in channels.');
  };

  const onSaveError = () => {
    toastNotification.error('Changes need to be made in order to save.');
  };

  return {
    isProposalModalOpen,
    triggerMarginStackSaving,
    triggerProposalSending,
    isSendingProposal
  };
}
