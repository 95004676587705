import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pt-7 pb-7 inline-block" }

export function render(_ctx, _cache) {
  const _component_SkuSelect = _resolveComponent("SkuSelect")
  const _component_margin_stack_marketplace = _resolveComponent("margin-stack-marketplace")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.desc), 1),
    _createVNode(_component_SkuSelect),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.storefrontIds, (storefrontId, index) => {
      return (_openBlock(), _createBlock(_component_margin_stack_marketplace, {
        key: storefrontId,
        id: _ctx.marginStackNavItems[index]?.code,
        storefrontId: storefrontId,
        isOpen: index === 0,
        isProposalModalOpen: _ctx.isProposalModalOpen,
        ref_for: true,
        ref: _ctx.skipUnwrap.marketplaces
      }, null, 8, ["id", "storefrontId", "isOpen", "isProposalModalOpen"]))
    }), 128))
  ], 64))
}