import { cloneDeep } from 'lodash';
import ApiService from '@/services/api.service';
import {
  IOfferBasicInfoState,
  IOfferBasicInfoGetters,
  IOfferBasicInfoActions,
  OfferBasicInfoMutationsTypes
} from '@/types/interfaces/store/product/offer/accordions/basic-offer-info';
import { PD_OFFER } from '@/types/constants';
import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { AxiosError, AxiosResponse } from 'axios';
import {
  IBasicOfferInfo,
  IBasicOfferInfoError
} from '@/types/interfaces/product/offer/accordions/basic-offer-info';
import { IState } from '@/types/interfaces/store';
import { IOfferState } from '@/types/interfaces/store/product/offer';

export const state: IOfferBasicInfoState = {
  isEditingBasicOfferInfo: false,
  isLoadingBasicOfferInfo: false,

  basicOfferInfo: null,
  originalBasicOfferInfo: null,
  emptyBasicOfferInfo: {
    condition: null,
    sku: null,
    identifiers: [
      {
        identifier: '',
        identifierType: ''
      }
    ],
    cost: null,
    margin: null,
    fulfillmentPartnerId: null,
    marginType: null,
    freightCost: null,
    warranty: null,
    warrantyDate: null,
    bundlingAvailable: null,
    bundlingPrice: null,
    returnRate: null,
    savedDataNotPublished: false
  },
  basicOfferInfoErrors: null
};

export const getters: GetterTree<IOfferBasicInfoState, IOfferBasicInfoState> &
  IOfferBasicInfoGetters = {
  [PD_OFFER.GETTERS.GET_FULFILLMENT_PARTNER_ID]: (
    state: IOfferBasicInfoState
  ): number | null | undefined => {
    return state.basicOfferInfo?.fulfillmentPartnerId;
  },

  [PD_OFFER.GETTERS.GET_FULFILLMENT_MARGIN_TYPE]: (
    state: IOfferBasicInfoState
  ): string | null | undefined => {
    return state.basicOfferInfo?.marginType;
  },

  [PD_OFFER.GETTERS.SAVED_DATA_NOT_PUBLISHED]: (
    state: IOfferBasicInfoState
  ): boolean | null | undefined => {
    return state.basicOfferInfo?.savedDataNotPublished;
  }
};


export const actions: ActionTree<IOfferState, IState> &
  IOfferBasicInfoActions = {
  [PD_OFFER.ACTIONS.GET_BASIC_OFFER_INFO](
    { commit },
    { productId, offerId }: { productId: number; offerId: number }
  ) {
    return new Promise((resolve, reject) => {
      commit(PD_OFFER.MUTATIONS.SET_IS_LOADING_BASIC_OFFER_INFO, true);

      const url =
        process.env.VUE_APP_CATALOG_SERVICE +
        '/products/' +
        productId +
        '/offers/' +
        offerId +
        '/basic-info';

      ApiService.get(url)
        .then((response: AxiosResponse<IBasicOfferInfo>) => {
          commit(PD_OFFER.MUTATIONS.SET_IS_LOADING_BASIC_OFFER_INFO, false);
          commit(PD_OFFER.MUTATIONS.SET_BASIC_OFFER_INFO, response.data);
          commit(
            PD_OFFER.MUTATIONS.SET_ORIGINAL_BASIC_OFFER_INFO,
            response.data
          );
          resolve(response.data);
        })
        .catch((error: AxiosError<IBasicOfferInfoError>) => {
          commit(PD_OFFER.MUTATIONS.SET_IS_LOADING_BASIC_OFFER_INFO, false);
          reject(error);
        });
    });
  }
};

const mutations: MutationTree<IOfferState> & OfferBasicInfoMutationsTypes = {
  [PD_OFFER.MUTATIONS.SET_IS_EDITING_BASIC_OFFER_INFO](
    state: IOfferBasicInfoState,
    value: boolean
  ) {
    state.isEditingBasicOfferInfo = value;
  },

  [PD_OFFER.MUTATIONS.SET_IS_LOADING_BASIC_OFFER_INFO](
    state: IOfferBasicInfoState,
    value: boolean
  ) {
    state.isLoadingBasicOfferInfo = value;
  },

  [PD_OFFER.MUTATIONS.SET_BASIC_OFFER_INFO](
    state: IOfferBasicInfoState,
    value: IBasicOfferInfo
  ) {
    state.basicOfferInfo = value;
  },

  [PD_OFFER.MUTATIONS.SET_ORIGINAL_BASIC_OFFER_INFO](
    state: IOfferBasicInfoState,
    value: IBasicOfferInfo
  ) {
    state.originalBasicOfferInfo = cloneDeep(value);
  },

  [PD_OFFER.MUTATIONS.SET_BASIC_OFFER_INFO_ERRORS](
    state: IOfferBasicInfoState,
    value: IBasicOfferInfoError
  ) {
    state.basicOfferInfoErrors = value;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
