<template src="./template.pug" lang="pug"></template>
<script>
import BasicProductInfo from './basic-product-info/index';
import Media from './media/index';
import Description from './description/index';
import CategoryAttributes from './category-attributes/index';
import Dimensions from './dimensions/index';
import Compliance from './compliance/index';

export default {
  name: 'ProductContent',
  components: {
    BasicProductInfo,
    Media,
    Description,
    CategoryAttributes,
    Dimensions,
    Compliance
  }
};
</script>

<script setup>
const desc =
  'Core details of this product that will remain true regardless of who’s fulfilling or selling the product.';
</script>
