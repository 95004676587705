import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, Teleport as _Teleport } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col"
}
const _hoisted_2 = { class: "text-black font-bold text-2xl py-6 -mt-6 order-1 sticky -top-6 bg-white z-10" }
const _hoisted_3 = {
  key: 0,
  class: "text-black font-bold text-xl mb-1 -mt-1 order-1"
}
const _hoisted_4 = {
  key: 1,
  class: "text-md order-1"
}
const _hoisted_5 = {
  key: 2,
  class: "text-black font-bold text-xl mb-1 -mt-1 order-3"
}

export function render(_ctx, _cache) {
  const _component_proposal_modal_part_tab = _resolveComponent("proposal-modal-part-tab")

  return (_ctx.isProposalModalOpen)
    ? (_openBlock(), _createBlock(_Teleport, {
        key: 0,
        to: "#proposal_modal_content"
      }, [
        (_ctx.isUpdating || _ctx.isProposing)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.storefrontFullName), 1),
              (_ctx.isUpdating)
                ? (_openBlock(), _createElementBlock("p", _hoisted_3, "Review your changes"))
                : _createCommentVNode("", true),
              (_ctx.isUpdating)
                ? (_openBlock(), _createElementBlock("p", _hoisted_4, "You’ve made the following changes for this product."))
                : _createCommentVNode("", true),
              (_ctx.isProposing)
                ? (_openBlock(), _createElementBlock("p", _hoisted_5, "Review your proposal"))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.entries(_ctx._editedInputs), ([tabName, tabEditedInputs]) => {
                return (_openBlock(), _createBlock(_component_proposal_modal_part_tab, {
                  editedInputs: tabEditedInputs,
                  proposalReceiver: _ctx.tabOwners[tabName],
                  isProposing: !_ctx.canEditTab(tabName),
                  tabName: tabName
                }, null, 8, ["editedInputs", "proposalReceiver", "isProposing", "tabName"]))
              }), 256))
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}