import utilityFunctions from '../mixins/utilityFunctions';
import { mapGetters } from 'vuex';

const mixin = {
  mixins: [utilityFunctions],
  data() {
    return {
      items: [
        {
          name: 'Products',
          icon: 'tag',
          children: [
            {
              name: 'My products',
              href: '/products'
            },
            {
              name: 'Upload status',
              href: '/products/upload_status',
              hidden: true
            },
            {
              name: 'Update queue',
              href: '/products/update_queue',
              hidden: true
            }
          ]
        },
        {
          name: 'Orders',
          icon: 'box',
          href: '/orders',
          children: []
        }
      ],
      sidebarKey: 0
    };
  },

  computed: {
    ...mapGetters('treatment', ['GET_USER_TREATMENT']),
    exposingChangesFlag() {
      return this.GET_USER_TREATMENT('exposing-changes-to-users');
    }
  },

  watch: {
    exposingChangesFlag: {
      immediate: true,
      handler(value) {
        this.updateSidebarComponent();
      }
    }
  },

  methods: {
    updateSidebarComponent() {
      this.sidebarKey++;
    }
  }
};

export default mixin;
