import { cloneDeep } from 'lodash';
import ApiService from '@/services/api.service';
import {
  IDescriptionState,
  IDescriptionActions,
  DescriptionMutationsTypes,
  IDescriptionGetters
} from '@/types/interfaces/store/product/accordions/description';
import { PD_CONT } from '@/types/constants';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { AxiosError, AxiosResponse } from 'axios';
import {
  IDescription,
  IDescriptionError
} from '@/types/interfaces/product/accordions/description';
import { IState } from '@/types/interfaces/store';

export const state: IDescriptionState = {
  isEditingDescription: false,
  isLoadingDescription: false,

  description: null,
  originalDescription: null,
  emptyDescription: {
    shortDescription: '',
    longDescription: '',
    featureBullets: ['']
  },
  descriptionErrors: null
};

export const getters: GetterTree<IDescriptionState, IDescriptionState> &
  IDescriptionGetters = {};

export const actions: ActionTree<IState, IState> & IDescriptionActions = {
  [PD_CONT.ACTIONS.GET_DESCRIPTION]({ commit }, productId) {
    return new Promise((resolve, reject) => {
      commit(PD_CONT.MUTATIONS.SET_IS_LOADING_DESCRIPTION, true);

      const url =
        process.env.VUE_APP_CATALOG_SERVICE +
        '/products/' +
        productId +
        '/description';

      ApiService.get(url)
        .then((response: AxiosResponse<IDescription>) => {
          commit(PD_CONT.MUTATIONS.SET_IS_LOADING_DESCRIPTION, false);
          commit(PD_CONT.MUTATIONS.SET_DESCRIPTION, response.data);
          commit(PD_CONT.MUTATIONS.SET_ORIGINAL_DESCRIPTION, response.data);
          resolve(response.data);
        })
        .catch((error: AxiosError) => {
          commit(PD_CONT.MUTATIONS.SET_IS_LOADING_DESCRIPTION, false);
          reject(error);
        });
    });
  }
};

const mutations: MutationTree<IState> & DescriptionMutationsTypes = {
  [PD_CONT.MUTATIONS.SET_IS_EDITING_DESCRIPTION](
    state: IDescriptionState,
    value: boolean
  ) {
    state.isEditingDescription = value;
  },

  [PD_CONT.MUTATIONS.SET_IS_LOADING_DESCRIPTION](
    state: IDescriptionState,
    value: boolean
  ) {
    state.isLoadingDescription = value;
  },

  [PD_CONT.MUTATIONS.SET_DESCRIPTION](
    state: IDescriptionState,
    value: IDescription
  ) {
    if (value.featureBullets.length === 0) {
      value.featureBullets?.push('');
    }
    state.description = value;
  },

  [PD_CONT.MUTATIONS.SET_ORIGINAL_DESCRIPTION](
    state: IDescriptionState,
    value: IDescription
  ) {
    if (value.featureBullets.length === 0) {
      value.featureBullets?.push('');
    }
    state.originalDescription = cloneDeep(value);
  },

  [PD_CONT.MUTATIONS.SET_DESCRIPTION_ERRORS](
    state: IDescriptionState,
    value: IDescriptionError
  ) {
    state.descriptionErrors = value;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
