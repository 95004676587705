import { cloneDeep, isEqual } from 'lodash';
import { ref, onMounted, watch, computed } from 'vue';
import { useEditPermissions } from '@/composables/views/products/product-details/margin-stack/marketplace/editPermissions';
import {
  IRebate,
  IInput,
  IEditedRebate,
  IOriginalInput
} from '@/types/interfaces/product/margin-stack/margin-stack';

export function useIsEditing(
  props: { inputs: IInput[]; rebate: IRebate; tabName: string },
  emits: ['isEditing', 'isProposing', 'isUpdating']
) {
  const { canEditTab } = useEditPermissions();

  const originalInputs = ref<IOriginalInput[] | IInput[]>();
  const isEditingInputs = ref<boolean>(false);
  const editedInputs = ref<IInput[]>([]);

  const originalRebate = ref<IRebate>();
  const isEditingRebate = ref<boolean>(false);
  const editedRebate = ref<IEditedRebate>({
    type: 'REBATE',
    isEditing: false
  });

  const setOriginals = () => {
    originalInputs.value = cloneDeep(props.inputs);
    originalRebate.value = cloneDeep(props.rebate);
  };

  watch(
    () => props.inputs,
    (inputs) => {
      setIsEditingInputs(inputs, originalInputs.value);
      setEditedInputs(inputs);
      emitEditedInputs();
    },
    { deep: true }
  );

  const setIsEditingInputs = (inputs, originalInputs) => {
    isEditingInputs.value = !isEqual(inputs, originalInputs);
  };

  const setEditedInputs = (inputs) => {
    editedInputs.value = inputs.reduce((editedInputs, input) => {
      const originalInput = getOriginalInput(input.type);
      
      if(!originalInput) {
        return editedInputs
      }

      if (!isEqual(input, originalInput)) {
        editedInputs.push({
          type: input.type,
          original: originalInput
        });
      }

      return editedInputs;
    }, []);
  };

  const getOriginalInput = (type: string) => {
    // On the loading of a margin stack, if a check sets the input.hasError to true then it will trigger but the originalInputs will be undefined... I hate this code base.
    if(!originalInputs.value) { 
      return null
    }
    // @ts-ignore
    return originalInputs.value.find((input) => input.type === type);
  };

  watch(
    () => props.rebate,
    (rebate) => {
      isEditingRebate.value = !isEqual(rebate, originalRebate.value);
      setEditedRebate();
      emitEditedInputs();
    },
    { deep: true }
  );

  const setEditedRebate = () => {
    editedRebate.value.isEditing = isEditingRebate.value;
    editedRebate.value.current = props.rebate;
    editedRebate.value.original = originalRebate.value;
  };

  const emitEditedInputs = () => {
    const editedInputsWithRebate = cloneDeep(editedInputs.value);
    if (editedRebate.value.isEditing) {
      // @ts-ignore
      editedInputsWithRebate.push(editedRebate.value);
    }
    // @ts-ignore
    emits('editedInputs', editedInputsWithRebate);
  };

  const isEditingInputsTab = computed(() => {
    return isEditingInputs.value || isEditingRebate.value;
  });

  watch(isEditingInputsTab, (value) => {
    // @ts-ignore
    emits('isEditing', value);
    // @ts-ignore
    emits('isProposing', isProposing(value));
    // @ts-ignore
    emits('isUpdating', isUpdating(value));
  });

  const isProposing = (isEditingInputsTab: boolean) => {
    if (canEditTab(props.tabName)) {
      return false;
    }
    return isEditingInputsTab;
  };

  const isUpdating = (isEditingInputsTab: boolean) => {
    if (!canEditTab(props.tabName)) {
      return false;
    }
    return isEditingInputsTab;
  };

  onMounted(() => {
    setOriginals();
  });

  return {
    isEditingInputsTab,
    originalInputs,
    originalRebate
  };
}
