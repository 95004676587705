export enum ProductGetters {
  GET_PRODUCT_CONTENT_DATA_FOR_REQUEST = 'GET_PRODUCT_CONTENT_DATA_FOR_REQUEST',
  GET_MANUFACTURER_OPTIONS = 'MANUFACTURER_OPTIONS',
  GET_OFFERS_OPTIONS = 'OFFERS_OPTIONS',
  GET_ACCORDION_PRODUCT_ERRORS = 'GET_ACCORDION_PRODUCT_ERRORS',
  IS_EDITING_PRODUCT = 'IS_EDITING_PRODUCT',
  IS_LOADING_PRODUCT = 'IS_LOADING_PRODUCT',
}
export enum ProductMutations {
  SET_IS_CREATING_PRODUCT = 'SET_IS_CREATING_PRODUCT',
  SET_IS_UPDATING_PRODUCT = 'SET_IS_UPDATING_PRODUCT',
  SET_IS_LOADING_OFFERS_LIST = 'SET_IS_LOADING_OFFERS_LIST',
  SET_OFFERS_LIST = 'SET_OFFERS_LIST',
  CLEAR_OFFERS_LIST = 'CLEAR_OFFERS_LIST',
  SET_IS_LOADING_MANUFACTURERS = 'SET_IS_LOADING_MANUFACTURERS',
  SET_MANUFACTURERS = 'SET_MANUFACTURERS',
  SET_IS_ADDING_NEW_PRODUCT = 'SET_IS_ADDING_NEW_PRODUCT',
  SET_PRODUCT_ID = 'SET_PRODUCT_ID',
  CLEAR_ERRORS_OBJECT = 'CLEAR_ERRORS_OBJECT',

  DISCARD_CHANGES_IN_ACCORDION_PRODUCT = 'DISCARD_CHANGES_IN_ACCORDION_PRODUCT',
  CLEAR_ACCORDION_PRODUCT_DATA = 'CLEAR_ACCORDION_PRODUCT_DATA',
  SET_EMPTY_ACCORDION_PRODUCT_DATA = 'SET_EMPTY_ACCORDION_PRODUCT_DATA',
  CLEAR_FIELD_PRODUCT_ERROR = 'CLEAR_FIELD_PRODUCT_ERROR'
}
export enum ProductActions {
  GET_PRODUCT_CONTENT = 'GET_PRODUCT_CONTENT',
  CREATE_PRODUCT_CONTENT = 'CREATE_PRODUCT_CONTENT',
  UPDATE_PRODUCT_CONTENT = 'UPDATE_PRODUCT_CONTENT',
  GET_OFFERS_LIST = 'GET_OFFERS_LIST',
  GET_MANUFACTURERS_FROM_BACKEND = 'GET_MANUFACTURERS_FROM_BACKEND',
  EXPORT_PRODUCTS = 'EXPORT_PRODUCTS',
  EXPORT_TEMPLATE = 'EXPORT_TEMPLATE',
  IMPORT_PRODUCTS = 'IMPORT_PRODUCTS',
  BULK_UPLOAD_PRODUCTS = 'BULK_UPLOAD_PRODUCTS',
  DOWNLOAD_ERRORS = 'DOWNLOAD_ERRORS',
  EXPORT_RECORDS = 'EXPORT_RECORDS',
  PUBLISH_MARKETPLACE = 'PUBLISH_MARKETPLACE',
  UNPUBLISH_MARKETPLACE = 'UNPUBLISH_MARKETPLACE',

  DISCARD_CHANGES_PRODUCT = 'DISCARD_CHANGES',
  CLEAR_PRODUCT_DATA = 'CLEAR_DATA',
  SET_EMPTY_PRODUCT_DATA = 'SET_EMPTY_DATA',
  UPDATE_ORIGINAL_PRODUCT_DATA = 'UPDATE_ORIGINAL_DATA',
  SET_PRODUCT_ERRORS = 'SET_ERRORS',
  PUBLISH_MARKETPLACE_ALL = "PUBLISH_MARKETPLACE_ALL"
}

export enum ExportProductsType {
  ALL_UPDATE_IN_PROGRESS = 'ALL_UPDATE_IN_PROGRESS',
  MATCH = 'MATCH',
  SELECTED = 'SELECTED'
}

export enum UploadProductsType {
  UPLOAD = 'UPLOAD',
  EDIT = 'EDIT'
}
