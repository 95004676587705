export enum TreatmentGetters {
  GET_USER_TREATMENT = 'GET_USER_TREATMENT',
  GET_ACCOUNT_TREATMENT = 'GET_ACCOUNT_TREATMENT',
  GET_ANONYMOUS_TREATMENT = 'GET_ANONYMOUS_TREATMENT'
}
export enum TreatmentMutations {
  SET_USER_TREATMENTS = 'SET_USER_TREATMENTS',
  SET_ACCOUNT_TREATMENTS = 'SET_ACCOUNT_TREATMENTS',
  SET_ANONYMOUS_TREATMENTS = 'SET_ANONYMOUS_TREATMENTS'
}
