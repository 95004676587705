export function useProductStatus(productStatus: string, productOfferStatus: string, productOfferStorefrontStatus: string) {
  const getProductStatusLabel = () => {
    switch (productStatus) {
      case 'PRODUCT_STATUS_MISSING_INPUTS':
        return 'Product Content: Missing inputs';
      case 'PRODUCT_STATUS_ADJUSTMENTS_NEEDED':
        return 'Product Content: Adjustments needed';
      default:
        return '';
    }
  };

  const getProductOfferStatusLabel = () => {
    switch (productOfferStatus) {
      case 'PRODUCT_STATUS_MISSING_INPUTS':
        return 'Sku Details: Missing inputs';
      case 'PRODUCT_STATUS_ADJUSTMENTS_NEEDED':
        return 'Sku Details: Adjustments needed';
      case 'PRODUCT_STATUS_FULFILLMENT_CONFIRMATION_NEEDED':
        return 'Fulfillment Partner Not Confirmed';
      default:
        return '';
    }
  };

  const getProductOfferStorefrontStatusLabel = () => {
    switch (productOfferStorefrontStatus) {
      case 'PRODUCT_STATUS_MISSING_INPUTS':
        return 'Margin Stack: Missing inputs';
      case 'PRODUCT_STATUS_ADJUSTMENTS_NEEDED':
        return 'Margin Stack: Adjustments needed';
      default:
        return '';
    }
  };

  const getStatusBgColor = (status: string) => {
    if (status == 'PRODUCT_STATUS_FULFILLMENT_CONFIRMATION_NEEDED') {
      return 'error'
    }
    switch (status) {
      case 'PRODUCT_STATUS_MISSING_INPUTS':
        return 'warning';
      case 'PRODUCT_STATUS_ADJUSTMENTS_NEEDED':
        return 'error';
      case 'PRODUCT_STATUS_UPDATE_IN_PROGRESS':
        return 'dark-knight';
      case 'PRODUCT_STATUS_READY_FOR_MARKETPLACE':
        return 'success';
      case 'PRODUCT_STATUS_READY_FOR_MARKETPLACE':
        return 'success';
      default:
        return 'warning';
    }
  };

  const getStatusTextColor = (statusCode: string) => {
    switch (statusCode) {
      case 'PRODUCT_STATUS_MISSING_INPUTS':
        return 'black';
      case 'PRODUCT_STATUS_READY_FOR_MARKETPLACE':
        return 'white';
      case 'PRODUCT_STATUS_ADJUSTMENTS_NEEDED':
        return 'white';
      case 'PRODUCT_STATUS_UPDATE_IN_PROGRESS':
        return 'white';
      case 'PRODUCT_STATUS_FULFILLMENT_CONFIRMATION_NEEDED':
        return 'white';
      default:
        return 'black';
    }
  };

  const getProductStatusClasses = (statusCode: string) => {
    return (
      'text-' +
      getStatusTextColor(productStatus) +
      ' bg-' +
      getStatusBgColor(productStatus)
    );
  };

  const getProductOfferStatusClasses = (statusCode: string) => {
    return (
      'text-' +
      getStatusTextColor(productOfferStatus) +
      ' bg-' +
      getStatusBgColor(productOfferStatus)
    );
  };
  
  const getProductOfferStorefrontStatusClasses = () => {
    return (
      'text-' +
      getStatusTextColor(productOfferStorefrontStatus) +
      ' bg-' +
      getStatusBgColor(productOfferStorefrontStatus)
    );
  };

  return {
    getProductStatusLabel,
    getProductOfferStatusLabel,
    getProductOfferStorefrontStatusLabel,
    getProductStatusClasses,
    getProductOfferStatusClasses,
    getProductOfferStorefrontStatusClasses
  };
}
