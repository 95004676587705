import { computed } from 'vue';
import { kebabCase } from 'lodash';
import { useStore } from 'vuex';
import { PARTNER, PD_OFFER } from '@/types/constants';

export function useMarginStackNavItems() {
  const store = useStore();
  const storefrontIds = computed(() => {
    return store.getters[
      'product/offer/' + PD_OFFER.GETTERS.GET_STOREFRONT_IDS
    ];
  });

  const marginStackNavItems = computed(() => {
    return storefrontIds.value.map((storefrontId) => {
      return getMarketplaceNavItem(storefrontId);
    });
  });

  const getMarketplaceNavItem = (storefrontId: number) => {
    const storefront = store.getters[
      'partner/' + PARTNER.GETTERS.GET_MARKETPLACE_STOREFRONT_BY_ID
    ](storefrontId);
    return {
      label: storefront?.marketplace?.name,
      code: kebabCase(storefront?.marketplace?.name)
    };
  };

  return {
    marginStackNavItems
  };
}
