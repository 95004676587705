import ApiService from '@/services/api.service';
import { PartnerType } from '@/types/enums/store/partner/partner';
import {
  IMarketplacePartner,
  IMarketplaces,
  IPartner,
  IPartners,
  IStorefront
} from '@/types/interfaces/partner/partner';
import {
  GettersContext,
  IPartnerActions,
  IPartnerGetters,
  IPartnerState,
  PartnerMutationsTypes
} from '@/types/interfaces/store/partner/partner';
import { PARTNER } from '@/types/constants';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { AxiosError, AxiosResponse } from 'axios';
import { IState } from '@/types/interfaces/store';

export const state: IPartnerState = {
  marketplaces: [],
  storefronts: [],
  fulfillmentPartners: [],
  brandPartners: []
};

export const getters: GetterTree<IPartnerState, IPartnerState> &
  IPartnerGetters = {
  [PARTNER.GETTERS.GET_MARKETPLACES]: (state: IPartnerState) =>
    state.marketplaces,
  [PARTNER.GETTERS.GET_MARKETPLACE_BY_ID]: (state: IPartnerState) => (
    marketplaceId: string
  ) => {
    return state.marketplaces?.find(
      (marketplace) => marketplace.id === parseInt(marketplaceId)
    );
  },
  [PARTNER.GETTERS.GET_MARKETPLACE_NAME_BY_ID]: (
    state: IPartnerState,
    getters: GettersContext
  ) => (marketplaceId) => {
    const marketplace = getters.GET_MARKETPLACE_BY_ID(marketplaceId);
    return marketplace?.name;
  },
  [PARTNER.GETTERS.GET_MARKETPLACE_STOREFRONT_BY_ID]: (
    state: IPartnerState
  ) => (storefrontId: number) => {
    return state.storefronts.find(
      (storefront) => storefront.id === storefrontId
    );
  },
  [PARTNER.GETTERS.GET_MARKETPLACE_STOREFRONT_BY_STOREFRONT_IDS_LIST]: (
    state: IPartnerState,
    getters: GettersContext
  ) => (marketplaceId, storefrontIdsList) => {
    if (!(storefrontIdsList && storefrontIdsList.length !== 0)) {
      return {};
    }

    const marketplace = getters.GET_MARKETPLACE_BY_ID(marketplaceId);
    if (!(marketplace && marketplace.storefronts)) {
      return {};
    }

    const storefront = marketplace.storefronts.find(
      (storefront: IStorefront) => {
        return storefrontIdsList.some((id) => id === storefront.id);
      }
    );
    if (!storefront) {
      return {};
    }
    return storefront;
  },

  [PARTNER.GETTERS.GET_FULFILLMENT_PARTNERS]: (state: IPartnerState) =>
    state.fulfillmentPartners,
  [PARTNER.GETTERS.GET_FULFILLMENT_PARTNER_BY_NAME]: (state: IPartnerState) => (
    fulfillmentPartnerName: string
  ) => {
    return state.fulfillmentPartners.find((partner) => {
      return partner.name === fulfillmentPartnerName;
    });
  },
  [PARTNER.GETTERS.GET_FULFILLMENT_PARTNER_BY_ID]: (state: IPartnerState) => (
    fulfillmentPartnerId: number
  ) => {
    return state.fulfillmentPartners.find((partner) => {
      return partner.id === fulfillmentPartnerId;
    });
  },

  [PARTNER.GETTERS.GET_BRAND_PARTNERS]: (state: IPartnerState) =>
    state.brandPartners,
  [PARTNER.GETTERS.GET_BRAND_BY_ID]: (state: IPartnerState) => (
    brandId: number
  ) => {
    return state.brandPartners.find(
      (marketplace) => marketplace.id === brandId
    );
  },
  [PARTNER.GETTERS.GET_BRAND_NAME_BY_ID]: (
    state: IPartnerState,
    getters: GettersContext
  ) => (brandId) => {
    const brand = getters.GET_BRAND_BY_ID(brandId);
    if (!brand) {
      return;
    }
    return brand.name;
  },

  [PARTNER.GETTERS.GET_FULFILLMENT_AND_BRAND_PARTNERS]: (
    state: IPartnerState
  ) => {
    return state.fulfillmentPartners.concat(state.brandPartners);
  },

  [PARTNER.GETTERS.GET_MARKETPLACE_STOREFRONTS_OPTIONS]: (
    state: IPartnerState,
    getters: GettersContext
  ) => (marketplaceId) => {
    const marketplace = getters.GET_MARKETPLACE_BY_ID(marketplaceId);
    return marketplace?.storefronts.map((item: IStorefront) => {
      return { value: item.id, label: item.type };
    });
  },

  [PARTNER.GETTERS.GET_MARKETPLACE_OPTIONS]: (state: IPartnerState) => {
    let options = state.marketplaces?.map((item) => {
      return { value: item.id, label: item.name };
    });
    options?.sort((a, b) => a.label.localeCompare(b.label));

    return options;
  },

  [PARTNER.GETTERS.GET_BRAND_OPTIONS]: (state: IPartnerState) => {
    let options = state.brandPartners.map((item) => {
      return { value: item.id, label: item.name };
    });
    options.sort((a, b) => a.label.localeCompare(b.label));

    return options;
  },

  [PARTNER.GETTERS.GET_FULFILLMENT_PARTNER_OPTIONS]: (state: IPartnerState) => {
    let options = state.fulfillmentPartners.map((item) => {
      return { value: item.id, label: item.name };
    });
    options.sort((a, b) => a.label.localeCompare(b.label));

    return options;
  }
};

export const actions: ActionTree<IState, IState> & IPartnerActions = {
  [PARTNER.ACTIONS.GET_PARTNERS_FROM_BACKEND]({ commit }, type: PartnerType) {
    return new Promise((resolve, reject) => {
      commit(PARTNER.MUTATIONS.GET_PARTNERS_REQUEST, type);

      const url = process.env.VUE_APP_PARTNER_SERVICE + '/' + type;

      ApiService.get(url)
        .then((response: AxiosResponse<IPartners>) => {
          commit(PARTNER.MUTATIONS.GET_PARTNERS_SUCCESS, {
            partners: response.data.partners,
            type
          });
          resolve(response.data.partners);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },

  [PARTNER.ACTIONS.GET_MARKETPLACES_FROM_BACKEND]({ commit }) {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      commit(PARTNER.MUTATIONS.GET_MARKETPLACES_REQUEST);
      const url = process.env.VUE_APP_CATALOG_SERVICE + '/marketplace';

      ApiService.get(url)
        .then((response: AxiosResponse<IMarketplaces>) => {
          const marketplace = response.data.marketplaces;
          commit(PARTNER.MUTATIONS.GET_MARKETPLACES_SUCCESS, marketplace);
          commit(PARTNER.MUTATIONS.SET_MARKETPLACES_STOREFRONTS, marketplace);
          resolve(response.data.marketplaces);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },

  async [PARTNER.ACTIONS.GET_ALL_PARTNERS_FROM_BACKEND]({ dispatch }) {
    await dispatch(
      PARTNER.ACTIONS.GET_PARTNERS_FROM_BACKEND,
      PartnerType.BRAND
    );
    await dispatch(
      PARTNER.ACTIONS.GET_PARTNERS_FROM_BACKEND,
      PartnerType.DISTRIBUTION_PARTNER
    );
    await dispatch(PARTNER.ACTIONS.GET_MARKETPLACES_FROM_BACKEND);
  }
};

const mutations: MutationTree<IState> & PartnerMutationsTypes = {
  [PARTNER.MUTATIONS.GET_MARKETPLACES_REQUEST](state: IPartnerState) {
    state.marketplaces = null;
  },

  [PARTNER.MUTATIONS.GET_MARKETPLACES_SUCCESS](
    state: IPartnerState,
    data: IMarketplacePartner[]
  ) {
    state.marketplaces = data;
  },

  [PARTNER.MUTATIONS.SET_MARKETPLACES_STOREFRONTS](
    state: IPartnerState,
    marketplaces: IMarketplacePartner[]
  ) {
    let storefronts: IStorefront[] = [];
    marketplaces.forEach((marketplace) => {
      marketplace?.storefronts?.forEach((storefront) => {
        storefront.marketplaceId = marketplace?.id;
        storefront.marketplace = marketplace;
      });
      storefronts = storefronts.concat(marketplace?.storefronts);
    });
    state.storefronts = storefronts;
  },

  [PARTNER.MUTATIONS.GET_PARTNERS_REQUEST](
    state: IPartnerState,
    type: PartnerType
  ) {
    mutations.GET_PARTNERS_SUCCESS(state, { partners: [], type });
  },

  [PARTNER.MUTATIONS.GET_PARTNERS_SUCCESS](
    state: IPartnerState,
    { partners, type }: { partners: IPartner[]; type: PartnerType }
  ) {
    if (!type) {
      return;
    }

    switch (type) {
      case PartnerType.BRAND:
        state.brandPartners = partners;
        break;
      case PartnerType.DISTRIBUTION_PARTNER:
        state.fulfillmentPartners = partners;
        break;
      default:
        break;
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
