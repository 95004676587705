import { resolveComponent as _resolveComponent, createVNode as _createVNode, toHandlers as _toHandlers, mergeProps as _mergeProps, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "p-6 pb-2" }
const _hoisted_2 = { class: "flex justify-between gap-x-8" }

export function render(_ctx, _cache) {
  const _component_t_input = _resolveComponent("t-input")
  const _component_t_input_with_select = _resolveComponent("t-input-with-select")
  const _component_t_bullet_input = _resolveComponent("t-bullet-input")
  const _component_t_tree_dropdown = _resolveComponent("t-tree-dropdown")
  const _component_t_autocomplete = _resolveComponent("t-autocomplete")
  const _component_t_selectize = _resolveComponent("t-selectize")
  const _component_t_input_decimal = _resolveComponent("t-input-decimal")
  const _component_t_collapsible_card = _resolveComponent("t-collapsible-card")

  return (_ctx.basicProductInfo)
    ? (_openBlock(), _createBlock(_component_t_collapsible_card, {
        key: 0,
        "trigger-label": "Basic product info",
        open: true
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_t_input, {
              id: "title",
              name: "title",
              modelValue: _ctx.basicProductInfo.title,
              "onUpdate:modelValue": [
                _cache[0] || (_cache[0] = $event => ((_ctx.basicProductInfo.title) = $event)),
                _cache[1] || (_cache[1] = $event => (_ctx.clearFieldError('title')))
              ],
              label: "Product title",
              isRequired: true,
              limiter: 200,
              errorMessage: _ctx.errors?.title
            }, null, 8, ["modelValue", "errorMessage"]),
            _createVNode(_component_t_bullet_input, {
              id: "identifier",
              label: "Identifier",
              modelValue: _ctx.basicProductInfo.identifiers,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.basicProductInfo.identifiers) = $event)),
              "add-button-label": "Add identifier",
              itemObject: { identifierType: '', identifier: '' },
              isRequired: true
            }, {
              default: _withCtx(({ array, index, props, events }) => [
                _createVNode(_component_t_input_with_select, _mergeProps({
                  class: "identifiers-item",
                  id: "identifier",
                  name: "identifier"
                }, props, _toHandlers(events), {
                  selectValueProp: array[index].identifierType,
                  "onUpdate:selectValueProp": $event => ((array[index].identifierType) = $event),
                  inputValueProp: array[index].identifier,
                  "onUpdate:inputValueProp": $event => ((array[index].identifier) = $event),
                  options: _ctx.identifiersTypeOptions,
                  disabledOptions: _ctx.disabledIdentifierOptions,
                  inputErrorMessage: _ctx.errors?.identifier,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (_ctx.clearFieldError('identifier')))
                }), null, 16, ["selectValueProp", "onUpdate:selectValueProp", "inputValueProp", "onUpdate:inputValueProp", "options", "disabledOptions", "inputErrorMessage"])
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_t_input, {
              id: "mpn",
              name: "mpn",
              modelValue: _ctx.basicProductInfo.mpn,
              "onUpdate:modelValue": [
                _cache[4] || (_cache[4] = $event => ((_ctx.basicProductInfo.mpn) = $event)),
                _cache[5] || (_cache[5] = $event => (_ctx.clearFieldError('mpn')))
              ],
              label: "MPN",
              isRequired: true,
              errorMessage: _ctx.errors?.mpn
            }, null, 8, ["modelValue", "errorMessage"]),
            _createVNode(_component_t_tree_dropdown, {
              id: "category",
              name: "category",
              ref: "category",
              modelValue: _ctx.basicProductInfo.categoryId,
              "onUpdate:modelValue": [
                _cache[6] || (_cache[6] = $event => ((_ctx.basicProductInfo.categoryId) = $event)),
                _cache[7] || (_cache[7] = $event => (_ctx.clearFieldError('category')))
              ],
              label: "Product category",
              options: _ctx.categoryOptions,
              lowestLevel: true,
              isRequired: true,
              errorMessage: _ctx.errors?.categoryId
            }, null, 8, ["modelValue", "options", "errorMessage"]),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_t_autocomplete, {
                id: "brand",
                name: "brand",
                ref: "brand",
                modelValue: _ctx.basicProductInfo.brandId,
                "onUpdate:modelValue": [
                  _cache[8] || (_cache[8] = $event => ((_ctx.basicProductInfo.brandId) = $event)),
                  _cache[9] || (_cache[9] = $event => (_ctx.clearFieldError('brand')))
                ],
                label: "Brand",
                options: _ctx.brandOptions,
                containerCssClass: "flex-1 mb-8",
                isRequired: true,
                errorMessage: _ctx.errors?.brandId
              }, null, 8, ["modelValue", "options", "errorMessage"]),
              _createVNode(_component_t_selectize, {
                id: "manufacturer",
                ref: "manufacturer",
                modelValue: _ctx.basicProductInfo.manufacturer,
                "onUpdate:modelValue": [
                  _cache[10] || (_cache[10] = $event => ((_ctx.basicProductInfo.manufacturer) = $event)),
                  _cache[11] || (_cache[11] = $event => (_ctx.clearFieldError('manufacturer')))
                ],
                name: "manufacturer",
                label: "Manufacturer",
                options: _ctx.manufacturerOptions,
                containerCssClass: "flex-1 mb-8",
                "aria-placeholder": "Manufacturer",
                errorMessage: _ctx.errors?.manufacturer
              }, null, 8, ["modelValue", "options", "errorMessage"])
            ]),
            _createVNode(_component_t_input_decimal, {
              id: "msrp",
              name: "msrp",
              modelValue: _ctx.basicProductInfo.msrp,
              "onUpdate:modelValue": [
                _cache[12] || (_cache[12] = $event => ((_ctx.basicProductInfo.msrp) = $event)),
                _cache[13] || (_cache[13] = $event => (_ctx.clearFieldError('msrp')))
              ],
              label: "MSRP",
              placeholder: "$0.00",
              isRequired: false,
              errorMessage: _ctx.errors?.msrp
            }, null, 8, ["modelValue", "errorMessage"])
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}