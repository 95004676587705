import ProductDetails from '@/views/products/product-details';
import ProductType from '@/views/products/product-type';
import Products from '@/views/products';
import UploadStatus from '@/views/products/upload-status';
import UpdateQueue from '@/views/products/update-queue';

let productRoutes = [
  {
    path: '/',
    name: 'home',
    redirect: {
      name: 'products'
    }
  },
  {
    path: '/products',
    name: 'products',
    component: Products
  },
  {
    path: '/products/add',
    name: 'add',
    component: ProductType
  },
  {
    path: '/products/new',
    name: 'add_product',
    component: ProductDetails,
    meta: {
      withSidenav: true,
      editing: false
    }
  },
  {
    path: '/products/:id/edit',
    name: 'edit_product',
    component: ProductDetails,
    meta: {
      withSidenav: true,
      editing: true
    }
  },
  {
    path: '/products/upload_status',
    name: 'upload_status',
    component: UploadStatus
  },
  {
    path: '/products/update_queue',
    name: 'update_queue',
    component: UpdateQueue,
    meta: {
      onlyWhenMarketnation: true
    }
  }
];

export default productRoutes;
