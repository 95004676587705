import ApiService from '@/services/api.service';
import helper from '../helper';
import gridQuery from '../../../utility/gridQuery';
import {
  GridUpdateQueueMutationsTypes,
  IGridUpdateQueueActions,
  IGridUpdateQueueGetters,
  IGridUpdateQueueState
} from '@/types/interfaces/store/product/grids/update-queue';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { GRID_UPDATE_QUEUE } from '@/types/constants';
import { IState } from '@/types/interfaces/store';
import { AxiosError, AxiosResponse } from 'axios';
import { IProductsGridItems } from '@/types/interfaces/product/grids/grids';

export const state: IGridUpdateQueueState = {
  updateQueueGridItems: {},
  loadingUpdateQueueGrid: false
};

export const getters: GetterTree<IGridUpdateQueueState, IGridUpdateQueueState> &
  IGridUpdateQueueGetters = {
  [GRID_UPDATE_QUEUE.GETTERS.GET_UPDATE_QUEUE_GRID_ITEMS]: (state) =>
    state.updateQueueGridItems,
  [GRID_UPDATE_QUEUE.GETTERS.IS_LOADING_UPDATE_QUEUE_GRID]: (state) =>
    state.loadingUpdateQueueGrid
};

export const actions: ActionTree<IState, IState> & IGridUpdateQueueActions = {
  [GRID_UPDATE_QUEUE.ACTIONS.GET_UPDATE_QUEUE_GRID]({ commit }, filters) {
    return new Promise((resolve, reject) => {
      commit(GRID_UPDATE_QUEUE.MUTATIONS.GET_UPDATE_QUEUE_GRID_REQUEST);

      let filtersCopy = JSON.parse(JSON.stringify(filters));
      filtersCopy.filter = helper.extractMarketplacesFromStatusFilter(
        filtersCopy.filter
      );
      const query = gridQuery.getGridQuery(filtersCopy);

      const url =
        process.env.VUE_APP_CATALOG_SERVICE +
        '/products/update-queue/grid' +
        query;

      ApiService.get(url)
        .then((response: AxiosResponse<IProductsGridItems>) => {
          commit(
            GRID_UPDATE_QUEUE.MUTATIONS.SET_UPDATE_QUEUE_GRID_SUCCESS,
            response.data
          );

          resolve(response);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },

  [GRID_UPDATE_QUEUE.ACTIONS.EXPORT_UPDATE_QUEUE_PRODUCTS](
    { commit },
    { type, filter }
  ) {
    return new Promise((resolve, reject) => {
      commit(GRID_UPDATE_QUEUE.MUTATIONS.EXPORT_UPDATE_QUEUE_PRODUCTS_REQUEST);

      let filterCopy = JSON.parse(JSON.stringify(filter));
      filterCopy = helper.extractMarketplacesFromStatusFilter(filterCopy);
      filterCopy = gridQuery.getGridFiltersForPostRequest(filterCopy);

      const url =
        process.env.VUE_APP_CATALOG_SERVICE + '/products/update-queue/export';
      const data = {
        filter: filterCopy,
        type
      };

      ApiService.post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch((response) => {
          reject(response);
        });
    });
  },

  [GRID_UPDATE_QUEUE.ACTIONS.UPDATE_UPDATE_QUEUE_PRODUCTS](
    { commit },
    { type, offerStorefronts }
  ) {
    return new Promise((resolve, reject) => {
      commit(GRID_UPDATE_QUEUE.MUTATIONS.UPDATE_UPDATE_QUEUE_PRODUCTS_REQUEST);

      const url =
        process.env.VUE_APP_CATALOG_SERVICE + '/products/update-queue/update';
      const data = {
        type,
        offerStorefronts
      };

      ApiService.post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch((response) => {
          reject(response);
        });
    });
  }
};

const mutations: MutationTree<IState> & GridUpdateQueueMutationsTypes = {
  [GRID_UPDATE_QUEUE.MUTATIONS.EXPORT_UPDATE_QUEUE_PRODUCTS_REQUEST]() {},

  [GRID_UPDATE_QUEUE.MUTATIONS.UPDATE_UPDATE_QUEUE_PRODUCTS_REQUEST]() {},

  [GRID_UPDATE_QUEUE.MUTATIONS.GET_UPDATE_QUEUE_GRID_REQUEST](state) {
    state.loadingUpdateQueueGrid = true;
  },
  [GRID_UPDATE_QUEUE.MUTATIONS.SET_UPDATE_QUEUE_GRID_SUCCESS](state, products) {
    state.updateQueueGridItems = null;
    state.loadingUpdateQueueGrid = false;
    state.updateQueueGridItems = products;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
