import { cloneDeep } from 'lodash';
import ApiService from '@/services/api.service';
import {
  IDimensionsState,
  IDimensionsGetters,
  IDimensionsActions,
  DimensionsMutationsTypes
} from '@/types/interfaces/store/product/accordions/dimensions';
import { PD_CONT } from '@/types/constants';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { AxiosError, AxiosResponse } from 'axios';
import {
  IDimensions,
  IDimensionsError
} from '@/types/interfaces/product/accordions/dimensions';
import { IState } from '@/types/interfaces/store';

export const state: IDimensionsState = {
  isEditingDimensions: false,
  isLoadingDimensions: false,

  dimensions: null,
  originalDimensions: null,
  emptyDimensions: {
    dimensionsUnit: '',
    height: null,
    length: null,
    width: null,
    weightUnit: '',
    weight: null
  },
  dimensionsErrors: null
};

export const getters: GetterTree<IDimensionsState, IDimensionsState> &
  IDimensionsGetters = {};

export const actions: ActionTree<IState, IState> & IDimensionsActions = {
  [PD_CONT.ACTIONS.GET_DIMENSIONS]({ commit }, productId) {
    return new Promise((resolve, reject) => {
      commit(PD_CONT.MUTATIONS.SET_IS_LOADING_DIMENSIONS, true);

      const url =
        process.env.VUE_APP_CATALOG_SERVICE +
        '/products/' +
        productId +
        '/dimensions';

      ApiService.get(url)
        .then((response: AxiosResponse<IDimensions>) => {
          commit(PD_CONT.MUTATIONS.SET_IS_LOADING_DIMENSIONS, false);
          commit(PD_CONT.MUTATIONS.SET_DIMENSIONS, response.data);
          commit(PD_CONT.MUTATIONS.SET_ORIGINAL_DIMENSIONS, response.data);
          resolve(response.data);
        })
        .catch((error: AxiosError) => {
          commit(PD_CONT.MUTATIONS.SET_IS_LOADING_DIMENSIONS, false);
          reject(error);
        });
    });
  }
};

const mutations: MutationTree<IState> & DimensionsMutationsTypes = {
  [PD_CONT.MUTATIONS.SET_IS_EDITING_DIMENSIONS](
    state: IDimensionsState,
    value: boolean
  ) {
    state.isEditingDimensions = value;
  },

  [PD_CONT.MUTATIONS.SET_IS_LOADING_DIMENSIONS](
    state: IDimensionsState,
    value: boolean
  ) {
    state.isLoadingDimensions = value;
  },

  [PD_CONT.MUTATIONS.SET_DIMENSIONS](
    state: IDimensionsState,
    value: IDimensions
  ) {
    state.dimensions = value;
  },

  [PD_CONT.MUTATIONS.SET_ORIGINAL_DIMENSIONS](
    state: IDimensionsState,
    value: IDimensions
  ) {
    state.originalDimensions = cloneDeep(value);
  },

  [PD_CONT.MUTATIONS.SET_DIMENSIONS_ERRORS](
    state: IDimensionsState,
    value: IDimensionsError
  ) {
    state.dimensionsErrors = value;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
