import ApiService from '@/services/api.service';
import gridQuery from '../../utility/gridQuery';
import {
  IOrderState,
  IOrderGetters,
  IOrderActions,
  OrderMutationsTypes
} from '@/types/interfaces/store/order/order';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { AxiosError, AxiosResponse } from 'axios';
import { IState } from '@/types/interfaces/store';
import { ORDER } from '@/types/constants';
import {
  IAttachment,
  IItem,
  INote,
  IOrder
} from '@/types/interfaces/order/order';
import { IOrdersGridItems } from '@/types/interfaces/product/grids/grids';

export const state: IOrderState = {
  order: {},
  loadingOrder: false,
  updatingOrder: false,

  submittingNote: false,
  notes: [],
  newNote: '',
  attachments: [],
  loadingNotes: false,
  loadingAttachments: false,

  ordersGridItems: {},
  loadingOrdersGrid: false,

  notesAndAttachments: [],

  resendingOrderToPartner: false,
  resendingOrderToMarketplace: false
};

export const getters: GetterTree<IOrderState, IOrderState> & IOrderGetters = {
  [ORDER.GETTERS.GET_ORDERS_GRID_ITEMS]: (state) => state.ordersGridItems,
  [ORDER.GETTERS.LOADING_ORDERS_GRID]: (state) => state.loadingOrdersGrid,

  [ORDER.GETTERS.GET_ORDER]: (state) => state.order,
  [ORDER.GETTERS.LOADING_ORDER]: (state) => state.loadingOrder,
  [ORDER.GETTERS.UPDATING_ORDER]: (state) => state.updatingOrder,

  [ORDER.GETTERS.SUBMITTING_NOTE]: (state) => state.submittingNote,
  [ORDER.GETTERS.GET_NEW_NOTE]: (state) => state.newNote,
  [ORDER.GETTERS.LOADING_NOTES_AND_ATTACHMENTS]: (state) =>
    state.loadingNotes || state.loadingAttachments,
  [ORDER.GETTERS.GET_NOTES_AND_ATTACHMENTS]: (state) => {
    if (!state.notes || !state.attachments) {
      return [];
    }

    // @ts-ignore
    const notesAndAttachments = state.notes.concat(state.attachments);
    notesAndAttachments.sort((a, b) => {
      // @ts-ignore
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
    state.notesAndAttachments = notesAndAttachments;
    return notesAndAttachments;
  },
  [ORDER.GETTERS.RESENDING_ORDER_TO_PARTNER]: (state) =>
    state.resendingOrderToPartner,
  [ORDER.GETTERS.RESENDING_ORDER_TO_MARKETPLACE]: (state) =>
    state.resendingOrderToMarketplace
};

export const actions: ActionTree<IState, IState> & IOrderActions = {
  [ORDER.ACTIONS.GET_ORDERS_GRID]({ commit }, filters) {
    return new Promise((resolve, reject) => {
      commit(ORDER.MUTATIONS.GET_ORDERS_GRID_REQUEST);

      let filtersCopy = JSON.parse(JSON.stringify(filters));
      const query = gridQuery.getGridQuery(filtersCopy);

      const urlApi = process.env.VUE_APP_ORDER_SERVICE + query;

      ApiService.get(urlApi)
        .then((response: AxiosResponse<IOrdersGridItems>) => {
          commit(ORDER.MUTATIONS.GET_ORDERS_GRID_SUCCESS, response.data);

          resolve(response);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },

  [ORDER.ACTIONS.GET_ORDER]({ commit }, orderId) {
    return new Promise((resolve, reject) => {
      commit(ORDER.MUTATIONS.GET_ORDER_REQUEST);

      const url = process.env.VUE_APP_ORDER_SERVICE + '/' + orderId;

      ApiService.get(url)
        .then((response: AxiosResponse<IOrder>) => {
          commit(ORDER.MUTATIONS.GET_ORDER_SUCCESS, response.data);
          resolve(response.data);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },

  [ORDER.ACTIONS.UPDATE_ORDER_ITEMS]({ commit }, { orderId, items }) {
    return new Promise((resolve, reject) => {
      commit(ORDER.MUTATIONS.UPDATE_ORDER_ITEMS_REQUEST);
      const url = process.env.VUE_APP_ORDER_SERVICE + '/' + orderId;

      ApiService.patch(url, { items })
        .then((response: AxiosResponse<IItem[]>) => {
          commit(ORDER.MUTATIONS.UPDATE_ORDER_ITEMS_SUCCESS);

          resolve(response);
        })
        .catch((error: AxiosError) => {
          commit(ORDER.MUTATIONS.UPDATE_ORDER_ITEMS_ERROR);

          reject(error);
        });
    });
  },

  [ORDER.ACTIONS.CREATE_NOTE]({ commit }, { data, orderId }) {
    return new Promise((resolve, reject) => {
      commit(ORDER.MUTATIONS.CREATE_NOTE_REQUEST);

      const url = process.env.VUE_APP_ORDER_SERVICE + '/' + orderId + '/notes';

      ApiService.post(url, data)
        .then((response: AxiosResponse) => {
          commit(ORDER.MUTATIONS.CREATE_NOTE_SUCCESS);
          resolve(response);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },

  [ORDER.ACTIONS.GET_NOTES]({ commit }, { orderId }) {
    return new Promise((resolve, reject) => {
      commit(ORDER.MUTATIONS.GET_NOTES_REQUEST);

      const url = process.env.VUE_APP_ORDER_SERVICE + '/' + orderId + '/notes';

      ApiService.get(url)
        .then((response: AxiosResponse<INote[]>) => {
          commit(ORDER.MUTATIONS.GET_NOTES_SUCCESS, response.data);
          resolve(response);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },

  [ORDER.ACTIONS.CREATE_ATTACHMENT]({ commit }, { data, orderId }) {
    return new Promise((resolve, reject) => {
      commit(ORDER.MUTATIONS.CREATE_ATTACHMENT_REQUEST);

      const url =
        process.env.VUE_APP_ORDER_SERVICE + '/' + orderId + '/attachments';

      let formData = new FormData();
      formData.append('file', data.file);

      ApiService.post(url, formData)
        .then((response: AxiosResponse) => {
          data.id = response.data;
          commit(ORDER.MUTATIONS.CREATE_ATTACHMENT_SUCCESS);
          resolve(response);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },

  [ORDER.ACTIONS.GET_ATTACHMENTS]({ commit }, { orderId }) {
    return new Promise((resolve, reject) => {
      commit(ORDER.MUTATIONS.GET_ATTACHMENTS_REQUEST);

      const url =
        process.env.VUE_APP_ORDER_SERVICE + '/' + orderId + '/attachments';

      ApiService.get(url)
        .then((response: AxiosResponse<IAttachment[]>) => {
          commit(ORDER.MUTATIONS.GET_ATTACHMENTS_SUCCESS, response.data);
          resolve(response);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },

  [ORDER.ACTIONS.DOWNLOAD_ATTACHMENT]({ commit }, { orderId, attachmentId }) {
    return new Promise((resolve, reject) => {
      commit(ORDER.MUTATIONS.DOWNLOAD_ATTACHMENT_REQUEST);
      const url =
        process.env.VUE_APP_ORDER_SERVICE +
        '/' +
        orderId +
        '/attachments/' +
        attachmentId;

      ApiService.get(url)
        .then((response: AxiosResponse) => {
          resolve(response);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },

  [ORDER.ACTIONS.RESUBMIT_ORDER_TO_PARTNER]({ commit }, { orderId }) {
    return new Promise((resolve, reject) => {
      commit(ORDER.MUTATIONS.RESENDING_ORDER_TO_PARTNER_REQUEST);

      const url =
        process.env.VUE_APP_ORDER_SERVICE +
        '/' +
        orderId +
        '/repush-failed-to-partner';

      ApiService.post(url)
        .then((response: AxiosResponse) => {
          commit(ORDER.MUTATIONS.RESENDING_ORDER_TO_PARTNER_SUCCESS);
          resolve(response);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },

  [ORDER.ACTIONS.RESUBMIT_ORDER_TO_MARKETPLACE]({ commit }, { orderId }) {
    return new Promise((resolve, reject) => {
      commit(ORDER.MUTATIONS.RESENDING_ORDER_TO_MARKETPLACE_REQUEST);

      const url =
        process.env.VUE_APP_ORDER_SERVICE +
        '/' +
        orderId +
        '/repush-failed-shipment-to-marketplace';

      ApiService.post(url)
        .then((response: AxiosResponse) => {
          commit(ORDER.MUTATIONS.RESENDING_ORDER_TO_MARKETPLACE_SUCCESS);
          resolve(response);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  }
};

const mutations: MutationTree<IState> & OrderMutationsTypes = {
  [ORDER.MUTATIONS.GET_ORDERS_GRID_REQUEST](state) {
    state.loadingOrdersGrid = true;
  },

  [ORDER.MUTATIONS.GET_ORDERS_GRID_SUCCESS](state, orders) {
    state.ordersGridItems = null;
    state.loadingOrdersGrid = false;
    state.ordersGridItems = orders;
  },

  [ORDER.MUTATIONS.GET_ORDER_REQUEST](state) {
    state.notes = null;
    state.attachments = null;
    state.loadingOrder = true;
  },

  [ORDER.MUTATIONS.GET_ORDER_SUCCESS](state, details) {
    state.order = null;
    state.order = details;
    state.loadingOrder = false;
  },

  [ORDER.MUTATIONS.CREATE_NOTE_REQUEST](state) {
    state.submittingNote = true;
  },

  [ORDER.MUTATIONS.CREATE_NOTE_SUCCESS](state) {
    state.submittingNote = false;
  },

  [ORDER.MUTATIONS.CREATE_ATTACHMENT_REQUEST](state) {
    state.submittingNote = true;
  },

  [ORDER.MUTATIONS.CREATE_ATTACHMENT_SUCCESS](state) {
    state.submittingNote = false;
  },

  [ORDER.MUTATIONS.SET_NEW_NOTE](state, value) {
    state.newNote = value;
  },

  [ORDER.MUTATIONS.GET_NOTES_REQUEST](state) {
    state.loadingNotes = true;
  },

  [ORDER.MUTATIONS.GET_NOTES_SUCCESS](state, notes) {
    state.notes = null;
    state.loadingNotes = false;
    state.notes = notes;
  },

  [ORDER.MUTATIONS.GET_ATTACHMENTS_REQUEST](state) {
    state.loadingAttachments = true;
  },

  [ORDER.MUTATIONS.GET_ATTACHMENTS_SUCCESS](state, attachments) {
    state.attachments = null;
    state.loadingAttachments = false;
    state.attachments = attachments;
  },

  [ORDER.MUTATIONS.DOWNLOAD_ATTACHMENT_REQUEST]() {},

  [ORDER.MUTATIONS.SET_ORDER_ITEMS](state, items) {
    // @ts-ignore
    state.order!.items = items;
  },

  [ORDER.MUTATIONS.UPDATE_ORDER_ITEMS_REQUEST](state) {
    state.updatingOrder = true;
  },

  [ORDER.MUTATIONS.UPDATE_ORDER_ITEMS_SUCCESS](state) {
    state.updatingOrder = false;
  },

  [ORDER.MUTATIONS.UPDATE_ORDER_ITEMS_ERROR](state) {
    state.updatingOrder = false;
  },

  [ORDER.MUTATIONS.RESENDING_ORDER_TO_PARTNER_REQUEST](state) {
    state.resendingOrderToPartner = true;
  },

  [ORDER.MUTATIONS.RESENDING_ORDER_TO_PARTNER_SUCCESS](state) {
    state.resendingOrderToPartner = false;
  },

  [ORDER.MUTATIONS.RESENDING_ORDER_TO_MARKETPLACE_REQUEST](state) {
    state.resendingOrderToMarketplace = true;
  },

  [ORDER.MUTATIONS.RESENDING_ORDER_TO_MARKETPLACE_SUCCESS](state) {
    state.resendingOrderToMarketplace = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
