import TAccordion from 'lib-vue-components/src/components/surfaces/accordion/index.vue';
import TAutocomplete from 'lib-vue-components/src/components/controls/inputs/autocomplete/index.vue';
import TButton from 'lib-vue-components/src/components/controls/button/index.vue';
import TCurrentlySetStatus from 'lib-vue-components/src/components/utils/currently-set-status/index.vue';
import TCarousel from 'lib-vue-components/src/components/utils/carousel/index.vue';
import TImageCarousel from 'lib-vue-components/src/components/data-display/image-carousel/index.vue';
import TInput from 'lib-vue-components/src/components/controls/inputs/input/index.vue';
import TInputDate from 'lib-vue-components/src/components/controls/inputs/input-date/index.vue';
import TInputDecimal from 'lib-vue-components/src/components/controls/inputs/input-decimal/index.vue';
import TInputMasked from 'lib-vue-components/src/components/controls/inputs/input-masked/index.vue';
import TInputSlider from 'lib-vue-components/src/components/controls/inputs/input-slider/index.vue';
import TModal from 'lib-vue-components/src/components/surfaces/modal/index.vue';
import TMultiselectDropdown from 'lib-vue-components/src/components/controls/selects/multiselect-dropdown/index.vue';
import TFieldError from 'lib-vue-components/src/components/utils/field-error/index.vue';
import TPagination from 'lib-vue-components/src/components/utils/pagination/index.vue';
import TRadioSet from 'lib-vue-components/src/components/controls/radio-set/index.vue';
import TSelect from 'lib-vue-components/src/components/controls/selects/select/index.vue';
import TSidenav from 'lib-vue-components/src/components/navigation/sidenav/index.vue';
import TSelectize from 'lib-vue-components/src/components/controls/selects/selectize/index.vue';
import TSortingTrigger from 'lib-vue-components/src/components/utils/sorting-trigger/index.vue';
import TTextarea from 'lib-vue-components/src/components/controls/inputs/textarea/index.vue';
import TToggle from 'lib-vue-components/src/components/controls/toggle/index.vue';
import TTooltip from 'lib-vue-components/src/components/data-display/tooltip/index.vue';
import TTreeDropdown from 'lib-vue-components/src/components/controls/selects/tree-dropdown/index.vue';
import TGrid from 'lib-vue-components/src/components/data-display/grid/index.vue';
import TSidebar from 'lib-vue-components/src/components/navigation/sidebar/index.vue';
import TMarginStack from 'lib-vue-components/src/components/data-display/charts/margin-stack/index.vue';
import TMarginStackPill from 'lib-vue-components/src/components/data-display/charts/margin-stack/margin-stack-pill/index.vue';
import TImageUploaderPerMarketplace from 'lib-vue-components/src/components/controls/uploaders/image-uploader-per-marketplace/index.vue';
import TBulletInput from 'lib-vue-components/src/components/controls/inputs/bullet-input/index.vue';
import TCollapsibleCard from 'lib-vue-components/src/components/surfaces/collapsible-card';
import TBulletCard from 'lib-vue-components/src/components/surfaces/bullet-card';
import TBanner from 'lib-vue-components/src/components/feedback/banner';
import TFileUploader from 'lib-vue-components/src/components/controls/uploaders/file-uploader';
import TComplexityCheck from 'lib-vue-components/src/components/utils/complexity-check';
import TProductsGrid from 'lib-vue-components/src/components/data-display/grid/products-grid';
import TUploadStatusGrid from 'lib-vue-components/src/components/data-display/grid/upload-status-grid';
import TUpdateQueueGrid from 'lib-vue-components/src/components/data-display/grid/update-queue-grid';
import TLoader from 'lib-vue-components/src/components/feedback/loader';
import TTable from 'lib-vue-components/src/components/data-display/table';
import TInputWithSelect from 'lib-vue-components/src/components/controls/inputs/input-with-select';
import TRangeDropdown from 'lib-vue-components/src/components/controls/selects/range-dropdown';
import TOrdersGrid from 'lib-vue-components/src/components/data-display/grid/orders-grid';
import THorizontalTabs from 'lib-vue-components/src/components/navigation/horizontal-tabs';
import THorizontalChart from 'lib-vue-components/src/components/data-display/charts/horizontal-chart';
import TSidenavV2 from 'lib-vue-components/src/components/navigation/sidenav-v2/index.vue';

const Components = {
  TAccordion,
  TAutocomplete,
  TButton,
  TCurrentlySetStatus,
  TCarousel,
  TImageCarousel,
  TInput,
  TInputDate,
  TInputDecimal,
  TInputMasked,
  TInputSlider,
  TFieldError,
  TModal,
  TMultiselectDropdown,
  TPagination,
  TRadioSet,
  TSelect,
  TSelectize,
  TSidenav,
  TSortingTrigger,
  TTextarea,
  TToggle,
  TTooltip,
  TTreeDropdown,
  TGrid,
  TSidebar,
  TMarginStack,
  TMarginStackPill,
  TImageUploaderPerMarketplace,
  TBulletInput,
  TBulletCard,
  TCollapsibleCard,
  TBanner,
  TFileUploader,
  TComplexityCheck,
  TProductsGrid,
  TUploadStatusGrid,
  TLoader,
  TUpdateQueueGrid,
  TTable,
  TInputWithSelect,
  TRangeDropdown,
  TOrdersGrid,
  THorizontalTabs,
  THorizontalChart,
  TSidenavV2
};

export default Components;
